import Aos from 'aos';
import React, { useEffect, useState } from 'react';

export const ContadorBodaComponent = ({ peltaColores, datosSeccionContador }) => {
  const targetDate = new Date(datosSeccionContador.fechaNewDate || "").getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const [estilos, setEstilos] = useState({ imagenArriba: "", imagenAbajo: "" });

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  }

  // Función para parsear los estilos
  const getEstilos = () => {
    try {
      const estilos = JSON.parse(datosSeccionContador.estilos || '{"imagenArriba":"","imagenAbajo":""}');
      console.log(estilos, datosSeccionContador.estilos);
      return estilos;
    } catch {
      return { imagenArriba: "", imagenAbajo: "" };
    }
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
    setEstilos(getEstilos());
    
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fontFamilyStyle = { fontFamily: datosSeccionContador.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor || "",
      }}
      className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full px-2 py-5"
    >
     <div className="flex flex-col pt-[10vh] w-full items-center">
      <h1
        className="font-serif sm:text-[52px] md:text-[92px]"
        data-aos="flip-up"
        style={fontFamilyStyle}
      >
        {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </h1>
      <br />
      <p
        className={`text-center pt-[5vh] font-serif ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-xl"} sm:w-[80%] md:w-[60%]`}
        data-aos="flip-up"
        style={fontFamilyStyle}
      >
        {datosSeccionContador.descipcion.split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea}
            <br />
          </React.Fragment>
        ))}
      </p>
      <div className="grid sm:grid-cols-1 items-center md:grid-cols-3 w-[80%] pt-[8vh]">
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: estilos.imagenArriba || "cover",
            height: "45vh",
            display: "flex",
            backgroundPosition: "center center",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="rounded-[30%]"
          data-aos="fade-right"
        ></div>
        {datosSeccionContador.contador ? (
        <div
        className="grid grid-cols-4 justify-center items-center mt-10"
        data-aos="fade-up"
        style={{
          border: "15px solid transparent",
          padding: "15px",
          borderImage: datosSeccionContador.marcoEnable
            ? "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')"
            : "",
          borderImageSlice: "46",
          borderImageWidth: "round",
          maxWidth: "100%", // Ensure the container does not exceed its parent's width
          overflow: "hidden", // Prevent content overflow
        }}
      >
        <div className="flex flex-col justify-center items-center text-center mx-2" >
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
            {timeRemaining.days}
          </p>
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
             {datosSeccionContador.ingles? "Days":"Días"}
          </p>
        </div>
        <div className="flex flex-col justify-center items-center text-center mx-2" >
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
            {timeRemaining.hours}
          </p>
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
            {datosSeccionContador.ingles? "H":"Hrs"}
          </p>
        </div>
        <div className="flex flex-col justify-center items-center text-center mx-2" >
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
            {timeRemaining.minutes}
          </p>
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
            {datosSeccionContador.ingles? "M":"Mins"}
          </p>
        </div>
        <div className="flex flex-col justify-center items-center text-center mx-2" >
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
            {timeRemaining.seconds}
          </p>
          <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive"?"text-[4vh]":"text-full"}`} style={{...fontFamilyStyle }}>
           {datosSeccionContador.ingles? "S":"Segs"}
          </p>
        </div>
      </div>         
        ) : (
          <div></div>
        )}
        <div
          style={{
            backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: estilos.imagenAbajo || "cover",
            height: "45vh",
            display: "flex",
            backgroundPosition: "center center",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="rounded-[30%] sm:mt-8 md:mt-0 sm:ml-0 md:ml-8"
          data-aos="fade-left"
        ></div>
      </div>
    </div>
    </div>
  );
};
