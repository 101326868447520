import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ContadorXvComponent } from "../../../../components/ComponentsXv/ContadorXvComponent";
import { PadresBodaComponent } from "../../../../components/ComponentsBoda/PadresBodaComponent";
import { CeremoniaXvComponent } from "../../../../components/ComponentsXv/CeremoniaXvComponent";
import { HospedajeXvComponent } from "../../../../components/ComponentsXv/HospedajeXvComponent";
import { RegalosXvComponent } from "../../../../components/ComponentsXv/RegalosXvComponent";
import { ConfirmacionDiamXvComponent } from "../../../../components/ComponentsXv/ConfirmacionDiamXvComponent";
import { VestimentaXvComponent } from "../../../../components/ComponentsXv/VestimentaXvComponent";
import { ConfirmacionXvComponent } from "../../../../components/ComponentsXv/ConfirmacionXvComponent";
import { ProgramaXvComponent } from "../../../../components/ComponentsXv/ProgramaXvComponent";
import { ProgramaBodaComponent } from "../../../../components/ComponentsBoda/ProgramaBodaComponent";
import { PortadaXvComponent } from "../../../../components/ComponentsXv/PortadaXvComponent";
import axios from "axios";
import ModalXvCard from "../../../../components/Modals/ModalXvCard";
import useUpdateMetaTags from "../../../Admin/Miniaturas/useUpdateMetaTags";
import { LoadingComponent } from "../../../../components/LoadingComponent";
import GoogleTranslate from "../../../../components/GoogleTranslate";
import { ConvertDataContador } from "../../../../components/functions/ConvertDataContador";
import Modal2Xv from "../../../../components/Modals/Modal2Xv";
import { LoadingComponentEn } from "../../../../components/LoadingComponentEn";
import ErrorAlert from "../../../../components/ErrorAlert";
import { LoadingEventos } from "../../../../components/LoadingEventos";

export const XvAutomatica = () => {
  const [seccionPortadaData, setSeccionPortadaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seccionImagenes, setSeccionImagenes] = useState([]);
  const [estado, setEstado] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const inglesEn = true 
  const [redirect, setRedirect] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idClient = searchParams.get("id");

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const handleRetry = () => {
    setShowError(false);
    fetchData();
  };

  // Función para hacer la solicitud HTTP
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/Eventos/${idClient}`);
      if (response.data.enableEvento == true) {
        handleError(
          "Your event has been disabled due to pending payment or other reasons! Please contact us."
        );
        window.location.href = "/";
      }
      setEstado(response.data);
      console.log(response.data);

      try {
        setLoading(true);
        const responsePortada = await axios.get(
          `/api/Seccionportada/obtenerXv/${idClient}`
        );
        console.log(responsePortada.data);
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
        const validarDate = regex.test(responsePortada.data?.portada?.fechaDeBoda);
        if (responsePortada?.data?.deuda) {
          handleError(responsePortada?.data?.smsdeuda);
          setRedirect(true);
          return;
        } else {
          if (responsePortada?.data?.smsdeuda && responsePortada?.data?.smsdeuda !== "") {
            handleError(responsePortada?.data?.smsdeuda);
          }
        }

        if (validarDate) {
          // Validate if there are 5 days or less until the XV años date
          const fechaXV = new Date(responsePortada.data.portada.fechaDeBoda);
          const hoy = new Date();
          const diasFaltantes = Math.ceil((fechaXV - hoy) / (1000 * 60 * 60 * 24));

          if (diasFaltantes <= 5) {
            console.log(diasFaltantes, "validation passed");
            if (response.data.monto < response.data.total) {
              handleError(
                "Warning! There are 5 days or less until your event and the payment is not complete. Please contact us to resolve this situation."
              );
              window.location.href = "/";
            }
          }

          const dataConvertida = await ConvertDataContador(responsePortada.data);
          setSeccionPortadaData(dataConvertida);
        } else {
          setSeccionPortadaData(responsePortada.data);
        }

        try {
          const responseImg = await axios.get(`/api/Imagenes?id=${idClient}`);
          const mapImg = responseImg.data.map((item) => item.urlFoto);
          setSeccionImagenes(mapImg);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          handleError(
            "We couldn't load the images. Please try again in a few minutes."
          );
        }
      } catch (error) {
        setLoading(false);
        handleError(
          "There was a problem loading the event details. Please try again later."
        );
      }
    } catch (error) {
      setLoading(false);
      handleError(
        "We couldn't connect to the server. Please check your connection and try again."
      );
    }
  };

  useUpdateMetaTags(seccionPortadaData?.portada?.imgPortada);

  // Llamar a la función fetchData cuando el componente se monte
  useEffect(() => {
    fetchData();
  }, []);

  const peltaColores = {
    color1: seccionPortadaData?.colores?.color1 || "",
    color2: seccionPortadaData?.colores?.color2 || "",
  };

  const datosSeccionPotada = {
    ingles: inglesEn,
    cancion: seccionPortadaData?.portada?.cancion || "",
    textoConfirmacion: seccionPortadaData?.portada?.textoConfirmacion || "",
    nombre: seccionPortadaData?.portada?.nombre || "",
    fechaDeBoda: seccionPortadaData?.portada?.fechaDeBoda || "",
    imgPortada: seccionPortadaData?.portada?.imgPortada || "",
    titulo: seccionPortadaData?.portada?.titulo || "",
    textColor: seccionPortadaData?.portada?.textColor || "",
    tipoLetra: seccionPortadaData?.portada?.tipoLetra || "",
  };

  const datosSeccionContador = {
    ingles: inglesEn,
    titulo: seccionPortadaData?.contador?.titulo || "",
    descipcion: seccionPortadaData?.contador?.descripcion || "",
    fechaNewDate: seccionPortadaData?.contador?.fechaNewDate || "",
    fotoIzquierda: seccionPortadaData?.contador?.fotoIzquierda || "",
    fotoDerecha: seccionPortadaData?.contador?.fotoDerecha || "",
    marcoEnable: seccionPortadaData?.contador?.marcoEnable,
    imgFondo: seccionPortadaData?.contador?.imgFondo || "",
    textColor: seccionPortadaData?.contador?.textColor || "",
    contador: seccionPortadaData?.contador?.contador,
    tipoLetra: seccionPortadaData?.contador?.tipoLetra,
    estilos: seccionPortadaData?.contador?.estilos || "",
  };

  const datosInvitados = {
    titulo: "¡Has sido invitado a la boda de Jimena y Antonio!",
    entradasText: "disfrutar nuestro dia especial",
  };

  const datosSeccionPadres = {
    ingles: inglesEn,
    cantidad: seccionPortadaData?.padres?.cantidad || "",
    titulo: seccionPortadaData?.padres?.titulo || "",
    descripcion: seccionPortadaData?.padres?.descripcion || "",
    titulo1: seccionPortadaData?.padres?.titulo1 || "",
    madre: seccionPortadaData?.padres?.madre || "",
    padre: seccionPortadaData?.padres?.padre || "",
    titulo2: seccionPortadaData?.padres?.titulo2 || "",
    madrina: seccionPortadaData?.padres?.madrina || "",
    padrino: seccionPortadaData?.padres?.padrino || "",
    titulo3: seccionPortadaData?.padres?.titulo3 || "",
    madrina2: seccionPortadaData?.padres?.madrina2 || "",
    padrino2: seccionPortadaData?.padres?.padrino2 || "",
    imgFondo: seccionPortadaData?.padres?.imgFondo || "",
    textColor: seccionPortadaData?.padres?.textColor || "",
    tipoLetra: seccionPortadaData?.padres?.tipoLetra || "",
  };

  const datosSeccionCeremonia = {
    ingles: inglesEn,
    dobleEnable: seccionPortadaData?.ceremonia?.dobleEnable,
    ceremoniaEnable: seccionPortadaData?.ceremonia?.ceremoniaEnable,
    ceremoniaImg: seccionPortadaData?.ceremonia?.ceremoniaImg,
    ceremoniaTitulo: seccionPortadaData?.ceremonia?.ceremoniaTitulo,
    ceremoniaFecha: seccionPortadaData?.ceremonia?.ceremoniaFecha || "",
    ceremoniaHora: seccionPortadaData?.ceremonia?.ceremoniaHora || "",
    ceremoniaNombreLugar:
      seccionPortadaData?.ceremonia?.ceremoniaNombreLugar || "",
    ceremoniaLugarMap: seccionPortadaData?.ceremonia?.ceremoniaLugarMap || "",
    ceremoniaUrl: seccionPortadaData?.ceremonia?.ceremoniaUrl || "",
    recepcionEnable: seccionPortadaData?.ceremonia?.recepcionEnable,
    recepcionImg: seccionPortadaData?.ceremonia?.recepcionImg,
    recepcionTitulo: seccionPortadaData?.ceremonia?.recepcionTitulo,
    recepcionFecha: seccionPortadaData?.ceremonia?.recepcionFecha || "",
    recepcionHora: seccionPortadaData?.ceremonia?.recepcionHora || "",
    recepcionNombreLugar:
      seccionPortadaData?.ceremonia?.recepcionNombreLugar || "",
    recepcionLugarMap: seccionPortadaData?.ceremonia?.recepcionLugarMap || "",
    recepcionUrl: seccionPortadaData?.ceremonia?.recepcionUrl || "",
    imgFondo: seccionPortadaData?.ceremonia?.imgFondo || "",
    textColor: seccionPortadaData?.ceremonia?.textColor || "",
    tipoLetra: seccionPortadaData?.ceremonia?.tipoLetra || "",
  };

  const datosSeccionPrograma = {
    ingles: inglesEn,
    ceremoniaReligiosa: seccionPortadaData?.programa?.ceremoniaReligiosa || "",
    titulo: seccionPortadaData?.programa?.titulo || "",
    descripcion: seccionPortadaData?.programa?.descripcion || "",
    ceremoniaReligiosaImg:
      seccionPortadaData?.programa?.ceremoniaReligiosaImg || "",
    ceremoniaReligiosaTitulo:
      seccionPortadaData?.programa?.ceremoniaReligiosaTitulo || "",
    recepcion: seccionPortadaData?.programa?.recepcion || "",
    recepcionImg: seccionPortadaData?.programa?.recepcionImg || "",
    recepcionTitulo: seccionPortadaData?.programa?.recepcionTitulo || "",
    baileXv: seccionPortadaData?.programa?.baileXv || "",
    baileXvImg: seccionPortadaData?.programa?.baileXvImg || "",
    baileXvTitulo: seccionPortadaData?.programa?.baileXvTitulo || "",
    cena: seccionPortadaData?.programa?.cena || "",
    cenaImg: seccionPortadaData?.programa?.cenaImg || "",
    cenaTitulo: seccionPortadaData?.programa?.cenaTitulo || "",
    coctel: seccionPortadaData?.programa?.coctel || "",
    coctelImg: seccionPortadaData?.programa?.coctelImg || "",
    coctelTitulo: seccionPortadaData?.programa?.coctelTitulo || "",
    baile: seccionPortadaData?.programa?.baile || "",
    baileImg: seccionPortadaData?.programa?.baileImg || "",
    baileTitulo: seccionPortadaData?.programa?.baileTitulo || "",
    imgFondo: seccionPortadaData?.programa?.imgFondo || "",
    textColor: seccionPortadaData?.programa?.textColor || "",
    tipoLetra: seccionPortadaData?.programa?.tipoLetra || "",
  };

  const datosSeccionVestimenta = {
    ingles: inglesEn,
    vestimenta: seccionPortadaData?.vestimenta?.vestimenta || "",
    messageVestimenta: seccionPortadaData?.vestimenta?.messageVestimenta || "",
    urlVestimentaMujer:
      seccionPortadaData?.vestimenta?.urlVestimentaMujer || "",
    urlVestimentaHombre:
      seccionPortadaData?.vestimenta?.urlVestimentaHombre || "",
    imgFondo: seccionPortadaData?.vestimenta?.imgFondo || "",
    textColor: seccionPortadaData?.vestimenta?.textColor || "",
    tipoLetra: seccionPortadaData?.vestimenta?.tipoLetra || "",
  };

  const datosSeccionHospedaje = {
    ingles: inglesEn,
    disponibleDos: seccionPortadaData?.hospedaje?.disponibleDos || "",
    descripcion: seccionPortadaData?.hospedaje?.descripcion || "",
    hotel1Titulo: seccionPortadaData?.hospedaje?.hotel1Titulo || "",
    hotel1lugar: seccionPortadaData?.hospedaje?.hotel1lugar || "",
    hotel1url: seccionPortadaData?.hospedaje?.hotel1url || "",
    hotel2Titulo: seccionPortadaData?.hospedaje?.hotel2Titulo || "",
    hotel2lugar: seccionPortadaData?.hospedaje?.hotel2lugar || "",
    hotel2url: seccionPortadaData?.hospedaje?.hotel2url || "",
    imgFondo: seccionPortadaData?.hospedaje?.imgFondo || "",
    textColor: seccionPortadaData?.hospedaje?.textColor || "",
    tipoLetra: seccionPortadaData?.hospedaje?.tipoLetra || "",
  };

  const datosSeccionRegalos = {
    ingles: inglesEn,
    cantidadRegalos: seccionPortadaData?.regalos?.cantidadRegalos || "",
    titulo: seccionPortadaData?.regalos?.titulo || "",
    descripcion: seccionPortadaData?.regalos?.descripcion || "",
    link1: seccionPortadaData?.regalos?.link1 || "",
    imgLink1: seccionPortadaData?.regalos?.imgLink1 || "",
    link1Enable: seccionPortadaData?.regalos?.link1Enable || "",
    link2: seccionPortadaData?.regalos?.link2 || "",
    imgLink2: seccionPortadaData?.regalos?.imgLink2 || "",
    link2Enable: seccionPortadaData?.regalos?.link2Enable || "",
    link3: seccionPortadaData?.regalos?.link3 || "",
    imgLink3: seccionPortadaData?.regalos?.imgLink3 || "",
    link3Enable: seccionPortadaData?.regalos?.link3Enable || "",
    imgFondo: seccionPortadaData?.regalos?.imgFondo || "",
    textColor: seccionPortadaData?.regalos?.textColor || "",
    tipoLetra: seccionPortadaData?.regalos?.tipoLetra || "",
  };

  const datosSeccionConfirmacion = {
    ingles: inglesEn,
    tituloConfirmacion:
      seccionPortadaData?.confirmacion?.tituloConfirmacion || "",
    aviso: seccionPortadaData?.confirmacion?.aviso || "",
    numeroConfirmacion:
      seccionPortadaData?.confirmacion?.numeroConfirmacion || "",
    textoConfirmacion:
      seccionPortadaData?.confirmacion?.textoConfirmacion || "",
    textWhatsApp: seccionPortadaData?.confirmacion?.textWhatsApp || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    imgFondo: seccionPortadaData?.confirmacion?.imgFondo || "",
    textColor: seccionPortadaData?.confirmacion?.textColor || "",
    enableNum: seccionPortadaData?.confirmacion?.enableNum || "",
    tipoLetra: seccionPortadaData?.confirmacion?.tipoLetra || "",
  };

  const datosSeccionInvitados = {
    imgFondo:
      "https://vizualmexico.com.mx/wp-content/uploads/2023/01/XV-Mayte-0144.jpeg",
    titulo: "¡Has sido invitado a los XV años de Jimena Torres!",
    entradasText: "disfrutar mis XV años",
    colorFondoName: "",
  };

  const contador = estado?.enableContador;
  const padres = estado?.enablePadres;
  const hospedaje = estado?.enableHospedaje;
  const confirmacion = estado?.enableConfirmacion;
  const Regalos = estado?.enableRegalos;
  const ceremonia = estado?.enableCeremonia;
  const programa = estado?.enablePrograma;
  const vestimenta = estado?.enableVestimenta;
  const carta = estado?.enableCarta;

  const [messageWhats, setMessageWhats] = useState("");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-neutral-500">
      <GoogleTranslate />
      {showError && (
        <ErrorAlert 
          message={errorMessage}
          onRetry={handleRetry}
          onClose={() => setShowError(false)}
          redirect={redirect}
        />
      )}
      
      {loading ? (
        <LoadingEventos />
      ) : (
        <>
          {!carta ? (
            seccionPortadaData?.portada?.sobre == null || seccionPortadaData?.portada?.sobre === 0 ? (
              <ModalXvCard
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                datosSeccionPortada={datosSeccionPotada}
                ingles={true}
              />
            ) : (
              <Modal2Xv
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                datosSeccionPortada={datosSeccionPotada}
                ingles={true}
              />
            )
          ) : (
            ""
          )}

          <PortadaXvComponent datosSeccionPortada={datosSeccionPotada} />

          {contador ? (
            <ContadorXvComponent
              peltaColores={peltaColores}
              datosSeccionContador={datosSeccionContador}
            />
          ) : (
            ""
          )}

          {padres ? (
            <PadresBodaComponent
              peltaColores={peltaColores}
              datosSeccionPadres={datosSeccionPadres}
            />
          ) : (
            ""
          )}

          {ceremonia ? (
            <CeremoniaXvComponent
              peltaColores={peltaColores}
              datosSeccionCeremonia={datosSeccionCeremonia}
            />
          ) : (
            ""
          )}

          {programa ? (
            <ProgramaXvComponent
              peltaColores={peltaColores}
              datosSeccionPrograma={datosSeccionPrograma}
            />
          ) : (
            ""
          )}

          {vestimenta ? (
            <VestimentaXvComponent
              peltaColores={peltaColores}
              datosSeccionVestimenta={datosSeccionVestimenta}
            />
          ) : (
            ""
          )}

          {hospedaje ? (
            <HospedajeXvComponent
              peltaColores={peltaColores}
              datosSeccionHospedaje={datosSeccionHospedaje}
            />
          ) : (
            ""
          )}

          {Regalos ? (
            <RegalosXvComponent
              peltaColores={peltaColores}
              datosSeccionRegalos={datosSeccionRegalos}
            />
          ) : (
            ""
          )}

          {confirmacion ? (
            <ConfirmacionXvComponent
              peltaColores={peltaColores}
              images={seccionImagenes}
              messageWhats={messageWhats}
              datosSeccionConfirmacion={datosSeccionConfirmacion}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
