/* global gtag */
'use client'

import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { motion } from 'framer-motion'
import { LeadClickWidget } from './LeadClickWidget'
import { FaWhatsapp, FaInstagram, FaFacebook, FaFacebookMessenger } from 'react-icons/fa'
import AOS from "aos";
import "aos/dist/aos.css";
import './Home.css';

// Google Ads conversion tracking function
function gtag_report_conversion(url) {
  console.log('khee');
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-16738412224/pvRgCKimqeEZEMDNv60-',
      'event_callback': callback
  });
  return false;
}

export const Home = ()=> {
  const [cartCount, setCartCount] = useState(0)
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0)
  const [currentDesignIndex, setCurrentDesignIndex] = useState(0)
  const [timeLeft, setTimeLeft] = useState(() => {
    // Obtener el tiempo restante guardado o iniciar un nuevo período de 24 horas
    const savedEndTime = localStorage.getItem('countdownEndTime');
    if (savedEndTime) {
      const endTime = parseInt(savedEndTime);
      const now = Date.now();
      if (now < endTime) {
        const diff = endTime - now;
        return {
          hours: Math.floor(diff / (1000 * 60 * 60)),
          minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((diff % (1000 * 60)) / 1000)
        };
      }
    }
    // Si no hay tiempo guardado o ya expiró, iniciar nuevo período
    const endTime = Date.now() + (24 * 60 * 60 * 1000);
    localStorage.setItem('countdownEndTime', endTime.toString());
    return {
      hours: 23,
      minutes: 59,
      seconds: 59
    };
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev.seconds > 0) {
          return { ...prev, seconds: prev.seconds - 1 };
        } else if (prev.minutes > 0) {
          return { ...prev, minutes: prev.minutes - 1, seconds: 59 };
        } else if (prev.hours > 0) {
          return { hours: prev.hours - 1, minutes: 59, seconds: 59 };
        } else {
          // Reiniciar el contador cuando llegue a cero
          const newEndTime = Date.now() + (24 * 60 * 60 * 1000);
          localStorage.setItem('countdownEndTime', newEndTime.toString());
          return { hours: 23, minutes: 59, seconds: 59 };
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const categories = [
    { 
      title: 'Boda', 
      gradient: 'bg-gradient-to-br from-pink-200 via-rose-200 to-pink-300',
      icon: '💍',
      description: 'El día más especial merece una invitación extraordinaria'
    },
    { 
      title: 'XV Años', 
      gradient: 'bg-gradient-to-br from-purple-200 via-fuchsia-200 to-pink-200',
      icon: '👑',
      description: 'Una celebración única para un momento único'
    },
    { 
      title: 'Bautizo', 
      gradient: 'bg-gradient-to-br from-sky-200 via-cyan-200 to-blue-200',
      icon: '🕊️',
      description: 'Celebra este momento especial con una invitación única'
    },
    { 
      title: 'Baby shower', 
      gradient: 'bg-gradient-to-br from-yellow-100 via-amber-100 to-orange-100',
      icon: '🎀',
      description: 'Comparte la dulce espera con tus seres queridos'
    },
    { 
      title: 'Fiestas', 
      gradient: 'bg-gradient-to-br from-emerald-200 via-teal-200 to-cyan-200',
      icon: '🎉',
      description: 'Haz de tu celebración un momento inolvidable'
    }
  ]

  const featuredDesigns = [
    { image: 'https://i.postimg.cc/rFgH7w6L/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-1.png', title: 'Invitación XV Años Azul' },
    { image: 'https://i.postimg.cc/W1zsHyHX/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-2.png', title: 'Invitación Floral' },
    { image: 'https://i.postimg.cc/g0rr3jpc/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-4.png', title: 'Invitación Cenicienta' }
  ]

  const plans = [
    {
      name: "PREMIUM",
      popular: true,
      originalPrice: 3200,
      price: 995,
      savings: 2205,
      color: "bg-gradient-to-br from-purple-500 to-indigo-600",
      features: [
        "Todo lo incluido en el plan DIAMANTE",
        "Sección de Instagram con hashtag personalizado",
        "Feed de Instagram integrado",
        "Mensajes personalizados para cada invitado",
        "Control de acceso individual",
        "Panel de administración de invitados",
        "Estadísticas de visualización",
        "Notificaciones en tiempo real",
        "Soporte prioritario 24/7", 
        "QR de confirmación de invitación",
        "Control de asistencia por medio de QR"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    },
    {
      name: "DIAMANTE",
      originalPrice: 2500,
      price: 895,
      savings: 1605,
      color: "bg-blue-100",
      features: [
        "Todo lo incluido en el plan PLATA",
        "Personalización avanzada de invitaciones",
        "Confirmación directa a los anfitriones",
        "Sección de hospedaje para invitados",
        "Integración con mesa de regalos",
        "Código de vestimenta interactivo",
        "Sección especial para padres y padrinos",
        "Galería extendida con 8 fotografías"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    },
    {
      name: "ORO",
      originalPrice: 1200,
      price: 595,
      savings: 605,
      color: "bg-yellow-100",
      features: [
        "Todo lo incluido en el plan PLATA",
        "Sección de hospedaje para invitados",
        "Código de vestimenta interactivo",
        "Sección especial para padres y padrinos",
        "Galería con 6 fotografías",
        "Diseño premium personalizado"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    },
    {
      name: "PLATA",
      originalPrice: 600,
      price: 295,
      savings: 305,
      color: "bg-gray-100",
      features: [
        "App móvil para compartir tus invitaciones (iOS y Android)",
        "Plantilla de colores personalizable",
        "Confirmación vía WhatsApp",
        "Detalles completos del evento",
        "Cuenta regresiva interactiva",
        "Ubicación del evento y ceremonia en Maps",
        "Galería con 4 fotografías",
        "Música de fondo personalizable"
      ],
      examples: [
        { text: "Ejemplo de XV años", href: "/ejemplos-xv" },
        { text: "Ejemplos de Boda", href: "/ejemplos-boda" }
      ]
    }
  ]

  const nextCategory = () => {
    setCurrentCategoryIndex((prev) => (prev + 1) % categories.length)
  }

  const prevCategory = () => {
    setCurrentCategoryIndex((prev) => (prev - 1 + categories.length) % categories.length)
  }

  const nextDesign = () => {
    setCurrentDesignIndex((prev) => (prev + 1) % featuredDesigns.length)
  }

  const prevDesign = () => {
    setCurrentDesignIndex((prev) => (prev - 1 + featuredDesigns.length) % featuredDesigns.length)
  }

  // Configurar los handlers para el swipe
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => nextCategory(),
    onSwipedRight: () => prevCategory(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <div className="min-h-screen bg-white relative">
      {/* Add this style tag for the snowfall animation */}

      {/* Add these snowflakes */}
      {[...Array(25)].map((_, index) => (
        <div
          key={index}
          className="snowflake"
          style={{
            left: `${Math.random() * 100}%`,
            top: `-${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 10}s`,
            fontSize: '20px',
          }}
        >
          <p className="text-sm">❤️</p>
        </div>
      ))}

      {/* Enhanced Header/Navbar */}
      <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white/95 backdrop-blur-sm shadow-md' : 'bg-transparent'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="flex-shrink-0"
            >
              <h1 className={`text-3xl font-serif italic ${isScrolled ? 'text-[#8B7355]' : 'text-white'} transition-colors duration-300`}>
                Digital RSVP
              </h1>
            </motion.div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-8">
              {[
                { name: 'Inicio', href: '/' },
                { name: 'XV Años', href: '/ejemplos-xv' },
                { name: 'Bodas', href: '/ejemplos-boda' },
                { name: 'Precios', href: '#precios' },
              ].map((item) => (
                <motion.a
                  key={item.name}
                  href={item.href}
                  className={`font-medium no-underline animate-pulse ${isScrolled ? 'text-gray-700 hover:text-[#8B7355]' : 'text-white/90 hover:text-white'} transition-colors duration-300`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {item.name}
                </motion.a>
              ))}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={(e) => {
                  e.preventDefault();
                  gtag_report_conversion();
                  window.open('https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20día,%20me%20gustaría%20información%20sobre%20las%20invitaciones%20digitales', '_blank');
                }}
                className={`px-6 py-2.5 rounded-full font-semibold transition-all duration-300
                  ${isScrolled 
                    ? 'bg-[#8B7355] text-white hover:bg-[#6d5a43] shadow-md hover:shadow-lg' 
                    : 'bg-white/10 text-white hover:bg-white/20 border-2 border-white/20'}`}
              >
                <span className="flex items-center">
                  Contactar
                  <FaWhatsapp className="ml-2" />
                </span>
              </motion.button>
            </nav>

            {/* Mobile menu button */}
            <motion.button
              whileTap={{ scale: 0.95 }}
              className="md:hidden p-2 rounded-lg"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <div className={`w-6 h-0.5 mb-1.5 transition-all duration-300 ${isScrolled ? 'bg-gray-800' : 'bg-white'} ${isMenuOpen ? 'transform rotate-45 translate-y-2' : ''}`}></div>
              <div className={`w-6 h-0.5 mb-1.5 transition-all duration-300 ${isScrolled ? 'bg-gray-800' : 'bg-white'} ${isMenuOpen ? 'opacity-0' : ''}`}></div>
              <div className={`w-6 h-0.5 transition-all duration-300 ${isScrolled ? 'bg-gray-800' : 'bg-white'} ${isMenuOpen ? 'transform -rotate-45 -translate-y-2' : ''}`}></div>
            </motion.button>
          </div>

          {/* Mobile Navigation Menu */}
          <motion.div
            initial={false}
            animate={{ height: isMenuOpen ? 'auto' : 0, opacity: isMenuOpen ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden overflow-hidden bg-white rounded-b-2xl shadow-lg"
          >
            <div className="px-4 py-2 space-y-1">
              {[
                { name: 'Inicio', href: '/' },
                { name: 'XV Años', href: '/ejemplos-xv' },
                { name: 'Bodas', href: '/ejemplos-boda' },
                { name: 'Precios', href: '#precios' },
              ].map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-4 text-base font-medium text-gray-700 hover:text-[#8B7355] border-b border-gray-100 last:border-0"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </a>
              ))}
              <div className="py-4">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    gtag_report_conversion();
                    window.open('https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20día,%20me%20gustaría%20información%20sobre%20las%20invitaciones%20digitales', '_blank');
                    setIsMenuOpen(false);
                  }}
                  className="w-full px-6 py-3 bg-[#8B7355] text-white rounded-full font-semibold hover:bg-[#6d5a43] transition-colors flex items-center justify-center"
                >
                  <span>Contactar</span>
                  <FaWhatsapp className="ml-2" />
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </header>

      {/* Banner Section */}
      <section className="pt-24 pb-16 bg-gradient-to-br from-green-800 via-green-700 to-green-900 text-white relative overflow-hidden">
        {/* Decorative background elements */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-64 h-64 bg-white rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-red-300 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-12 md:py-20 relative z-10">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div 
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="space-y-6 md:space-y-8 text-center md:text-left"
            >
              <h2 className="text-4xl md:text-6xl font-bold leading-tight">
                Invitaciones Web
                <span className="block text-[#FFD700] mt-2 font-serif italic">Personalizadas</span>
              </h2>
              <p className="text-lg md:text-xl text-gray-200 leading-relaxed">
                Crea una experiencia única y memorable para tus invitados con nuestras invitaciones digitales. 
                Diseños exclusivos que harán brillar tu evento especial. ✨
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
                <motion.a
                  href="/ejemplos-xv"
                  whileHover={{ scale: 1.05, boxShadow: "0 10px 30px -10px rgba(255, 215, 0, 0.4)" }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                  className="no-underline group relative inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-[#FFD700] to-[#FFA500] text-red-900 rounded-xl font-bold text-lg transition-all duration-300 overflow-hidden shadow-[0_5px_15px_-5px_rgba(255,215,0,0.3)]"
                >
                  <span className="relative z-10 flex items-center">
                    Ver Diseños
                    <svg className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-[#FFE55C] to-[#FFB52E] opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </motion.a>
                <motion.a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    gtag_report_conversion();
                    window.open('https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20día,%20me%20gustaría%20información%20sobre%20las%20invitaciones%20digitales', '_blank');
                  }}
                  whileHover={{ scale: 1.05, boxShadow: "0 10px 30px -10px rgba(255, 255, 255, 0.4)" }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                  className="no-underline group relative inline-flex items-center justify-center px-8 py-4 bg-white/10 backdrop-blur-sm text-white rounded-xl font-bold text-lg transition-all duration-300 border-2 border-white/20 overflow-hidden"
                >
                  <span className="relative z-10 flex items-center">
                    Cotizar Ahora
                    <FaWhatsapp className="ml-2 text-xl group-hover:scale-110 transition-transform duration-200" />
                  </span>
                  <div className="absolute inset-0 bg-white/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </motion.a>
              </div>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative"
            >
              <div className="grid grid-cols-2 gap-4 max-w-lg mx-auto">
                <motion.div 
                  className="transform rotate-[-5deg] hover:rotate-[-2deg] transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                >
                  <img 
                    src="https://i.postimg.cc/rFgH7w6L/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-1.png" 
                    alt="Invitación digital" 
                    className="rounded-2xl shadow-2xl w-full hover:shadow-3xl transition-shadow duration-300"
                  />
                </motion.div>
                <motion.div 
                  className="transform rotate-[5deg] hover:rotate-[2deg] transition-all duration-300 mt-8"
                  whileHover={{ scale: 1.05 }}
                >
                  <img 
                    src="https://i.postimg.cc/g0rr3jpc/Historia-Instagram-Frase-recordatorio-minimalista-blanco-marron-zip-4.png" 
                    alt="Invitación digital" 
                    className="rounded-2xl shadow-2xl w-full hover:shadow-3xl transition-shadow duration-300"
                  />
                </motion.div>
              </div>
              
              {/* Decorative elements */}
              <motion.div 
                animate={{ 
                  scale: [1, 1.2, 1],
                  rotate: [0, 360]
                }}
                transition={{ 
                  duration: 5,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="absolute top-0 right-0 text-5xl"
              >
                ✨
              </motion.div>
              <motion.div 
                animate={{ 
                  scale: [1, 1.2, 1],
                  rotate: [0, -360]
                }}
                transition={{ 
                  duration: 5,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="absolute bottom-0 left-0 text-5xl"
              >
                🌟
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Categories Section */}
      <section id="precios" className="py-8 md:py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12 animate-fade-in">
            Tipos de Eventos
          </h2>
          <div className="relative">
            <div className="flex overflow-hidden touch-pan-x" {...swipeHandlers}>
              <div 
                className="flex transition-transform duration-500 ease-out w-full" 
                style={{ transform: `translateX(-${currentCategoryIndex * 100}%)` }}
              >
                {categories.map((category, index) => (
                  <div key={index} className="w-full flex-shrink-0 px-2 md:px-4">
                    <div className={`relative group cursor-pointer overflow-hidden rounded-xl md:rounded-2xl shadow-xl h-[150px] md:h-[200px] ${category.gradient} hover:shadow-2xl transform transition-all duration-300 hover:-translate-y-2`}>
                      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 md:p-8 text-center">
                        <span className="text-4xl md:text-6xl mb-2 md:mb-4 animate-bounce">{category.icon}</span>
                        <h3 className="text-xl md:text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                          {category.title}
                        </h3>
                        <p className="mt-1 md:mt-2 text-sm md:text-base text-gray-600 hidden md:block">{category.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Indicadores más grandes en móvil para mejor interacción */}
            <div className="flex justify-center mt-4 gap-3">
              {categories.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 md:w-2 md:h-2 rounded-full transition-all duration-300 ${
                    currentCategoryIndex === index ? 'bg-blue-500 w-5 md:w-4' : 'bg-gray-300'
                  }`}
                  onClick={() => setCurrentCategoryIndex(index)}
                />
              ))}
            </div>

            {/* Botones de navegación más grandes en móvil */}
            <button 
              onClick={prevCategory} 
              className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 bg-white/80 p-3 md:p-2 rounded-full shadow-lg hover:bg-white transition-all duration-200 hover:scale-110 z-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button 
              onClick={nextCategory} 
              className="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 bg-white/80 p-3 md:p-2 rounded-full shadow-lg hover:bg-white transition-all duration-200 hover:scale-110 z-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-8 md:py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Elige tu Plan Perfecto</h2>
            <p className="text-lg md:text-xl font-semibold">
              ✨ Oferta especial termina en: {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
            {plans.map((plan, index) => (
              <div 
                key={index} 
                className={`bg-white mb-4 rounded-2xl shadow-xl overflow-hidden transform hover:scale-105 transition-transform duration-300 ${
                  plan.popular ? 'ring-4 ring-purple-500 scale-105 z-10' : ''
                }`}
              >
                <div className={`px-6 py-4 ${plan.color} ${plan.popular ? 'text-white' : ''}`}>
                  <h3 className="text-2xl font-bold text-center">{plan.name}</h3>
                  {plan.popular && (
                    <span className="block text-center text-sm bg-yellow-400 text-purple-900 px-3 py-1 rounded-full mx-auto w-max mt-2 font-semibold">
                      MÁS POPULAR
                    </span>
                  )}
                </div>
                
                <div className="p-6">
                  <div className="text-center mb-6">
                    <span className="text-gray-400 line-through text-lg">${plan.originalPrice}</span>
                    <div className="text-5xl font-bold text-gray-900 my-2">${plan.price}</div>
                    <div className="text-green-500 font-semibold">Ahorra ${plan.savings}</div>
                  </div>

                  <div className="mb-6">
                    <p className="text-sm text-gray-600 mb-4 font-medium">Elige uno de nuestros diseños exclusivos</p>
                    <div className="space-y-2">
                      {plan.examples.map((example, i) => (
                        <a 
                          key={i} 
                          href={example.href} 
                          className="block text-purple-600 hover:text-purple-700 hover:underline text-sm font-medium"
                        >
                          → {example.text}
                        </a>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-4 mb-8">
                    <h4 className="font-semibold text-gray-900">Incluye:</h4>
                    <ul className="space-y-3">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2 text-sm">
                          <svg className="w-5 h-5 text-green-500 shrink-0 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="text-gray-600">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <button 
                    className={`w-full py-4 rounded-xl font-bold text-white transition-colors ${
                      plan.popular 
                        ? 'bg-purple-600 hover:bg-purple-700' 
                        : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                  >
                    SELECCIONAR PLAN
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Beneficios Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Beneficios de las <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500">Invitaciones Digitales</span>
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Descubre por qué las invitaciones digitales son la mejor opción para tu evento especial
            </p>
          </motion.div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: "🌍",
                title: "Ecológicas",
                description: "Contribuye al medio ambiente eliminando el uso de papel",
                color: "from-green-500 to-emerald-500"
              },
              {
                icon: "💰",
                title: "Ahorra Costos",
                description: "Evita gastos de impresión y envío tradicional",
                color: "from-blue-500 to-cyan-500"
              },
              {
                icon: "📱",
                title: "Fácil Distribución",
                description: "Comparte instantáneamente por WhatsApp, email o redes sociales",
                color: "from-purple-500 to-pink-500"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="group"
              >
                <div className="bg-white rounded-2xl shadow-xl p-8 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 relative overflow-hidden">
                  <div className={`absolute inset-0 bg-gradient-to-br ${benefit.color} opacity-0 group-hover:opacity-5 transition-opacity duration-300`}></div>
                  <div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform duration-300">{benefit.icon}</div>
                  <h3 className="text-2xl font-bold mb-4">{benefit.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{benefit.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Características Destacadas */}
      <section className="py-20 bg-white relative">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Características <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Destacadas</span>
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Todo lo que necesitas para crear una invitación única y memorable
            </p>
          </motion.div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: "📍",
                title: "Ubicación con Google Maps",
                description: "Guía a tus invitados directamente al lugar del evento"
              },
              {
                icon: "📸",
                title: "Galería de Fotos",
                description: "Comparte tus mejores momentos con una galería personalizada"
              },
              {
                icon: "🎵",
                title: "Música de Fondo",
                description: "Añade tu canción favorita a la invitación"
              },
              {
                icon: "📅",
                title: "Confirmación de Asistencia",
                description: "Gestiona fácilmente la lista de invitados"
              },
              {
                icon: "⏰",
                title: "Cuenta Regresiva",
                description: "Mantén la emoción con un contador hasta el gran día"
              },
              {
                icon: "🎁",
                title: "Mesa de Regalos",
                description: "Integración con tiendas departamentales"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
                className="bg-gradient-to-br from-gray-50 to-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <div className="bg-gradient-to-br from-purple-100 to-pink-100 w-16 h-16 rounded-xl flex items-center justify-center mb-6">
                  <span className="text-3xl">{feature.icon}</span>
                </div>
                <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-4xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Preguntas Frecuentes
            </h2>
            <p className="text-gray-600 text-lg">
              Todo lo que necesitas saber sobre nuestras invitaciones digitales
            </p>
          </motion.div>
          <div className="space-y-6">
            {[
              {
                question: "¿Cómo funciona la invitación digital?",
                answer: "La invitación es una página web personalizada que puedes compartir fácilmente a través de un enlace. Tus invitados podrán acceder desde cualquier dispositivo."
              },
              {
                question: "¿Puedo personalizar los colores y textos?",
                answer: "¡Sí! Puedes personalizar colores, textos, imágenes y otros elementos para que coincidan con tu estilo y temática."
              },
              {
                question: "¿Cómo comparto la invitación?",
                answer: "Puedes compartir el enlace de tu invitación por WhatsApp, correo electrónico, redes sociales o cualquier plataforma de mensajería."
              },
              {
                question: "¿Por cuánto tiempo estará disponible mi invitación?",
                answer: "Tu invitación estará disponible por 1 año desde la fecha de compra."
              }
            ].map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <h3 className="text-xl font-bold mb-4 text-gray-800">{faq.question}</h3>
                <p className="text-gray-600 leading-relaxed">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 bg-white relative overflow-hidden">
        {/* Decorative background elements */}
        <div className="absolute inset-0 bg-gradient-to-br from-purple-50 via-transparent to-pink-50 opacity-70"></div>
        <div className="absolute inset-0">
          <div className="absolute top-20 left-20 w-72 h-72 bg-purple-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob"></div>
          <div className="absolute bottom-20 right-20 w-72 h-72 bg-yellow-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob animation-delay-2000"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-pink-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob animation-delay-4000"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-sm font-semibold text-purple-600 tracking-wider uppercase mb-2 block">Testimonios</span>
            <h2 className="text-4xl md:text-5xl font-bold mb-4">
              Lo que dicen nuestros <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">Clientes</span>
            </h2>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Descubre por qué nuestros clientes aman nuestras invitaciones digitales ⭐️
            </p>
          </motion.div>

          <div className="relative px-4 md:px-12">
            <div className="flex overflow-hidden">
              <motion.div 
                className="flex transition-transform duration-500 ease-out w-full"
                style={{ transform: `translateX(-${currentDesignIndex * 100}%)` }}
                drag="x"
                dragConstraints={{ left: -window.innerWidth * 2, right: 0 }}
                dragElastic={0.2}
                dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);
                  
                  if (swipe < -swipeConfidenceThreshold) {
                    nextDesign();
                  } else if (swipe > swipeConfidenceThreshold) {
                    prevDesign();
                  }
                }}
              >
                {[
                  {
                    name: "Ana García",
                    event: "XV Años", 
                    image: "https://i.pravatar.cc/150?img=1",
                    review: "¡Increíble servicio! La invitación quedó hermosa y todos mis invitados quedaron encantados. El proceso fue muy fácil y el resultado superó mis expectativas.",
                    rating: 5
                  },
                  {
                    name: "Carlos Rodríguez",
                    event: "Boda",
                    image: "https://i.pravatar.cc/150?img=5", 
                    review: "Elegimos Digital RSVP para nuestra boda y fue la mejor decisión. La invitación era elegante, moderna y práctica. El seguimiento de confirmaciones fue muy útil.",
                    rating: 5
                  },
                  {
                    name: "María Fernanda",
                    event: "Baby Shower",
                    image: "https://i.pravatar.cc/150?img=2",
                    review: "Me encantó poder personalizar cada detalle de la invitación. El servicio al cliente fue excelente y siempre respondieron rápido a mis dudas.",
                    rating: 5
                  },
                  {
                    name: "Laura Martínez",
                    event: "XV Años",
                    image: "https://i.pravatar.cc/150?img=32",
                    review: "La invitación digital fue perfecta para mi fiesta. Todos mis amigos comentaron lo bonita que estaba y lo fácil que fue confirmar su asistencia. ¡Totalmente recomendado!",
                    rating: 5
                  }
                ].map((testimonial, index) => (
                  <motion.div 
                    key={index} 
                    className="w-full flex-shrink-0 p-4 md:p-8"
                    initial={{ opacity: 0, scale: 0.8 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 cursor-grab active:cursor-grabbing">
                      <div className="flex items-center gap-4 mb-6">
                        <img 
                          src={testimonial.image} 
                          alt={testimonial.name}
                          className="w-16 h-16 rounded-full object-cover ring-4 ring-purple-100" 
                        />
                        <div>
                          <h3 className="text-xl font-bold text-gray-900">{testimonial.name}</h3>
                          <p className="text-purple-600 font-medium">{testimonial.event}</p>
                        </div>
                      </div>
                      <div className="flex mb-4">
                        {[...Array(testimonial.rating)].map((_, i) => (
                          <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                      <blockquote className="text-gray-700 leading-relaxed mb-6">
                        "{testimonial.review}"
                      </blockquote>
                      <div className="flex justify-end">
                        <span className="inline-flex items-center text-sm text-purple-600">
                          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg>
                          Cliente verificado
                        </span>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>

            {/* Navigation Buttons */}
            <motion.button 
              onClick={prevDesign}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-3 md:p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 z-10"
              whileHover={{ scale: 1.1, backgroundColor: "rgba(255, 255, 255, 0.95)" }}
              whileTap={{ scale: 0.95 }}
            >
              <svg className="w-6 h-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </motion.button>
            <motion.button 
              onClick={nextDesign}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-3 md:p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 z-10"
              whileHover={{ scale: 1.1, backgroundColor: "rgba(255, 255, 255, 0.95)" }}
              whileTap={{ scale: 0.95 }}
            >
              <svg className="w-6 h-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </motion.button>

            {/* Dots Indicator */}
            <div className="flex justify-center mt-8 gap-3">
              {[0, 1, 2].map((index) => (
                <button
                  key={index}
                  onClick={() => setCurrentDesignIndex(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    currentDesignIndex === index 
                      ? 'bg-purple-600 w-8'
                      : 'bg-gray-300 hover:bg-purple-400'
                  }`}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Enhanced Footer */}
      <footer className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white relative overflow-hidden">
        {/* Decorative background elements */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-64 h-64 bg-white rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-gray-300 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-16 relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-8">
            {/* Brand Section */}
            <div className="text-center md:text-left">
              <motion.h3 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-3xl font-serif italic text-[#8B7355] mb-4"
              >
                Digital RSVP
              </motion.h3>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-gray-400 mb-6"
              >
                Creamos invitaciones digitales únicas y memorables para tus eventos especiales.
              </motion.p>
            </div>

            {/* Quick Links */}
            <div className="text-center">
              <motion.h4 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-lg font-semibold mb-4 text-[#8B7355]"
              >
                Enlaces Rápidos
              </motion.h4>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="space-y-2"
              >
                <a href="/ejemplos-xv" className="block text-gray-400 hover:text-white transition-colors duration-300">XV Años</a>
                <a href="/ejemplos-boda" className="block text-gray-400 hover:text-white transition-colors duration-300">Bodas</a>
                <a href="#precios" className="block text-gray-400 hover:text-white transition-colors duration-300">Precios</a>
              </motion.div>
            </div>

            {/* Contact Section */}
            <div className="text-center md:text-right">
              <motion.h4 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-lg font-semibold mb-4 text-[#8B7355]"
              >
                Síguenos
              </motion.h4>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="flex justify-center md:justify-end space-x-6"
              >
                <motion.a 
                  href="https://www.facebook.com/profile.php?id=61556089589338" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-500 transition-colors duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FaFacebook size={24} />
                </motion.a>
                <motion.a 
                  href="https://www.instagram.com/digitalrsvp20/" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-pink-500 transition-colors duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FaInstagram size={24} />
                </motion.a>
                <motion.a 
                  href="https://m.me/61556089589338?text=Hola%2C%20me%20gustar%C3%ADa%20informaci%C3%B3n%20sobre%20las%20invitaciones%20digitales"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-600 transition-colors duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FaFacebookMessenger size={24} />
                </motion.a>
              </motion.div>
            </div>
          </div>

          {/* CTA Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center pt-12 border-t border-gray-800"
          >
            <h3 className="text-2xl font-bold mb-6">¿Listo para crear tu invitación?</h3>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={(e) => {
                e.preventDefault();
                gtag_report_conversion();
                window.open('https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20día,%20me%20gustaría%20información%20sobre%20las%20invitaciones%20digitales', '_blank');
              }}
              className="inline-flex items-center px-8 py-3 bg-[#8B7355] text-white rounded-full font-semibold hover:bg-[#6d5a43] transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <span>Contactar Ahora</span>
              <FaWhatsapp className="ml-2" />
            </motion.button>
          </motion.div>

          {/* Copyright */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center mt-12 text-gray-500"
          >
            <p>Your Invitation VIP | Copyright © {new Date().getFullYear()}</p>
          </motion.div>
        </div>
      </footer>

      {/* Floating WhatsApp Button */}
      <motion.a
        href="https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20día,%20me%20gustaría%20información%20sobre%20las%20invitaciones%20digitales"
        onClick={() => gtag_report_conversion()}
        className="fixed no-underline bottom-6 animate-bounce right-6 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300 z-50 flex items-center gap-2"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.372.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
        </svg>
        <span className="font-semibold">WhatsApp</span>
      </motion.a>
    </div>
  )
}

