import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import YouTube from "react-youtube";
import FormattedText from "../functions/FormattedText";

/**
 * Valida si una cadena de texto es un enlace de YouTube.
 * @param {string} input - La cadena de texto a validar.
 * @returns {boolean} - `true` si la cadena es un enlace de YouTube, `false` en caso contrario.
 */
const isYouTubeLink = (input) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubeRegex.test(input);
};

/**
 * Extrae el ID del video de un enlace de YouTube.
 * @param {string} url - El enlace de YouTube.
 * @returns {string|null} - El ID del video de YouTube o `null` si no se puede extraer.
 */
const extractYouTubeID = (url) => {
  const idRegex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(idRegex);
  return match ? match[1] : null;
};

export const PortadaXvComponent = ({ datosSeccionPortada, screenMobile }) => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isYouTube, setIsYouTube] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cancionYoutube, setCancionYoutube] = useState("");
  const playerRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const reproYoutube = (vali) => {
    if (vali) {
      setIsYouTube(true);
      const youtubeID = extractYouTubeID(datosSeccionPortada.cancion);
      console.log("ID del video de YouTube:", youtubeID);
      setCancionYoutube(youtubeID);
    }
  };

  const onReady = (event) => {
    playerRef.current = event.target;
    playerRef.current.playVideo(); // Autoplay the video
  };

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const validacion = isYouTubeLink(datosSeccionPortada.cancion);
    reproYoutube(validacion);
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  // Aplica el tipo de letra si está definido
  const fontFamilyStyle = {
    fontFamily: datosSeccionPortada.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
    color: datosSeccionPortada.textColor || "#ffffff",
  };

  
  console.log(datosSeccionPortada)
  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionPortada.imgPortada})`,
        backgroundRepeat: "no-repeat",
        height: "100vh", // Ajusta la altura del contenedor
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        ...fontFamilyStyle, // Aplicar el tipo de letra a todo el div
      }}
      className={`bg-slate-950 flex flex-col ${screenMobile ? 'bg-cover' : 'sm:bg-cover md:bg-full'} justify-center items-center w-full`}
    >
      <div
        className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
        style={{ backgroundColor: datosSeccionPortada.titulo === "Nada"?"":"rgba(0, 0, 0, 0.3)", ...fontFamilyStyle }}
      >
        {isYouTube ? (
          <YouTube
            videoId={cancionYoutube}
            opts={{
              height: "1", // Cambia a un tamaño pequeño pero visible
              width: "1",
              playerVars: {
                autoplay: 1,
                controls: 0,
              },
            }}
            onReady={onReady}
          />
        ) : (
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPortada.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
        )}

        {datosSeccionPortada.cancion && (
          <div className="w-full flex justify-end h-full items-start fixed ">
            {isYouTube ? (
              <button
                onClick={() => {
                  if (playerRef.current) {
                    if (isPlaying) {
                      playerRef.current.pauseVideo();
                    } else {
                      playerRef.current.playVideo();
                    }
                    setIsPlaying(!isPlaying);
                  }
                }}
                className="text-3xl rounded animate-bounce bg-black p-1"
              >
                {isPlaying ? "🔊" : "🔇"}
              </button>
            ) : (
              <button
                onClick={handlePlayButtonClick}
                className="text-3xl rounded animate-bounce bg-black p-1"
              >
                {isMusicPlaying ? "🔊" : "🔇"}
              </button>
            )}
          </div>
        )}
        {datosSeccionPortada.titulo === "Nada" ? (
          ""
        ) : (
          <>
            <br />
            <br />
            <h1
              className={`text-4xl ${screenMobile ? "text-4xl" : "sm:text-4xl md:text-[132px]"} text-center flex ${datosSeccionPortada.posicion === "arriba" ? "mt-[-250px] mb-[300px]" : ""}`}
              data-aos="flip-up"
              style={fontFamilyStyle} // Aplicar el tipo de letra al h1
            >
              <FormattedText text={datosSeccionPortada?.nombre} />
            </h1>
            <br />
            <br />
            <p
              className={`p-3 rounded-lg text-2xl text-center`}
              data-aos="fade-up"
              style={fontFamilyStyle} // Aplicar el tipo de letra al p
            >
              {datosSeccionPortada.titulo
                ? `- ${datosSeccionPortada.titulo} -`
                : ""}{" "}
              <br />
              {datosSeccionPortada.fechaDeBoda
                .split("\\n")
                .map((linea, index) => (
                  <React.Fragment key={index}>
                    {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                      parte.startsWith("/") && parte.endsWith("/") ? (
                        <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                      ) : (
                        <span key={subIndex}>{parte}</span>
                      )
                    ))}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </>
        )}

        <br />
      </div>
    </div>
  );
};
