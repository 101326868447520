import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCamera, FaDownload, FaShare, FaTimes, FaComment, FaHeart, FaPlus, FaUserPlus } from 'react-icons/fa';

// Actualizar las variables de color para que coincidan
const colors = {
  primary: '#1a1a1a',
  secondary: '#f5f5f5',
  accent: '#D4AF37',
  surface: '#ffffff',
  text: '#2d2d2d'
};

// Agregar los mismos estilos
const styles = `
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  padding: 12px;
}

.image-item {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.image-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: ${colors.accent};
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
}

.floating-button:hover {
  transform: scale(1.1);
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.5), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-item:hover .image-overlay {
  opacity: 1;
}

.like-button {
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.like-button:active {
  transform: scale(1.5);
}
`;

export const InvitadoView = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const invitadoId = searchParams.get('invitado');
  const navigate = useNavigate();
  
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadingImages, setUploadingImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imageText, setImageText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [likesList, setLikesList] = useState([]);
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [galeriaInfo, setGaleriaInfo] = useState(null);
  const [invitadoData, setInvitadoData] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [invitados, setInvitados] = useState([]);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    const verificarInvitado = async () => {
      try {
        const response = await axios.get(`/api/Invitado/lista/${invitadoId}`);
        if (!response.data || response.data.idEvento !== parseInt(id)) {
          navigate(`/img-event/${id}`);
        }
      } catch (error) {
        console.error('Error verificando invitado:', error);
        navigate(`/img-event/${id}`);
      }
    };

    if (!invitadoId) {
      navigate(`/img-event/${id}`);
    } else {
      verificarInvitado();
    }
  }, [id, invitadoId, navigate]);

  useEffect(() => {
    fetchImages();
    fetchInvitado();
    fetchGaleriaInfo();
    fetchAlbums();
    fetchInvitados();
  }, [id]);

  const fetchImages = async (albumId = null) => {
    try {
      const response = await axios.get(`/api/ImagenesEvento/evento/${id}`);
      const allImagesData = response.data;
      setAllImages(allImagesData);
      
      if (albumId) {
        setImages(allImagesData.filter(img => img.idAlbum === albumId));
      } else {
        setImages(allImagesData);
      }
      setLoading(false);
    } catch (err) {
      setError('Error al cargar las imágenes');
      setLoading(false);
    }
  };

  const fetchInvitado = async () => {
    try {
      console.log('invitadoId:', invitadoId);
      const response = await axios.get(`/api/Invitado/lista/${invitadoId}`);
      console.log('Respuesta:', response.data); // Debug
      if (response.data) {
        setInvitadoData(response.data);
      }
    } catch (error) {
      console.error('Error al cargar información del invitado:', error);
    }
  };

  const fetchGaleriaInfo = async () => {
    try {
      const response = await axios.get(`/api/GaleriaEventoAdmin/Evento/${id}`);
      if (response.data && response.data.length > 0) {
        setGaleriaInfo(response.data[0]);
      }
    } catch (error) {
      console.error('Error al cargar información de la galería:', error);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`/api/Album/Evento/${id}`);
      setAlbums(response.data);
    } catch (error) {
      console.error('Error al cargar álbumes:', error);
    }
  };

  const fetchInvitados = async () => {
    try {
      const response = await axios.get(`/api/Invitado/evento/${id}`);
      setInvitados(response.data);
    } catch (err) {
      console.error('Error al cargar invitados:', err);
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setUploadingImages(files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    })));
  };

  const handleUpload = async () => {
    if (!selectedAlbum) {
      setError('Por favor selecciona un álbum');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      for (const imgData of uploadingImages) {
        const formData = new FormData();
        formData.append("file", imgData.file);

        const azureResponse = await axios.post('/api/ImagenesAzure/Upload', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        });

        const imageData = {
          urlImg: azureResponse.data.url,
          imageName: imgData.file.name,
          imageType: imgData.file.type,
          uploadDate: new Date().toISOString(),
          idEvento: parseInt(id),
          texto: imageText,
          idAlbum: selectedAlbum.idAlbum
        };

        await axios.post('/api/ImagenesEvento', imageData);
      }

      // Recargar imágenes del álbum actual
      await fetchImages(selectedAlbum.idAlbum);
      
      // Limpiar estado
      setUploadingImages([]);
      setImageText('');
      setIsUploading(false);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error al subir imágenes:', error);
      setError('Error al subir las imágenes');
      setIsUploading(false);
    }
  };

  const handleDownloadImage = async (imageUrl, index) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `imagen_${index + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar la imagen:', error);
      setError('Error al descargar la imagen');
    }
  };

  const handleImageSelect = async (image) => {
    try {
      // Obtener el conteo actualizado de likes
      const likesCount = await axios.get(`/api/Likes/Image/${image.idImagenesEvento}`);
      // Obtener la lista de personas que dieron like
      const likesDetails = await axios.get(`/api/Likes/Details/${image.idImagenesEvento}`);
      
      setSelectedImage({
        ...image,
        likes: likesCount.data  // Usar el conteo real de likes
      });
      setLikesList(likesDetails.data);
    } catch (error) {
      console.error('Error al obtener detalles de la imagen:', error);
      setSelectedImage(image);
    }
  };

  const handleLike = async (imageId) => {
    try {
      if (!invitadoId) {
        setError('No se puede dar like sin identificación de invitado');
        return;
      }

      // Enviar la petición de like/unlike
      await axios.post('/api/Likes', {
        idInvitado: parseInt(invitadoId),
        idimagenesEvento: imageId
      });

      // Obtener el nuevo conteo real de likes
      const likesCount = await axios.get(`/api/Likes/Image/${imageId}`);
      const likesDetails = await axios.get(`/api/Likes/Details/${imageId}`);

      // Actualizar el estado local con el conteo real
      setImages(images.map(img => 
        img.idImagenesEvento === imageId 
          ? { ...img, likes: likesCount.data }
          : img
      ));

      if (selectedImage?.idImagenesEvento === imageId) {
        setSelectedImage(prev => ({ 
          ...prev, 
          likes: likesCount.data
        }));
        setLikesList(likesDetails.data);
      }

    } catch (error) {
      console.error('Error al gestionar like:', error);
      setError('Error al gestionar el like de la imagen');
    }
  };

  // Agregar una nueva función para obtener likes
  const getLikesCount = async (imageId) => {
    try {
      const likesCount = await axios.get(`/api/Likes/Image/${imageId}`);
      return likesCount.data;
    } catch (error) {
      console.error('Error al obtener likes:', error);
      return 0;
    }
  };

  // Calcular totales cuando cambien las imágenes
  useEffect(() => {
    const likes = images.reduce((total, image) => total + (image.likes || 0), 0);
    const comments = images.reduce((total, image) => total + (image.comments?.length || 0), 0);
    
    setTotalLikes(likes);
    setTotalComments(comments);
  }, [images]);

  // Agregar el efecto para los estilos
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const handleDelete = async (image) => {
    try {
      // 1. Eliminar de Azure
      const fileName = image.urlImg.split('/').pop();
      await axios.delete(`/api/ImagenesAzure/Delete/${fileName}`);

      // 2. Eliminar de la base de datos
      await axios.delete(`/api/ImagenesEvento/${image.idImagenesEvento}`);

      // 3. Actualizar la lista
      setImages(images.filter(img => img.idImagenesEvento !== image.idImagenesEvento));
    } catch (err) {
      setError('Error al eliminar la imagen');
      console.error('Error deleting image:', err);
    }
  };

  const handleAlbumSelect = (album) => {
    setSelectedAlbum(album);
    fetchImages(album.idAlbum);
  };

  // Agregar función para obtener la primera imagen de un álbum
  const getFirstImageFromAlbum = (albumId) => {
    return allImages.find(img => img.idAlbum === albumId)?.urlImg;
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-[468px] mx-auto">
        {/* Header con imagen de portada y nombre */}
        <div className="relative h-[300px]">
          <div className="absolute inset-0">
            <img
              src={galeriaInfo?.foto || '/default-gallery.png'}
              alt="Portada"
              className="w-full h-full object-cover"
            />
            {/* Overlay gradiente */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black/60" />
          </div>
          
          {/* Contenido sobre la imagen */}
          <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
            <h1 className="text-2xl font-semibold mb-1">
              {galeriaInfo ? galeriaInfo.nombre : 'Álbum del Evento'}
            </h1>
            <p className="text-sm opacity-90">
              {new Date().toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </p>
          </div>
        </div>

        {/* Barra de navegación */}
        <div className="flex justify-around py-4 border-b">
          <button 
            onClick={() => document.getElementById('fileInput').click()}
            className="flex flex-col items-center gap-1 text-gray-600"
          >
            <FaPlus className="text-xl" />
            <span className="text-xs">Subir</span>
          </button>
          <div className="flex flex-col items-center gap-1 text-gray-600">
            <div className="relative">
              <FaCamera className="text-xl" />
              <span className="absolute -top-2 -right-2 bg-[#D4AF37] text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                {images?.length || 0}
              </span>
            </div>
            <span className="text-xs">Fotos</span>
          </div>
          <div className="flex flex-col items-center gap-1 text-gray-600">
            <div className="relative">
              <FaHeart className="text-xl" />
              <span className="absolute -top-2 -right-2 bg-[#D4AF37] text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                {totalLikes || 0}
              </span>
            </div>
            <span className="text-xs">Me gusta</span>
          </div>
        </div>

        {/* Input file oculto */}
        <input
          type="file"
          id="fileInput"
          className="hidden"
          multiple
          accept="image/*"
          onChange={handleFileSelect}
        />

        {/* Sección de álbumes deslizable */}
        <div className="px-2 py-2">
          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex gap-2 min-w-max">
              {/* Opción "Todas las fotos" */}
              <button
                onClick={() => {
                  setSelectedAlbum(null);
                  fetchImages();
                }}
                className={`flex flex-col w-[30vh] relative ${
                  !selectedAlbum ? 'opacity-100' : 'opacity-70'
                }`}
              >
                <div className={`aspect-square rounded-lg overflow-hidden mb-1 ${
                  !selectedAlbum ? 'ring-2 ring-[#D4AF37]' : ''
                }`}>
                  {allImages.length > 0 ? (
                    <img
                      src={allImages[0]?.urlImg}
                      alt="Todas las fotos"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                      <FaCamera className="text-xl text-gray-400" />
                    </div>
                  )}
                </div>
                <span className="text-xs text-center text-gray-700 font-medium truncate">
                  Todas las fotos
                </span>
              </button>

              {/* Álbumes */}
              {albums.map(album => (
                <button
                  key={album.idAlbum}
                  onClick={() => handleAlbumSelect(album)}
                  className={`flex flex-col w-[30vh] relative ${
                    selectedAlbum?.idAlbum === album.idAlbum ? 'opacity-100' : 'opacity-70'
                  }`}
                >
                  <div className={`aspect-square rounded-lg overflow-hidden mb-1 ${
                    selectedAlbum?.idAlbum === album.idAlbum ? 'ring-2 ring-[#D4AF37]' : ''
                  }`}>
                    {album.imagenPortada || getFirstImageFromAlbum(album.idAlbum) ? (
                      <img
                        src={album.imagenPortada || getFirstImageFromAlbum(album.idAlbum)}
                        alt={album.nombre}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                        <FaCamera className="text-xl text-gray-400" />
                      </div>
                    )}
                  </div>
                  <span className="text-xs text-center text-gray-700 font-medium truncate">
                    {album.nombre}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Grid de fotos */}
        <div className="grid grid-cols-3 p-2 gap-[2px]">
          {images.map((image, index) => (
            <motion.div
              key={image.idImagenesEvento}
              className="relative aspect-square"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.05 }}
              onClick={() => handleImageSelect(image)}
            >
              <img
                src={image.urlImg}
                alt={`Imagen ${image.idImagenesEvento}`}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black/20 opacity-0 hover:opacity-100 transition-opacity duration-200 flex items-center justify-center">
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                    <FaHeart className="text-white" />
                    <span className="text-white">{image.likes}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <FaComment className="text-white" />
                    <span className="text-white">{image.comments?.length || 0}</span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Mantener los modales existentes */}
        <AnimatePresence>
          {uploadingImages.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center p-4"
            >
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-white rounded-xl p-6 max-w-lg w-full"
                onClick={e => e.stopPropagation()}
              >
                <h3 className="text-lg font-medium mb-4">Vista previa</h3>

                {/* Selector de álbum */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Seleccionar álbum
                  </label>
                  <select
                    value={selectedAlbum?.idAlbum || ''}
                    onChange={(e) => {
                      const albumId = parseInt(e.target.value);
                      const album = albums.find(a => a.idAlbum === albumId);
                      setSelectedAlbum(album || null);
                    }}
                    className="w-full p-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#D4AF37]"
                  >
                    <option value="">Seleccionar álbum</option>
                    {albums.map(album => (
                      <option key={album.idAlbum} value={album.idAlbum}>
                        {album.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="grid grid-cols-3 gap-3 mb-4">
                  {uploadingImages.map((img, index) => (
                    <div key={index} className="relative aspect-square">
                      <img
                        src={img.preview}
                        alt="Preview"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <button
                        onClick={() => {
                          const newImages = [...uploadingImages];
                          URL.revokeObjectURL(newImages[index].preview);
                          newImages.splice(index, 1);
                          setUploadingImages(newImages);
                        }}
                        className="absolute -top-2 -right-2 bg-black/50 text-white p-1.5 rounded-full hover:bg-black transition-colors"
                      >
                        <FaTimes className="text-xs" />
                      </button>
                    </div>
                  ))}
                </div>
                <textarea
                  value={imageText}
                  onChange={(e) => setImageText(e.target.value)}
                  placeholder="Escribe un mensaje para tus fotos..."
                  className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D4AF37] text-sm"
                  rows="3"
                />

                {/* Mensaje de error si no se selecciona álbum */}
                {uploadingImages.length > 0 && !selectedAlbum && (
                  <p className="text-red-500 text-sm mt-2">
                    Por favor selecciona un álbum para las imágenes
                  </p>
                )}

                <div className="flex gap-3">
                  <button
                    onClick={() => setUploadingImages([])}
                    className="flex-1 py-3 px-4 border border-gray-200 rounded-xl text-gray-600 hover:border-gray-300 transition-colors text-sm font-light"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleUpload}
                    disabled={isUploading || !selectedAlbum}
                    className="flex-1 py-3 px-4 bg-[#D4AF37] text-white rounded-xl hover:bg-[#B4934A] transition-colors text-sm font-light disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isUploading ? (
                      <div className="flex items-center justify-center gap-2">
                        <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                        <span>{uploadProgress}%</span>
                      </div>
                    ) : (
                      'Subir fotos'
                    )}
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}

          {selectedImage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/95 z-50 p-4"
              onClick={() => setSelectedImage(null)}
            >
              <div className="h-full flex flex-col">
                <div className="flex-grow flex items-center justify-center relative">
                  <img
                    src={selectedImage.urlImg}
                    alt="Imagen ampliada"
                    className={`max-w-full max-h-[80vh] object-contain transition-all duration-300 ${
                      selectedImage.showMessage ? 'blur-sm' : ''
                    }`}
                  />
                  {selectedImage.texto && selectedImage.showMessage && (
                    <div className="absolute inset-0 flex items-center justify-center p-6">
                      <div className="bg-black/70 text-white p-6 rounded-[4px] max-w-md text-center">
                        <p className="text-lg">{selectedImage.texto}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-center gap-1 p-4">
                  <button
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (selectedImage) {
                        await handleLike(selectedImage.idImagenesEvento);
                        console.log('Personas que dieron like:', likesList);
                      }
                    }}
                    className="p-4 rounded-full text-[#D4AF37] transition-colors flex items-center gap-2"
                  >
                    <FaHeart className={`text-xl ${
                      selectedImage?.likes > 0 ? 'text-red-500' : 'text-[#D4AF37]'
                    }`} />
                    <span className="text-white">{selectedImage?.likes || 0}</span>
                  </button>
                  {selectedImage.texto && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(prev => ({ ...prev, showMessage: !prev.showMessage }));
                      }}
                      className="p-4 rounded-full text-[#D4AF37] transition-colors"
                    >
                      <FaComment className={selectedImage.showMessage ? 'opacity-50' : ''} />
                    </button>
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownloadImage(selectedImage.urlImg, selectedImage.idImagenesEvento);
                    }}
                    className="p-4 rounded-full text-white"
                  >
                    <FaDownload />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (navigator.share) {
                        navigator.share({
                          title: 'Compartir imagen',
                          text: selectedImage.texto || 'Mira esta foto del evento',
                          url: selectedImage.urlImg
                        });
                      }
                    }}
                    className="p-4 rounded-full text-white"
                  >
                    <FaShare />
                  </button>
                </div>
              </div>
            </motion.div>
          )}

          {error && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="fixed bottom-20 left-4 right-4 bg-red-500 text-white p-4 rounded-xl"
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Agregar estilos para ocultar la barra de desplazamiento */}
        <style jsx>{`
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }
          .scrollbar-hide {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
        `}</style>
      </div>
    </div>
  );
}; 