'use client'

import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import FormattedText from '../functions/FormattedText';

export const VestimentaBodaComponent2 = ({ datosSeccionVestimenta, colores, isEnglish }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    })
  }, [])

  const fontFamilyStyle = {
    fontFamily: datosSeccionVestimenta.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: datosSeccionVestimenta.textColor || '#000000'
  };

  const backgroundStyle = {
    backgroundImage: `url(${datosSeccionVestimenta.imgFondo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  // Parse colores from string if it exists
  const paletaColores = datosSeccionVestimenta.colores ? JSON.parse(datosSeccionVestimenta.colores) : null;

  return (
    <div className="min-h-auto bg-[#E5DDD3] px-0 py-24" style={{...backgroundStyle, backgroundColor: colores?.color1 || '#E5DDD3'}}>
      <div className="w-full">
        {/* Top curved section with title */}
        <div 
          data-aos="fade-down"
          className="relative min-w-full"
        >
          <div className="bg-[#C2B6AA] pb-8 pt-4 rounded-b-[50%]" style={{backgroundColor: colores?.color2 || '#C2B6AA'}}>
            <h1 className="text-center font-serif text-4xl text-white" style={textStyle}>
              {isEnglish ? "Dress Code" : "Código de Vestimenta"}
            </h1>
          </div>
        </div>

        {/* Content section */}
        <div 
          data-aos="fade-up"
          data-aos-delay="200"
          className="relative mx-4 mt-4 rounded-3xl bg-white p-8 shadow-lg"
        >
          <h2 className="text-center font-serif text-2xl text-[#8B8178] mb-8" style={textStyle}>
            {datosSeccionVestimenta.vestimenta || (isEnglish ? "Formal" : "Formal")}
          </h2>

            {datosSeccionVestimenta.messageVestimenta &&(
              <p className="text-center font-serif text-2xl text-[#8B8178] mb-8" style={textStyle}>
                <FormattedText text={datosSeccionVestimenta.messageVestimenta} />
              </p>
            )}
          <div className="grid grid-cols-2 gap-8">
            {/* Men's section */}
            <div
              data-aos="fade-right"
              className="text-center"
            >
              <div className="mx-auto h-24 w-24 rounded-full bg-[#C2B6AA] flex items-center justify-center mb-4 hover:scale-110 transition-transform duration-300" style={{backgroundColor: colores?.color1 || '#C2B6AA'}}>
                <img
                  src={datosSeccionVestimenta.urlVestimentaHombre || "https://i.postimg.cc/9Xsfnxz5/24.png"}
                  alt={isEnglish ? "Men's Attire" : "Vestimenta Hombre"}
                  className="w-20 h-20 object-contain"
                />
              </div>
              <h3 className="font-serif text-xl text-[#8B8178] mb-2" style={textStyle}>{isEnglish ? "Men" : "Hombres"}</h3>
              <p className="text-sm text-[#8B8178]" style={textStyle}>
                <FormattedText text={datosSeccionVestimenta.textoHombre || (isEnglish ? "Full Suit" : "Traje Completo")} />
              </p>
            </div>

            {/* Women's section */}
            <div
              data-aos="fade-left"
              className="text-center"
            >
              <div className="mx-auto h-24 w-24 rounded-full bg-[#C2B6AA] flex items-center justify-center mb-4 hover:scale-110 transition-transform duration-300" style={{backgroundColor: colores?.color1 || '#C2B6AA'}}>
                <img
                  src={datosSeccionVestimenta.urlVestimentaMujer || "https://i.postimg.cc/2j16Tpk7/25.png"}
                  alt={isEnglish ? "Women's Attire" : "Vestimenta Mujer"}
                  className="w-20 h-20 object-contain"
                />
              </div>
              <h3 className="font-serif text-xl text-[#8B8178] mb-2" style={textStyle}>{isEnglish ? "Women" : "Mujeres"}</h3>
              <p className="text-sm text-[#8B8178]" style={textStyle}>
                <FormattedText text={datosSeccionVestimenta.textoMujer || (isEnglish ? "Full Dress" : "Vestido Completo")} />
              </p>
            </div>
          </div>
        </div>

        {/* Color palette section */}
        {paletaColores && (
          <div 
            data-aos="fade-up"
            data-aos-delay="600"
            className="relative mx-4 mt-8 rounded-3xl bg-white p-8 shadow-lg"
          >
            <h2 className="text-center font-serif text-2xl text-[#8B8178] mb-8" style={textStyle}>
              {isEnglish ? "Color Palette" : "Paleta de colores"}
            </h2>
            <div className="flex justify-center gap-1">
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color1 }}></div>
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color2 }}></div>
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color3 }}></div>
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color4 }}></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
