import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "reactstrap";
import GaleriaTable from "../../components/ComponentsAuto/GaleriaTable";
import { ImageFondo } from "./FormsComponent/Images/ImageFondo";
import { validateToken } from "../../components/validateToken";
import toast from "react-hot-toast";
import SelectTipoLetra from "../../components/functions/SelectTipoLetra";
import { SelectIcons } from "../../components/functions/SelectIcons";
import { SelectSobre } from "../../components/functions/SelectSobre";
import { AddHistorial } from "../../components/functions/AddHistorial";
import { InstagramForm } from "./DetallesComponent/InstagramForm";
import { ColoresEventoForm } from './DetallesComponent/ColoresEventoForm';
import { ContadorForm } from './DetallesComponent/ContadorForm';
import { PadresForm } from './DetallesComponent/PadresForm';
import { CeremoniaForm } from './DetallesComponent/CeremoniaForm';
import { ConfirmacionForm } from './DetallesComponent/ConfirmacionForm';
import { RegalosForm } from './DetallesComponent/RegalosForm';
import { VestimentaForm } from './DetallesComponent/VestimentaForm';
import { InvitadosForm } from './DetallesComponent/InvitadosForm';
import { DetallesNavbar } from './DetallesComponent/DetallesNavbar';
import { PortadaXvForm } from './DetallesComponent/PortadaXvForm';
import { HospedajeForm } from "./DetallesComponent/HospedajeForm";
import { ProgramaXvForm } from "./DetallesComponent/ProgramaXvForm";

export const XvDetalles = () => {
  const [datosInvitacion, setDatosInvitacion] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataEvento, setDataEvento] = useState({});
  const [userName, setUserName] = useState("");
  const [resetMessage, setResetMessage] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idClient = searchParams.get("id");

  const fetchData = async () => {
    try {
      const responseEvent = await axios.get(`/api/Eventos/${idClient}`);
      setDataEvento(responseEvent.data);
      console.log("Evento", responseEvent.data);
      const response = await axios.get(
        `/api/Seccionportada/obtenerDiseno2xv/${idClient}`
      );
      setDatosInvitacion(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Error");
    }
  };

  const actualizarCeremonia = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionceremonium/${values.ceremoniaId}`, values);
      toast.dismiss();
      toast.success("Ceremonia actualizada correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar la ceremonia.");
    }
  };

  const actualizarColores = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Colores/${values.colorId}`, values);
      toast.dismiss();
      toast.success("Colores actualizados correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar los colores.");
    }
  };

  const actualizarContador = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccioncontador/${values.contadorId}`, values);
      toast.dismiss();
      toast.success("Contador actualizado correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar el contador.");
    }
  };

  const actualizarInstagram = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/InstagramPosts/${values.idInstagram}`, values);
      toast.dismiss();
      toast.success("Instagram actualizado correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar Instagram.");
    }
  };

  const actualizarInvitados = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccioninvitado/${values.invitadosId}`, values);
      toast.dismiss();
      toast.success("Invitados actualizados correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar los invitados.");
    }
  };

  const actualizarConfirmacion = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionconfirmacion/${values.confirmacionId}`, values);
      toast.dismiss();
      toast.success("Confirmación actualizada correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar la confirmación.");
    }
  };

  const actualizarHospedaje = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionhospedaje/${values.hospedajeId}`, values);
      toast.dismiss();
      toast.success("Hospedaje actualizado correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar el hospedaje.");
    }
  };

  const actualizarPadres = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionpadre/${values.padresId}`, values);
      toast.dismiss();
      toast.success("Padres actualizados correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar los padres.");
    }
  };

  const actualizarPortada = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionportadum/${values.portadaId}`, values);
      toast.dismiss();
      toast.success("Portada actualizada correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar la portada.");
    }
  };

  const actualizarVestimenta = async (values) => {
    toast.loading("Actualizando...");
    try {
      if (values.colores && typeof values.colores === 'object') {
        values.colores = JSON.stringify(values.colores);
      }
      await axios.put(`/api/Seccionvestimentum/${values.vestimentaId}`, values);
      toast.dismiss();
      toast.success("Vestimenta actualizada correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar la vestimenta.");
    }
  };

  const actualizarPrograma = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionprograma/${values.programaId}`, values);
      toast.dismiss();
      toast.success("Programa actualizado correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar el programa.");
    }
  };

  const actualizarRegalos = async (values) => {
    toast.loading("Actualizando...");
    try {
      await axios.put(`/api/Seccionregalo/${values.regalosId}`, values);
      toast.dismiss();
      toast.success("Regalos actualizados correctamente.", {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#4ade80',
          color: 'white',
        },
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar los regalos.");
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    validateToken();
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    validateAndFetchData();
  }, []);

  return (
    <div className="md:ml-64">
      <div className={`w-full py-2 text-center text-white font-medium ${dataEvento?.diseño === "2" ? 'bg-[#D4AF37]' : 'bg-gray-600'
        }`}>
        {dataEvento?.diseño === "2"
          ? "Diseño VIP"
          : "Diseño Clásico"
        }
      </div>

      <div className="p-4">
      {loading ? (
        <div>Cargando XV...</div>
      ) : (
          <div className="w-full p-6 mx-auto bg-white rounded-md shadow-md">
            <DetallesNavbar dataEvento={dataEvento} idClient={idClient} userName={userName} />
          <h2 className="text-3xl font-semibold mb-4">Detalles del evento</h2>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Iconos</h3>
            <p className="mb-4">
              Accede a nuestra galería de iconos para personalizar tu
              invitación:
            </p>
            <a
              href="/iconos"
              className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Ver Galería de Iconos
            </a>
          </div>

          <br />
          <br />
          <div id="colores-portada">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
              COLORES
            </h2>
            <ColoresEventoForm
                diseño={dataEvento?.diseño}
              initialValues={datosInvitacion?.colores}
              onSubmit={(values) => {
                console.log(values);
                actualizarColores(values);
              }}
            />
          </div>
          <br />
          <br />
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
              <PortadaXvForm
            initialValues={datosInvitacion?.portada}
            onSubmit={(values) => {
              console.log("Formulario de portada enviado:", values);
              actualizarPortada(values);
            }}
                diseño={dataEvento?.diseño}
                colores={datosInvitacion?.colores || {}}
                    />
                  </div>

          {dataEvento.enableContador ? (
            <div id="contador">
              <ContadorForm
                initialValues={datosInvitacion?.contador}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
                onSubmit={(values) => {
                  console.log("Formulario de contador enviado:", values);
                  actualizarContador(values);
                }}
              />
            </div>
          ) : null}

          <br />
          {dataEvento.enablePadres ? (
            <div id="padres">
              <PadresForm
                initialValues={datosInvitacion?.padres}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
                onSubmit={(values) => {
                  console.log("Formulario de padres y padrinos enviado:", values);
                  actualizarPadres(values);
                }}
              />
            </div>
          ) : null}

          <br />
          {dataEvento.enableCeremonia ? (
            <div id="ceremonia">
              <CeremoniaForm
                initialValues={datosInvitacion?.ceremonia}
                onSubmit={(values) => {
                  actualizarCeremonia(values);
                }}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
              />
            </div>
          ) : null}

          <div id="confirmacion">
            <ConfirmacionForm
              initialValues={datosInvitacion?.confirmacion}
              onSubmit={(values) => {
                console.log("Formulario de confirmación enviado:", values);
                actualizarConfirmacion(values);
              }}
            />
          </div>

          <br />
          {dataEvento.enableHospedaje ? (
              <div id="hospedaje">
                <HospedajeForm
                  initialValues={datosInvitacion?.hospedaje}
                  onSubmit={(values) => {
                    console.log("Formulario de hospedaje enviado:", values);
                    actualizarHospedaje(values);
                  }}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
                />
            </div>
          ) : null}

          <br />
          {dataEvento.enablePrograma ? (
              <div id="programa">
                <ProgramaXvForm
                  initialValues={datosInvitacion?.programa}
                  onSubmit={(values) => {
                    console.log("Formulario de programa enviado:", values);
                    actualizarPrograma(values);
                  }}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
                />
            </div>
          ) : null}

          <br />
          {dataEvento?.enableRegalos ? (
            <div id="regalos">
              <RegalosForm
                initialValues={datosInvitacion?.regalos}
                onSubmit={(values) => {
                  console.log("Formulario de Mesa de Regalos enviado:", values);
                  actualizarRegalos(values);
                }}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
              />
            </div>
          ) : null}

          <br />
          {dataEvento?.enableVestimenta ? (
            <div id="vestimenta">
              <VestimentaForm
                initialValues={datosInvitacion?.vestimenta}
                onSubmit={(values) => {
                  console.log("Formulario de Vestimenta enviado:", values);
                  actualizarVestimenta(values);
                }}
                  diseño={dataEvento?.diseño}
                  colores={datosInvitacion?.colores || {}}
              />
            </div>
          ) : null}

          <div id="invitados">
            <InvitadosForm
              initialValues={datosInvitacion?.invitados}
              onSubmit={(values) => {
                console.log("Formulario de Invitados enviado:", values);
                actualizarInvitados(values);
              }}
                diseño={dataEvento?.diseño}
                colores={datosInvitacion?.colores || {}}
            />
          </div>

          {dataEvento?.enableInsta ? (
            <div id="instagram">
              <InstagramForm dataInsta={datosInvitacion?.insta} actualizarInstagram={actualizarInstagram} />
            </div>
          ) : null}

          <div id="galeria">
            <GaleriaTable idEvento={idClient} />
          </div>
        </div>
      )}
      </div>
    </div>
  );
};
