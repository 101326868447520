'use client'

import { useState, useEffect } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { MdHotel } from 'react-icons/md'
import AOS from 'aos'
import 'aos/dist/aos.css'
import FormattedText from '../functions/FormattedText'

export const HospedajeBodaComponent2 = ({datosSeccionHospedaje, colores, isEnglish}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const fontFamilyStyle = {
    fontFamily: datosSeccionHospedaje.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: datosSeccionHospedaje.textColor || '#000000'
  };

  const backgroundStyle = {
    backgroundImage: `url(${datosSeccionHospedaje.imgFondo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  
  const hotelSuggestions = [
    {
      id: 1,
      image: datosSeccionHospedaje?.hotel1Titulo,
      location: datosSeccionHospedaje?.hotel1url
    },
    datosSeccionHospedaje?.disponibleDos ? {
      id: 2,
      image: datosSeccionHospedaje?.hotel2Titulo,
      location: datosSeccionHospedaje?.hotel2url
    } : null
  ].filter(hotel => hotel !== null); // Filtra valores nulos
  

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    })

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % hotelSuggestions.length)
    }, 5000)

    return () => clearInterval(timer)
  }, [])

  const goToLocation = () => {
    window.open(hotelSuggestions[currentIndex].location, '_blank')
  }

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + hotelSuggestions.length) % hotelSuggestions.length)
  }

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % hotelSuggestions.length)
  }

  return (
    <div className="min-h-screen w-full" style={{...backgroundStyle, backgroundColor: colores?.color1 || '#E5DDD3'}}>
      {/* Top section with title - Ahora sin padding superior */}
      <div 
        data-aos="fade-down"
        className="pb-16 rounded-b-[50%]"
        style={{backgroundColor: colores?.color2 || '#C2B6AA'}}
      >
        <h1 className="text-center font-serif text-3xl text-white pt-4 px-4" style={textStyle}>
          {isEnglish ? "Accommodation" : "Sugerencia De"}
          <br />
          {isEnglish ? "Suggestions" : "Hospedaje"}
        </h1>
      </div>

      <div className="w-full py-4">
        {/* Description text */}
        {datosSeccionHospedaje?.descripcion && (
          <div 
            data-aos="fade-up"
            className="text-center mx-4 mt-8 mb-4 text-[#8B8178]"
          >
            <p className="font-serif text-lg" style={textStyle}>
              <FormattedText text={datosSeccionHospedaje.descripcion} />
            </p>
          </div>
        )}

        {/* Image container with arch shape */}
        <div 
          data-aos="fade-up"
          data-aos-delay="200"
          className="relative mx-4 mt-8 rounded-t-[110px] bg-white p-4 shadow-lg"
        >
          <div className="overflow-hidden rounded-t-[100px] relative">
            {hotelSuggestions[currentIndex].image ? (
              <img
                src={hotelSuggestions[currentIndex].image}
                alt={`Hotel suggestion ${currentIndex + 1}`}
                className="h-[400px] w-full object-cover"
              />
            ) : (
              <div className="h-[400px] w-full bg-[#C2B6AA]/10 flex items-center justify-center">
                <MdHotel className="w-32 h-32 text-[#8B8178]" />
              </div>
            )}
            {/* Slider control buttons */}
            <button 
              onClick={goToPrevious}
              className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/50 p-2 rounded-full hover:bg-white/75 transition-colors"
            >
              <ChevronLeft className="w-6 h-6 text-[#8B8178]" />
            </button>
            <button 
              onClick={goToNext}
              className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/50 p-2 rounded-full hover:bg-white/75 transition-colors"
            >
              <ChevronRight className="w-6 h-6 text-[#8B8178]" />
            </button>
          </div>
          
          {/* Location button */}
          <button 
            data-aos="fade-up"
            data-aos-delay="400"
            onClick={goToLocation}
            className="w-full rounded-lg 0 py-3 mt-4 text-center font-serif text-[#8B8178] transition-colors "
            style={{...textStyle, backgroundColor: colores?.color1 || '#E5DDD3'}}
          >
            {isEnglish ? "Tap to see Location" : "Toca para ir a Ubicación"}
          </button>

          {/* Carousel dots */}
          <div 
            data-aos="fade-up"
            data-aos-delay="600"
            className="mt-4 flex justify-center gap-2"
          >
            {hotelSuggestions.map((_, index) => (
              <div 
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentIndex ? 'bg-[#C2B6AA]' : 'bg-[#C2B6AA]/40'
                }`}
              />
            ))}
          </div>
        </div>

        {/* Decorative circles */}
        <div className="relative mx-4">
          <div 
            data-aos="fade-right"
            data-aos-delay="800"
            className="absolute -left-6 top-1/2 h-3 w-3 rounded-full bg-[#C2B6AA]/40" 
          />
          <div 
            data-aos="fade-left"
            data-aos-delay="800"
            className="absolute -right-6 top-1/2 h-3 w-3 rounded-full bg-[#C2B6AA]/40" 
          />
        </div>
      </div>
    </div>
  )
}