'use client'

import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { FaChampagneGlasses } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";

export const UbicacionesBodaComponent2 = ({ datosSeccionCeremonia, colores, isEnglish, screenMobile }) => {
  const fontFamilyStyle = {
    fontFamily: datosSeccionCeremonia?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: datosSeccionCeremonia?.textColor || "#000000"
  };

  const renderFormattedText = (text) => {
    if (!text) return null;
    return text.split("\\n").map((linea, index) => (
      <React.Fragment key={index}>
        {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
          parte.startsWith("/") && parte.endsWith("/") ? (
            <strong key={subIndex}>{parte.slice(1, -1)}</strong>
          ) : (
            <span key={subIndex}>{parte}</span>
          )
        ))}
        <br />
      </React.Fragment>
    ));
  };

  const formatTime = (isoDateString) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    if (!isoDatePattern.test(isoDateString)) {
      return isoDateString;
    }

    const timePart = isoDateString.split('T')[1];
    const [hours, minutes] = timePart.split(':');

    const hoursNumber = parseInt(hours, 10);
    const period = hoursNumber >= 12 ? 'p.m.' : 'a.m.';
    const formattedHours = hoursNumber % 12 || 12;

    return `${formattedHours}:${minutes} ${period}`;
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    })
  }, [])

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionCeremonia?.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className="h-auto pb-8 bg-[#E5DDD3] px-4 py-8"
    >
      <h1
        data-aos={screenMobile ? '' : 'fade-down'}
        style={textStyle}
        className="text-3xl text-center mb-8"
      >
        {renderFormattedText(datosSeccionCeremonia?.titulo || (isEnglish ? 
          (datosSeccionCeremonia?.ceremoniaEnable && datosSeccionCeremonia?.recepcionEnable ? "Locations" : "Location") : 
          (datosSeccionCeremonia?.ceremoniaEnable && datosSeccionCeremonia?.recepcionEnable ? "Ubicaciones" : "Ubicación")))}
      </h1>

      <div className={`max-w-6xl px-4 mx-auto space-y-8 ${screenMobile ? 'flex flex-col' : 'lg:flex lg:space-x-8 lg:space-y-0'}`}>
        {datosSeccionCeremonia?.ceremoniaEnable && (
          <div
            data-aos={screenMobile ? '' : 'fade-right'}
            className={`${screenMobile ? '' : 'lg:flex-1'} space-y-4`}
          >
            <h2 className="text-3xl font-serif text-center" style={textStyle}>
              {datosSeccionCeremonia?.ceremoniaTitulo || (isEnglish ? "Ceremony" : "Ceremonia")}
            </h2>
            <div className="relative">
              <div className="overflow-hidden rounded-t-[50%]">
                {datosSeccionCeremonia?.ceremoniaImg ? (
                  <img
                    src={datosSeccionCeremonia.ceremoniaImg}
                    alt={isEnglish ? "Church" : "Iglesia"}
                    className="w-full h-52 object-cover"
                  />
                ) : (
                  <div className="w-full h-52 flex items-center justify-center"
                  style={{backgroundColor: colores?.color1 || '#f3f4f6'}}>
                    <PiChurchLight className="text-[60px]" style={{color: datosSeccionCeremonia?.textColor}} />
                  </div>
                )}
              </div>
              {datosSeccionCeremonia?.ceremoniaUrl && (
                <a
                  href={datosSeccionCeremonia.ceremoniaUrl}
                  style={{...fontFamilyStyle, backgroundColor: colores?.color2 || '#C2B6AA'}}
                  className="absolute inset-x-4 bottom-4 py-2 hover:bg-[#b5a99a] transition-colors rounded-lg text-center text-sm font-medium text-white"
                >
                  {isEnglish ? "View map" : "Ver Ubicación"}
                </a>
              )}
            </div>
            <div className="text-center space-y-2">
              <p className="text-xl" style={textStyle}>
                {formatTime(datosSeccionCeremonia?.ceremoniaHora)}
              </p>
              <p className="text-xl" style={textStyle}>
                {renderFormattedText(datosSeccionCeremonia?.ceremoniaNombreLugar)}
              </p>
            </div>
          </div>
        )}

        {datosSeccionCeremonia?.recepcionEnable && (
          <div
            data-aos={screenMobile ? '' : 'fade-left'}
            className={`${screenMobile ? '' : 'lg:flex-1'} space-y-4`}
          >
            <h2 className="text-3xl font-serif text-center" style={textStyle}>
              {datosSeccionCeremonia?.recepcionTitulo || (isEnglish ? "Reception" : "Recepción")}
            </h2>
            <div className="relative">
              <div className="overflow-hidden rounded-t-[50%]">
                {datosSeccionCeremonia?.recepcionImg ? (
                  <img
                    src={datosSeccionCeremonia.recepcionImg}
                    alt={isEnglish ? "Reception" : "Recepción"}
                    className="w-full h-52 object-cover"
                  />
                ) : (
                  <div className="w-full h-52 flex items-center justify-center"
                  style={{backgroundColor: colores?.color1 || '#f3f4f6'}}>
                    <FaChampagneGlasses className="text-[60px]" style={{color: datosSeccionCeremonia?.textColor}} />
                  </div>
                )}
              </div>
              {datosSeccionCeremonia?.recepcionUrl && (
                <a
                  href={datosSeccionCeremonia.recepcionUrl}
                  style={{...fontFamilyStyle, backgroundColor: colores?.color2 || '#C2B6AA'}}
                  className="absolute inset-x-4 bottom-4 py-2 bg-[#C2B6AA] hover:bg-[#b5a99a] transition-colors rounded-lg text-center text-sm font-medium text-white"
                >
                  {isEnglish ? "View map" : "Ver Ubicación"}
                </a>
              )}
            </div>
            <div className="text-center space-y-2">
              <p className="text-xl" style={textStyle}>
                {formatTime(datosSeccionCeremonia?.recepcionHora)}
              </p>
              <p className="text-xl" style={textStyle}>
                {renderFormattedText(datosSeccionCeremonia?.recepcionNombreLugar)}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
