import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUpload, FaTrash, FaImage } from 'react-icons/fa';

export const ImagenesAzure = () => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get('/api/ImagenesAzure/List');
      setImages(response.data);
    } catch (err) {
      setError('Error al cargar las imágenes');
      console.error('Error fetching images:', err);
    }
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    setUploading(true);
    setError(null);

    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post('/api/ImagenesAzure/Upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setImages(prev => [...prev, response.data.url]);
      }
    } catch (err) {
      setError('Error al subir las imágenes');
      console.error('Error uploading images:', err);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (imageUrl) => {
    try {
      const fileName = imageUrl.split('/').pop();
      await axios.delete(`/api/ImagenesAzure/Delete/${fileName}`);
      setImages(images.filter(img => img !== imageUrl));
      setImageToDelete(null);
    } catch (err) {
      setError('Error al eliminar la imagen');
      console.error('Error deleting image:', err);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
          <h1 className="text-2xl font-bold mb-6">Test de Imágenes Azure</h1>
          
          {/* Botón de subida */}
          <div className="mb-8">
            <input
              type="file"
              id="fileInput"
              multiple
              accept="image/*"
              className="hidden"
              onChange={handleFileSelect}
            />
            <label
              htmlFor="fileInput"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg cursor-pointer hover:bg-blue-700 transition-colors"
            >
              <FaUpload className="mr-2" />
              Subir Imágenes
            </label>
          </div>

          {/* Estado de carga */}
          {uploading && (
            <div className="mb-4 flex items-center text-blue-600">
              <div className="animate-spin mr-2">
                <FaImage />
              </div>
              Subiendo imágenes...
            </div>
          )}

          {/* Mensaje de error */}
          {error && (
            <div className="mb-4 text-red-500">
              {error}
            </div>
          )}

          {/* Grid de imágenes */}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {images.map((imageUrl, index) => (
              <motion.div
                key={imageUrl}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative group"
              >
                <img
                  src={imageUrl}
                  alt={`Imagen ${index + 1}`}
                  className="w-full h-48 object-cover rounded-lg"
                  onClick={() => setSelectedImage(imageUrl)}
                />
                <div className="absolute inset-0 bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-300 rounded-lg flex items-center justify-center">
                  <button
                    onClick={() => setImageToDelete(imageUrl)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-2 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <FaTrash />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal de imagen */}
      <AnimatePresence>
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
            onClick={() => setSelectedImage(null)}
          >
            <motion.img
              src={selectedImage}
              alt="Imagen ampliada"
              className="max-w-full max-h-[90vh] object-contain"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Modal de confirmación de eliminación */}
      <AnimatePresence>
        {imageToDelete && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            onClick={() => setImageToDelete(null)}
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="bg-white rounded-lg p-6 max-w-sm w-full"
              onClick={e => e.stopPropagation()}
            >
              <h3 className="text-xl font-semibold mb-4">Confirmar eliminación</h3>
              <p className="text-gray-600 mb-6">
                ¿Estás seguro de que deseas eliminar esta imagen? Esta acción no se puede deshacer.
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setImageToDelete(null)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => handleDelete(imageToDelete)}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                >
                  Eliminar
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};