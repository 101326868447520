import React from "react";

export const RegalosXvComponent = ({ peltaColores, datosSeccionRegalos, screenMobile }) => {
  // Definimos el estilo de la fuente
  const fontFamilyStyle = { fontFamily: datosSeccionRegalos.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionRegalos.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionRegalos.textColor || "",
        ...fontFamilyStyle,
      }}
      className="h-auto flex flex-col py-[5vh] px-3 items-center w-full"
    >
      <div className="flex flex-col sm:pt-[5vh] md:pt-[10vh] w-full justify-center items-center p-2">
        <h1
          className={`text-center ${screenMobile ? 'text-[32px]' : 'sm:text-[32px] md:text-[42px]'}`}
          data-aos={screenMobile ? '' : 'flip-up'}
          style={fontFamilyStyle}
        >
          {datosSeccionRegalos.titulo || "Mesa de regalos"}
        </h1>
        <br />
        <p
          className={`text-center pt-[5vh] text-xl ${screenMobile ? 'max-w-[90%]' : 'sm:max-w-[90%] md:max-w-[60%]'}`}
          data-aos={screenMobile ? '' : 'flip-up'}
          style={fontFamilyStyle}
        >
          {datosSeccionRegalos.descripcion
            .split("\\n")
            .map((linea, index) => (
              <React.Fragment key={index}>
                {linea}
                <br />
              </React.Fragment>
            ))}
        </p>

        <div
          className={`grid ${screenMobile ? 'grid-cols-1' : `sm:grid-cols-1 md:grid-cols-${
            datosSeccionRegalos.cantidadRegalos || 2
          }`} w-[80%] pt-[8vh]`}
        >
            <div
              style={{
                backgroundImage: `url(${datosSeccionRegalos.imgLink1 || "https://static.vecteezy.com/system/resources/previews/019/766/223/non_2x/amazon-logo-amazon-icon-transparent-free-png.png"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "110% 100%",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className="flex flex-col justify-end w-[100%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh]"
              data-aos={screenMobile ? '' : 'fade-right'}
            >
              {datosSeccionRegalos.link1Enable ? (
                <a
                  href={datosSeccionRegalos.link1}
                  className="overflow-hidden relative w-32 p-2 h-auto bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                >
                  {datosSeccionRegalos.ingles? "View Registry":"Ver mesa!"}
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              ):""}
            </div>

          {(datosSeccionRegalos.cantidadRegalos == 2 ||
            datosSeccionRegalos.cantidadRegalos == 3) ? (
            <div
              style={{
                backgroundImage: `url(${datosSeccionRegalos.imgLink2 || "https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "110% 100%",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className={`flex flex-col justify-end w-[100%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] ${screenMobile ? 'mt-4' : 'sm:mt-4 md:mt-0'}`}
              data-aos={screenMobile ? '' : 'fade-right'}
            >
              {datosSeccionRegalos.link2Enable ? (
                <a
                  href={datosSeccionRegalos.link2}
                  className="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                >
                  {datosSeccionRegalos.ingles? "View Registry":"Ver mesa!"}
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              ):""}
            </div>
          ):""}

          {datosSeccionRegalos.cantidadRegalos == 3 ? (
            <div
              style={{
                backgroundImage: `url(${datosSeccionRegalos.imgLink3 || "https://laeconomia.com.mx/wp-content/uploads/liverpool-face.png"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "110% 100%",
                display: "flex",
                backgroundPosition: "center center",
                backgroundColor: peltaColores.color1,
              }}
              className={`flex flex-col justify-end w-[100%] rounded-2xl items-center bg-slate-100 drop-shadow-2xl h-[40vh] ${screenMobile ? 'mt-4' : 'sm:mt-4 md:mt-0'}`}
              data-aos={screenMobile ? '' : 'fade-right'}
            >
              {datosSeccionRegalos.link3Enable ? (
                <a
                  href={datosSeccionRegalos.link3}
                  className="overflow-hidden relative w-32 p-2 h-12 bg-black text-white text-center border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                >
                  {datosSeccionRegalos.ingles? "View Registry":"Ver mesa!"}
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                  <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                  <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                    Explore!
                  </span>
                </a>
              ):""}
            </div>
          ):""}
        </div>
      </div>
    </div>
  );
};
