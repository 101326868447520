import React, { useState, useRef, useEffect } from "react";
import { useField } from "formik";
import { Label } from "reactstrap";
import { backgrounds, categories } from './backgroundsData';

export const ImageFondo = ({ label, ingles, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [isOpen, setIsOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('todos');
  const dropdownRef = useRef(null);

  // Función para obtener el título de la categoría según el idioma
  const getCategoryTitle = (category) => {
    return ingles ? category.title.en : category.title.es;
  };

  // Encontrar la opción seleccionada actual
  const selectedBackground = backgrounds.find(bg => bg.value === field.value);

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <Label>{label}</Label>
      
      {/* Botón Select */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer border rounded-lg p-2 flex items-center gap-4 hover:bg-gray-50"
      >
        {field.value ? (
          <div className="h-[16vh] w-[9vh] relative overflow-hidden rounded-md">
            <img
              src={field.value}
              alt="Background preview"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        ) : (
          <div className="h-16 w-9 bg-gray-200 rounded-md flex items-center justify-center">
            <span className="text-gray-500">{ingles ? "No background" : "Sin fondo"}</span>
          </div>
        )}
        <svg 
          className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`} 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50">
          <div className="min-h-screen p-2 sm:p-4 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl max-h-[90vh] flex flex-col">
              {/* Header */}
              <div className="px-4 sm:px-6 py-4 flex items-center justify-between border-b flex-shrink-0">
                <h3 className="text-lg sm:text-xl font-medium text-gray-900">
                  {ingles ? "Select a background" : "Selecciona un fondo"}
                </h3>
                <button 
                  onClick={() => setIsOpen(false)} 
                  className="text-gray-400 hover:text-gray-500 transition-colors"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Menú de categorías */}
              <div className="border-b flex-shrink-0">
                <nav className="flex overflow-x-auto py-2 px-4 sm:px-6 space-x-2 sm:space-x-4 hide-scrollbar">
                  {Object.entries(categories).map(([key, category]) => (
                    <button
                      key={key}
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveCategory(key);
                      }}
                      className={`
                        px-4 py-2 text-sm font-medium rounded-md whitespace-nowrap
                        transition-colors duration-200
                        ${activeCategory === key 
                          ? 'bg-gray-100 text-gray-900' 
                          : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                        }
                      `}
                    >
                      {getCategoryTitle(category)}
                    </button>
                  ))}
                </nav>
              </div>

              {/* Grid de fondos */}
              <div className="flex-grow overflow-y-auto p-4 sm:p-6">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 sm:gap-4">
                  {backgrounds
                    .filter(bg => activeCategory === 'todos' || bg.categories.includes(activeCategory))
                    .map((background, index) => (
                      <div 
                        key={index}
                        className="cursor-pointer group relative"
                        onClick={() => {
                          setValue(background.value);
                          setIsOpen(false);
                        }}
                      >
                        <div className="aspect-[9/16] rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                          {background.value ? (
                            <img
                              src={background.value}
                              alt="Background option"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full bg-gray-50 flex items-center justify-center">
                              <span className="text-gray-400 text-sm">
                                {ingles ? "No background" : "Sin fondo"}
                              </span>
                            </div>
                          )}
                          {field.value === background.value && (
                            <div className="absolute inset-0 bg-black bg-opacity-10 backdrop-blur-[1px] flex items-center justify-center">
                              <div className="rounded-full p-1.5 bg-blue-500/40 backdrop-blur-sm">
                                <svg 
                                  className="w-4 h-4 text-white" 
                                  fill="none" 
                                  stroke="currentColor" 
                                  viewBox="0 0 24 24"
                                >
                                  <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2.5} 
                                    d="M5 13l4 4L19 7" 
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                        <p className="text-xs text-gray-500">{background.value}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>

      {meta.touched && meta.error ? (
        <div className="text-red-500 text-sm mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};
