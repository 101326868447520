import React, { useState, useEffect } from 'react';
import imgVip from "../../assets/sellovip.png";
import imgrsvp from "../../assets/sellorsvp.png";
import { FaHandPointDown } from "react-icons/fa";

export default function Modal2Xv({ isOpen, setIsOpen, ingles }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnvelopeOpen, setIsEnvelopeOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    }
  }, [isOpen]);

  const openEnvelope = () => {
    setIsEnvelopeOpen(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsEnvelopeOpen(false);
      setIsVisible(false);
    }, 2000);
  };

  if (!isVisible && !isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-700 ${
        isOpen ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="relative w-full h-full bg-[#f5f3e9] transition-all duration-700">
        {/* Left flap */}
        <div
          className={`absolute left-0 top-0 w-1/2 h-full bg-[#f5f3e9] transform origin-left shadow-xl z-10 transition-transform duration-700 ${
            isEnvelopeOpen ? '-translate-x-full' : 'translate-x-0'
          }`}
        ></div>
        {/* Right flap */}
        <div
          className={`absolute right-0 top-0 w-1/2 h-full bg-[#f5f3e9] transform origin-right shadow-2xl z-10 transition-transform duration-700 ${
            isEnvelopeOpen ? 'translate-x-full' : 'translate-x-0'
          }`}
        ></div>
        {/* Wax seal */}
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 z-20 cursor-pointer"
          onClick={openEnvelope}
        >
          <img 
            src={window.location.hostname === 'yourinvitation.vip' ? imgVip : imgrsvp} 
            alt="Toca Aquí" 
            className="w-full animate-pulse h-full transition-transform duration-300 hover:scale-110"
          />
          <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-white text-gray-800 px-3 py-1 rounded-full shadow-md text-sm whitespace-nowrap flex items-center gap-1">
            Click <FaHandPointDown className="text-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}
