import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { HospedajeBodaComponent2 } from '../../../components/ComponentsBoda2/HospedajeBodaComponent2';
import { HospedajeXvComponent } from '../../../components/ComponentsXv/HospedajeXvComponent';
import { PreviewModal } from '../../../components/PreviewModal';

export const HospedajeForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Detalles del Hospedaje</h3>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                {/* Sección Principal */}
                <div className="bg-blue-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-blue-900 mb-4">Información Principal</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                        Título:
                      </label>
                      <Input
                        type="text"
                        id="titulo"
                        name="titulo"
                        value={values?.titulo}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-1">
                        Descripción:
                      </label>
                      <Input
                        type="text"
                        id="descripcion"
                        name="descripcion"
                        value={values?.descripcion}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="disponibleDos" className="flex items-center h-full">
                        <Input
                          type="checkbox"
                          id="disponibleDos"
                          name="disponibleDos"
                          checked={values?.disponibleDos}
                          onChange={handleCustomChange(handleChange, values)}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <span className="ml-2 text-sm text-gray-700">Disponible Dos</span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* Hotel 1 */}
                <div className="bg-green-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-green-900 mb-4">Hotel 1</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="hotel1Titulo" className="block text-sm font-medium text-gray-700 mb-1">
                        {diseño === "2" ? "Url Imagen Hotel 1:" : "Título del Hotel 1:"}
                      </label>
                      <Input
                        type="text"
                        id="hotel1Titulo"
                        name="hotel1Titulo"
                        value={values?.hotel1Titulo}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="hotel1lugar" className="block text-sm font-medium text-gray-700 mb-1">
                        Lugar del Hotel 1:
                      </label>
                      <Input
                        type="text"
                        id="hotel1lugar"
                        name="hotel1lugar"
                        value={values?.hotel1lugar}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="hotel1url" className="block text-sm font-medium text-gray-700 mb-1">
                        URL del Hotel 1:
                      </label>
                      <Input
                        type="text"
                        id="hotel1url"
                        name="hotel1url"
                        value={values?.hotel1url}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                  </div>
                </div>

                {/* Hotel 2 */}
                <div className="bg-yellow-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-yellow-900 mb-4">Hotel 2</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="hotel2Titulo" className="block text-sm font-medium text-gray-700 mb-1">
                        {diseño === "2" ? "Url Imagen Hotel 2:" : "Título del Hotel 2:"}
                      </label>
                      <Input
                        type="text"
                        id="hotel2Titulo"
                        name="hotel2Titulo"
                        value={values?.hotel2Titulo}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="hotel2lugar" className="block text-sm font-medium text-gray-700 mb-1">
                        Lugar del Hotel 2:
                      </label>
                      <Input
                        type="text"
                        id="hotel2lugar"
                        name="hotel2lugar"
                        value={values?.hotel2lugar}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="hotel2url" className="block text-sm font-medium text-gray-700 mb-1">
                        URL del Hotel 2:
                      </label>
                      <Input
                        type="text"
                        id="hotel2url"
                        name="hotel2url"
                        value={values?.hotel2url}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                  </div>
                </div>

                {/* Configuración de Estilo */}
                <div className="bg-purple-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-purple-900 mb-4">Configuración de Estilo</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                        Color del Texto:
                      </label>
                      <Input
                        type="color"
                        id="textColor"
                        name="textColor"
                        value={values?.textColor || "#000000"}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full h-10 p-1 rounded-md border-gray-300"
                      />
                    </div>
                    <div>
                      <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                    </div>
                    <div className="col-span-1 md:col-span-3">
                      <ImageFondo
                        label="Seleccione un fondo"
                        name="imgFondo"
                        onChange={handleCustomChange(handleChange, values)}
                        value={values?.imgFondo}
                        className="mt-1 w-full"
                      />
                      <Input
                        type="text"
                        id="imgFondo"
                        name="imgFondo"
                        value={values?.imgFondo}
                        onChange={handleCustomChange(handleChange, values)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentValues(values);
                      setRefreshKey(prev => prev + 1);
                      if (isMobile) {
                        setShowMobilePreview(true);
                      }
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Actualizar Hospedaje
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {!isMobile && <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <HospedajeBodaComponent2
                  key={refreshKey}
                  datosSeccionHospedaje={currentValues}
                  colores={colores}
                  isEnglish={false}
                  screenMobile={true}
                />
              ) : (
                <HospedajeXvComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionHospedaje={currentValues}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>}
          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {currentValues && Object.keys(currentValues).length > 0 && (
                diseño === "2" ? (
                  <HospedajeBodaComponent2
                    key={refreshKey}
                    datosSeccionHospedaje={currentValues}
                    colores={colores}
                    isEnglish={false}
                    screenMobile={true}
                  />
                ) : (
                  <HospedajeXvComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionHospedaje={currentValues}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
}; 