import React, { useState } from 'react';
import './Cartas.css';

export const Cartas = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    {
      text: "hola mi niña\n\nte hice este librito para que tengas presente lo mucho que te quiero, eres esa persona que alegra mis días, que los llena de luz y felicidad me siento tan afortunado de tenerte.\n\ngracias por llegar a mi vida ❤️",
      background: "#1a1a1a"
    },
    {
      title: "Tú y yo ❤️",
      image: "couple",
      background: "#fff5f5"
    },
    {
      title: "Contigo",
      subtitle: "me siento en las",
      image: "clouds",
      background: "#ffe4e4"
    }
  ];

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="book-wrapper">
      <div className="book-container">
        <div className="pages" style={{ transform: `translateX(-${currentPage * 100}%)` }}>
          {pages.map((page, index) => (
            <div 
              key={index} 
              className="page"
              style={{ background: page.background }}
            >
              {page.image === 'couple' ? (
                <div className="couple-page">
                  <h1>{page.title}</h1>
                  <div className="couple-icons">
                    <div className="icon-blue"></div>
                    <div className="icon-pink"></div>
                  </div>
                </div>
              ) : page.image === 'clouds' ? (
                <div className="clouds-page">
                  <h1>{page.title}</h1>
                  <h2>{page.subtitle}</h2>
                  <div className="pink-clouds">
                    <div className="stick-figures"></div>
                  </div>
                </div>
              ) : (
                <div className="text-page">
                  {page.text.split('\n').map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      <div className="navigation">
        <button 
          className="nav-button"
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 0}
        >
          ←
        </button>
        <button 
          className="nav-button"
          onClick={() => handlePageChange('next')}
          disabled={currentPage === pages.length - 1}
        >
          →
        </button>
      </div>
    </div>
  );
};