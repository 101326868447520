import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { SelectIcons } from '../../../components/functions/SelectIcons';
import { ContadorBodaComponent2 } from '../../../components/ComponentsBoda2/ContadorBodaComponent2';
import { ContadorXvComponent } from '../../../components/ComponentsXv/ContadorXvComponent';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { PreviewModal } from '../../../components/PreviewModal';

export const ContadorForm = ({ initialValues, onSubmit, colores, diseño }) => {
  // Función para parsear el string de estilos a objeto
  const parseEstilos = (estilosString) => {
    try {
      return JSON.parse(estilosString || '{"imagenArriba":"","imagenAbajo":""}');
    } catch {
      return { imagenArriba: "", imagenAbajo: "" };
    }
  };

  const [datosSeccionContador, setDatosSeccionContador] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [currentValues, setCurrentValues] = useState(null);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Función para convertir el objeto de estilos a string
  const stringifyEstilos = (estilosObj) => {
    return JSON.stringify(estilosObj);
  };

  // Manejador personalizado para actualizar tanto Formik como datosSeccionContador
  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
  };

  // Manejador especial para SelectTipoLetra
  const handleTipoLetraChange = (handleChange, values) => (e) => {
    const newValue = e.target.value;
    
    // Crear el objeto actualizado con el nuevo valor
    const updatedValues = {
      ...values,
      tipoLetra: newValue
    };
    
    // Actualizar datosSeccionContador inmediatamente
    setDatosSeccionContador({
      ...updatedValues,
      estilos: stringifyEstilos(updatedValues?.estilosObj),
    });

    setRefreshKey(prev => prev + 1);
    handleChange(e);
  };

  // Manejador para el botón de preview
  const handlePreviewClick = (values) => {
    setDatosSeccionContador({
      fechaDeBoda: values?.fechaDeBoda || "",
      titulo: values?.titulo || "",
      descipcion: values?.descripcion || "",
      fechaNewDate: values?.fechaNewDate || "",
      fotoIzquierda: values?.fotoIzquierda || "",
      fotoDerecha: values?.fotoDerecha || "",
      marcoEnable: values?.marcoEnable,
      imgFondo: values?.imgFondo || "",
      textColor: values?.textColor || "",
      contador: values?.contador,
      tipoLetra: values?.tipoLetra,
      estilos: stringifyEstilos(values?.estilosObj),
    });
    setRefreshKey(prev => prev + 1);
  };

  useEffect(() => {
    console.log(datosSeccionContador);
    setDatosSeccionContador(
      {
        fechaDeBoda: initialValues?.fechaDeBoda || "",
        titulo: initialValues?.titulo || "",
        descipcion: initialValues?.descripcion || "",
        fechaNewDate: initialValues?.fechaNewDate || "",
        fotoIzquierda: initialValues?.fotoIzquierda || "",
        fotoDerecha: initialValues?.fotoDerecha || "",
        marcoEnable: initialValues?.marcoEnable,
        imgFondo: initialValues?.imgFondo || "",
        textColor: initialValues?.textColor || "",
        contador: initialValues?.contador,
        tipoLetra: initialValues?.tipoLetra,
      }
    );
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Contador</h3>
        <div className='grid grid-cols-[1.5fr,1fr] gap-6'>
          <Formik
            initialValues={{
              ...initialValues,
              estilosObj: parseEstilos(initialValues?.estilos)
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Actualizar el preview
              handlePreviewClick(values);
              
              // Continuar con el guardado normal
              const newValues = {
                ...values,
                estilos: stringifyEstilos(values?.estilosObj)
              };
              delete newValues?.estilosObj;
              onSubmit(newValues);
              setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="contador" className="block text-sm font-medium text-gray-700 mb-1">
                        Contador:
                      </label>
                      <Input
                        type="checkbox"
                        id="contador"
                        name="contador"
                        checked={values?.contador}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-1">
                        Descripción:
                      </label>
                      <textarea
                        id="descripcion"
                        name="descripcion"
                        value={values?.descripcion}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label htmlFor="fechaNewDate" className="block text-sm font-medium text-gray-700 mb-1">
                        Fecha:
                      </label>
                      <Input
                        type="text"
                        id="fechaNewDate"
                        name="fechaNewDate"
                        value={values?.fechaNewDate}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label htmlFor="marcoEnable" className="block text-sm font-medium text-gray-700 mb-1">
                        Marco Habilitado:
                      </label>
                      <Input
                        type="checkbox"
                        id="marcoEnable"
                        name="marcoEnable"
                        checked={values?.marcoEnable}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                   
                    <div>
                     {/* <label htmlFor="fotoIzquierda" className="block text-sm font-medium text-gray-700 mb-1">
                        Foto Arriba de Contador (Izquierda):
                      </label>
                      <Input
                        type="text"
                        id="fotoIzquierda"
                        name="fotoIzquierda"
                        value={values?.fotoIzquierda}
                        onChange={handleChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />*/}
                      <SelectIcons
                                label="Selecciona un icono para la foto Arriba"
                                name="fotoIzquierda"
                                onChange={handleCustomChange(handleChange, values)}
                                value={values?.fotoIzquierda}
                                className={`w-full border-gray-300`}
                              />
                              <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="imagenArriba"
                          checked={values.estilosObj.imagenArriba === "contain"}
                          onChange={(e) => {
                            setFieldValue("estilosObj", {
                              ...values.estilosObj,
                              imagenArriba: e.target.checked ? "contain" : ""
                            });
                          }}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="imagenArriba" className="text-sm text-gray-700">
                          Mostrar toda la imagen arriba
                        </label>
                      </div>
                    </div>
                    <div className=''>
                      {/*<div>
                      <label htmlFor="fotoDerecha" className="block text-sm font-medium text-gray-700 mb-1">
                        Foto Abajo de Contador (Derecha):
                      </label>
                      <Input
                        type="text"
                        id="fotoDerecha"
                        name="fotoDerecha"
                        value={values?.fotoDerecha}
                        onChange={handleChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      </div>*/}
                     <SelectIcons
                                label="Selecciona un icono para la foto Abajo"
                                name="fotoDerecha"
                                onChange={handleCustomChange(handleChange, values)}
                                value={values?.fotoDerecha}
                                className={`w-full border-gray-300`}
                              />
                              
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="imagenAbajo"
                          checked={values.estilosObj.imagenAbajo === "contain"}
                          onChange={(e) => {
                            setFieldValue("estilosObj", {
                              ...values.estilosObj,
                              imagenAbajo: e.target.checked ? "contain" : ""
                            });
                          }}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="imagenAbajo" className="text-sm text-gray-700">
                          Mostrar toda la imagen abajo
                        </label>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                        Título:
                      </label>
                      <Input
                        type="text"
                        id="titulo"
                        name="titulo"
                        value={values?.titulo}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                        Color de Texto:
                      </label>
                      <Input
                        type="color"
                        id="textColor"
                        name="textColor"
                        value={values?.textColor}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 block w-full h-10"
                      />
                    </div>
                    <div>
                      <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                        Imagen de fondo:
                      </label>
                      <Input
                        type="text"
                        id="imgFondo"
                        name="imgFondo"
                        value={values?.imgFondo}
                        onChange={handleCustomChange(handleChange, values)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                        Seleccionar imagen de fondo:
                      </label>
                      <ImageFondo
                        label="Seleccione un fondo"
                        name="imgFondo"
                        onChange={handleCustomChange(handleChange, values)}
                        value={values?.imgFondo}
                        className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                          values?.imgFondo ? "input-error" : ""
                        }`}
                      />
                    </div>
                    <div>
                      <SelectTipoLetra 
                        values={values} 
                        handleChange={handleTipoLetraChange(handleChange, values)} 
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-4 mt-6">
                    <button
                      type="button"
                      onClick={() => {
                        setCurrentValues(values);
                        setRefreshKey(prev => prev + 1);
                        if (isMobile) {
                          setShowMobilePreview(true);
                        }
                      }}
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                    >
                      Ver Preview
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                    >
                      Guardar Contador
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {!isMobile && <PreviewContainer>
            {
              diseño || "" === "2" ? (
                <ContadorBodaComponent2
                  key={refreshKey}
                  contadorData={datosSeccionContador}
                  peltaColores={colores || {}}
                  onSubmit={onSubmit}
                  miniScreen={true}
                  screenMobile={true}
                />
              ) : (
                <ContadorXvComponent 
                  key={refreshKey}
                  peltaColores={colores || {}}
                  datosSeccionContador={datosSeccionContador}
                  miniScreen={true}
                  screenMobile={true}
                />
              )
            }
          </PreviewContainer>}
          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {currentValues && Object.keys(currentValues).length > 0 && (
                diseño === "2" ? (
                  <ContadorBodaComponent2
                    key={refreshKey}
                    contadorData={currentValues}
                    peltaColores={colores}
                    onSubmit={onSubmit}
                    miniScreen={true}
                    screenMobile={true}
                  />
                ) : (
                  <ContadorXvComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionContador={currentValues}
                    miniScreen={true}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
};  