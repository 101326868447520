import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaArrowDown, FaStar, FaGem, FaMedal, FaCrown, FaHandPointDown } from "react-icons/fa";
import videoProceso from "../../../assets/proceso-compra.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import videoExplicacion from "./videos/explicacion.mp4";

const packageDetails = {
  "PAQUETE PLATA": {
    features: [
      "Conteo regresivo al evento",
      "Detalles de la ceremonia y fiesta",
      "Ubicación en Google Maps",
      "Información de padres y padrinos",
      "Música",
      "5 fotos en la invitación",
      "Confirmación de asistencia",
      "App móvil para compartir tus invitaciones (iOS y Android)",
      "Plantilla de colores personalizable",
      "Confirmación vía WhatsApp",
      "Detalles completos del evento",
      "Cuenta regresiva interactiva",
      "Ubicación del evento y ceremonia en Maps",
      "Galería con 4 fotografías",
      "Música de fondo personalizable"
    ],
    price: "295",
    savings: "305"
  },
  "PAQUETE ORO": {
    features: [
      "Todo lo incluido en el plan PLATA",
      "3 fotos extra en la invitación (8 en total)",
      "Código de vestimenta interactivo",
      "Itinerario detallado",
      "Mesa de regalos (Link de Liverpool o Amazon)",
      "Selección de tipo de letra",
      "Sección de hospedaje para invitados",
      "Código de vestimenta interactivo",
      "Sección especial para padres y padrinos",
      "Galería con 6 fotografías",
      "Diseño premium personalizado"
    ],
    price: "595",
    savings: "605"
  },
  "PAQUETE DIAMANTE": {
    features: [
      "Todo lo incluido en el plan ORO",
      "2 fotos adicionales (10 en total)",
      "Pases personalizados con nombre y acceso",
      "Personalización avanzada de invitaciones",
      "Confirmación directa a los anfitriones",
      "Sección de hospedaje para invitados",
      "Código de vestimenta interactivo",
      "Sección especial para padres y padrinos",
      "Integración con mesa de regalos",
      "Galería extendida con 8 fotografías"
    ],
    price: "895",
    savings: "1605"
  },
  "PAQUETE PREMIUM": {
    features: [
      "Todo lo incluido en el plan DIAMANTE",
      "Diseño especial con hashtag",
      "Gestión de confirmaciones y pases",
      "Mensajes de invitados",
      "Soporte prioritario 24/7",
      "Sección de Instagram con hashtag personalizado",
      "Mensajes personalizados para cada invitado",
      "Control de acceso individual",
      "Panel de administración de invitados",
      "Estadísticas de visualización",
      "Notificaciones en tiempo real",
      "QR de confirmación de invitación",
      "Control de asistencia por medio de QR"
    ],
    price: "995",
    savings: "2205"
  }
};

const packages = [

  {
    name: "PAQUETE PREMIUM",
    icon: <FaCrown className="text-4xl text-purple-500" />,
    video: videoExplicacion,
    images: [
      "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
      "https://digitalrsvp.mx/api/Images/e2941d1e-415a-49d5-a637-f61772ce2da2.png",
      "https://i.postimg.cc/BbFjyFfF/Beauty-Flowers-Watercolor-Background-Instagram-Story.png"
    ],
    designs: [
      {
        preciceDec: 2995,
        precice: 995,
        discount: "67% OFF",
        name: "Diseño Vip con Fotos",
        link: "/2/XV-auto-diamante?idInvitado=134992",
      }
    ],
  },
  {
    name: "PAQUETE DIAMANTE",
    icon: <FaGem className="text-4xl text-blue-500" />,
    images: ["https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg"],
    designs: [
      {
        preciceDec: "2500",
        precice: 895,
        discount: "64% OFF",
        name: "Diseño Clasico con Fotos",
        link: "/evento-diamante-xv?name=Angel%20Jaimes",
        name2: "Ejemplos sin Fotos",
        link2: "/evento-diamante-xv-foto?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "PAQUETE ORO",
    icon: <FaStar className="text-4xl text-yellow-500" />,
    images: ["https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg"],
    designs: [
      {
        preciceDec: "1200",
        precice: 595,
        discount: "50% OFF",
        name: "Diseño Vip con Fotos",
        link: "/2/evento-auto?id=7837"
      },
      {
        preciceDec: "1200",
        precice: 595,
        discount: "50% OFF",
        name: "Diseño Clasico con Fotos",
        link: "/evento-oro-xv",
        name2: "Ejemplos sin Fotos",
        link2: "/evento-oro-xv-fotos",
      },
    ],
  },
  {
    name: "PAQUETE PLATA",
    icon: <FaMedal className="text-4xl text-gray-400" />,
    images: ["https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg"],
    designs: [

      {
        preciceDec: "600",
        precice: 295,
        discount: "51% OFF",
        name: "Ejemplo Diseño Vip",
        link: "/2/evento-auto?id=7836"
      },
      {
        preciceDec: "600",
        precice: 295,
        discount: "51% OFF",
        name: "Diseño Clasico con Fotos",
        link: "/evento-plata-xv",
        name2: "Ejemplo sin Fotos",
        link2: "/evento-plata-xv-fotos",
      }
    ],
  },
];

const sliderImages = [
  "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
  "https://i.postimg.cc/BbFjyFfF/Beauty-Flowers-Watercolor-Background-Instagram-Story.png",
  "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
  "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
];

export const EjemplosXv = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [typedText, setTypedText] = useState("");
  const [showHand, setShowHand] = useState(true);
  const [selectedFeatures, setSelectedFeatures] = useState(null);
  const fullText = "Invitaciones de Quinceañera";

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16738412224";
    script.async = true;
    document.head.appendChild(script);

    const scriptText = document.createElement('script');
    scriptText.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16738412224');
    `;
    document.head.appendChild(scriptText);

    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < fullText.length) {
        setTypedText(fullText.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptText);
      clearInterval(typingInterval);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHand(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const packageSliderSettings = {
    ...sliderSettings,
    arrows: true,
    dots: false,
  };

  const StarBackground = () => (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {[...Array(50)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 bg-white"
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            scale: Math.random() * 0.5 + 0.5,
          }}
          animate={{
            scale: [1, Math.random() * 1.5 + 0.8, 1],
            opacity: [0.3, 0.8, 0.3],
          }}
          transition={{
            duration: Math.random() * 3 + 2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
            delay: Math.random() * 2,
          }}
          style={{
            boxShadow: `0 0 ${Math.random() * 6 + 2}px rgba(236, 72, 153, 0.8)`,
          }}
        />
      ))}
    </div>
  );

  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-pink-200 via-pink-100 to-pink-200">
      <StarBackground />

      <AnimatePresence>
        {showHand && (
          <motion.div
            className="fixed bottom-8 right-8 z-50 text-yellow-500"
            initial={{ opacity: 0, y: -20 }}
            animate={{
              opacity: [0.5, 1, 0.5],
              y: [-20, 20, -20],
            }}
            exit={{
              opacity: 0,
              y: 20,
              transition: { duration: 0.5 }
            }}
            transition={{
              duration: 2,
              repeat: 2,
              repeatType: "reverse",
            }}
          >
            <div className="bg-white/10 p-3 rounded-full shadow-lg backdrop-blur-sm">
              <FaHandPointDown className="w-8 h-8" />
              <motion.div
                className="absolute -bottom-2 left-1/2 w-1 h-12 bg-pink-300/50 rounded-full blur-sm"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: [0, 1, 0],
                  y: [-30, 30, -30]
                }}
                transition={{
                  duration: 2,
                  repeat: 2,
                  repeatType: "reverse",
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Header Section with Slider */}
      <div className="w-full h-[50vh] relative overflow-hidden">
        {/* Slider Component */}
        <Slider {...sliderSettings}>
          {sliderImages.map((image, index) => (
            <div key={index} className="w-full h-[50vh]">
              <img
                src={image}
                alt={`Wedding Celebration ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </Slider>
        {/* Overlay with Title */}
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-4xl md:text-6xl font-serif text-white text-center mb-4"
          >
            {typedText}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-xl md:text-2xl text-white text-center"
          >
            Descubre nuestros paquetes exclusivos
          </motion.p>
        </div>
      </div>
      {/* Packages Section */}
      <motion.div
        className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 mt-16 max-w-7xl mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {packages.map((pkg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.7,
              delay: index * 0.2,
              ease: "easeOut"
            }}
            whileHover={{ scale: 1.03, translateY: -5 }}
            className={`bg-[#ffffff] p-6 rounded-2xl transform relative shadow-lg
              ${pkg.name === "PAQUETE PREMIUM" ?
                "border-[3px] border-purple-500 scale-105 shadow-[0_0_40px_rgba(168,85,247,0.2)] z-10" :
                pkg.name === "PAQUETE DIAMANTE" ?
                  "border-[2px] border-[#7FE6FF]" :
                  pkg.name === "PAQUETE ORO" ?
                    "border-[2px] border-[#FFD700]" :
                    "border-[2px] border-[#B0B0B0]"
              }`}
          >
            {/* Package Title and Icon */}
            <div className="relative pb-2 mb-3">
              <div className="flex items-center justify-center mb-2">
                {pkg.icon}
              </div>
              <h2 className="text-lg font-['Playfair Display'] font-bold text-center tracking-wider">
                {pkg.name}
              </h2>
              <div className={`absolute bottom-0 left-0 right-0 h-[1px] ${pkg.name === "PAQUETE PREMIUM" ? "bg-purple-500" :
                  pkg.name === "PAQUETE DIAMANTE" ? "bg-[#7FE6FF]" :
                    pkg.name === "PAQUETE ORO" ? "bg-[#FFD700]" :
                      "bg-[#B0B0B0]"
                }`}></div>
            </div>
            {/* Package Images Slider */}
            <div className="h-64 mb-4">
              {pkg.video ? (
                <video
                  src={pkg.video}
                  className="w-full h-64 object-contain rounded-lg"
                  controls
                  autoPlay
                  loop
                  muted
                />
              ) : (
                <Slider {...packageSliderSettings}>
                  {pkg.images && pkg.images.length > 0 ? (
                    pkg.images.map((image, idx) => (
                      <div key={idx}>
                        <img
                          src={image}
                          alt={`${pkg.name} slide ${idx + 1}`}
                          className="w-full h-64 object-cover rounded-lg"
                        />
                      </div>
                    ))
                  ) : (
                    <div>No hay imágenes disponibles</div>
                  )}
                </Slider>
              )}
            </div>
            {/* Botón y modal de características */}
            <div className="mb-4 relative" style={{ perspective: "1000px" }}>
              <AnimatePresence mode="wait">
                {selectedFeatures === pkg.name ? (
                  <motion.div
                    key="back"
                    initial={{ rotateY: 180, opacity: 0 }}
                    animate={{ rotateY: 0, opacity: 1 }}
                    exit={{ rotateY: -180, opacity: 0 }}
                    transition={{ duration: 0.6 }}
                    style={{ transformStyle: "preserve-3d" }}
                    className="bg-white rounded-xl shadow-xl p-6"
                  >
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-semibold text-gray-800">
                        ✨ Características del {pkg.name}
                      </h3>
                      <motion.button
                        onClick={() => setSelectedFeatures(null)}
                        className="text-gray-500 hover:text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-full p-2"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                      </motion.button>
                    </div>

                    <div className="space-y-3">
                      {packageDetails[pkg.name].features.map((feature, idx) => (
                        <motion.div
                          key={idx}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: idx * 0.1 }}
                          className="flex items-start gap-3 text-gray-600"
                        >
                          <span className="text-pink-500 mt-1">✦</span>
                          <span className="text-sm">{feature}</span>
                        </motion.div>
                      ))}

                      <motion.div
                        className="mt-6 pt-4 border-t border-gray-100"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                      >
                        <div className="text-center">
                          <span className="text-2xl font-bold text-gray-800">
                            ${packageDetails[pkg.name].price}
                          </span>
                          <span className="text-sm text-gray-500 ml-2">
                            Ahorras ${packageDetails[pkg.name].savings}
                          </span>
                        </div>
                      </motion.div>
                    </div>
                  </motion.div>
                ) : (
                  <motion.button
                    key="front"
                    onClick={() => setSelectedFeatures(pkg.name)}
                    initial={{ rotateY: -180, opacity: 0 }}
                    animate={{ rotateY: 0, opacity: 1 }}
                    exit={{ rotateY: 180, opacity: 0 }}
                    transition={{ duration: 0.6 }}
                    style={{ transformStyle: "preserve-3d" }}
                    className="w-full py-3 px-4 bg-gradient-to-r from-pink-400 to-pink-500 text-white rounded-xl
                               hover:from-pink-500 hover:to-pink-600 transition-all duration-300 
                               flex items-center justify-center gap-2 shadow-lg hover:shadow-xl"
                    whileHover={{ scale: 1.02, y: -2 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <span className="text-lg">✨</span>
                    <span>Ver características</span>
                    <motion.span
                      animate={{ x: [0, 5, 0] }}
                      transition={{ duration: 1, repeat: Infinity }}
                    >
                      →
                    </motion.span>
                  </motion.button>
                )}
              </AnimatePresence>
            </div>
            {/* Package Designs */}
            {pkg.designs && pkg.designs.length > 0 ? (
              pkg.designs.map((design, idx) => (
                <div key={idx} className="text-center mb-4">
                  <div className="mb-4 text-center">
                    <span className="text-[#FFD700] font-bold block mb-2 text-sm">
                      ¡Oferta!
                    </span>
                    <div className="flex items-center justify-center gap-2 mb-1">
                      <span className="text-[#FFD700] text-sm">
                        -{Math.round((1 - parseInt(design.precice) / parseInt(design.preciceDec)) * 100)}%
                      </span>
                      <span className="text-lg font-bold">
                        ${design.precice}
                      </span>
                    </div>
                    <span className="text-[#B0B0B0] text-[11px]">
                      Antes <span className="line-through">${design.preciceDec}</span>
                    </span>
                  </div>
                  <div className="space-y-3">
                    <a
                      href={design.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-white gap-4 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                    >
                      <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                      </svg>
                      <span>{design.name}</span>
                    </a>
                    {design.link2 && (
                      <a
                        href={design.link2}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-white gap-4 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                      >
                        <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                          <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                        </svg>
                        <span>{design.name2}</span>
                      </a>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>No hay diseños disponibles</div>
            )}
          </motion.div>
        ))}
      </motion.div>
      {/* How to Purchase Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="p-8 w-full flex flex-col items-center bg-opacity-90 rounded-lg shadow-xl mt-16"
      >
        <h1 className="text-4xl text-center font-serif mb-6 text-gray-800">Cómo adquirir tu invitación</h1>
        <FaArrowDown className="text-5xl text-blue-600 animate-bounce mb-8" />
        <div className="relative w-full md:w-3/4 lg:w-2/3 xl:w-1/2" style={{ paddingBottom: "56.25%" }}>
          <video
            className="absolute inset-0 w-full h-full object-contain rounded-lg shadow-lg"
            src={videoProceso}
            controls
            autoPlay
            loop
            muted
          />
        </div>
      </motion.div>
      <div className="h-16"></div> {/* Spacer */}
    </div>
  );
};
