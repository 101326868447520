import React from 'react';

const SelectTipoLetra = ({ values, handleChange }) => {
  return (
    <div>
      <label htmlFor="tipoLetra" className="block text-sm font-medium text-gray-700 mb-1">
        Tipo de Letra
      </label>
      <select
        id="tipoLetra"
        name="tipoLetra"
        value={values?.tipoLetra}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        style={{
          fontFamily: values?.tipoLetra || "Arial, sans-serif",
        }}
      >
        <option value="">Selecciona una fuente</option>
        <option value="Marck Script, cursive" style={{ fontFamily: "Marck Script, cursive" }}>
          Marck Script
        </option>
        <option value="Arial, sans-serif" style={{ fontFamily: "Arial, sans-serif" }}>
          Arial
        </option>
        <option value="Times New Roman, serif" style={{ fontFamily: "Times New Roman, serif" }}>
          Times New Roman
        </option>
        <option value="Akaya Kanadaka, cursive" style={{ fontFamily: "Akaya Kanadaka, cursive" }}>
          Akaya Kanadaka
        </option>
        <option value="Alegreya, serif" style={{ fontFamily: "Alegreya, serif" }}>
          Alegreya
        </option>
        <option value="Courier New, monospace" style={{ fontFamily: "Courier New, monospace" }}>
          Courier New
        </option>
        <option value="La Belle Aurore, cursive" style={{ fontFamily: "La Belle Aurore, cursive" }}>
          La Belle Aurore
        </option>
        <option value="Ewert, serif" style={{ fontFamily: "Ewert, serif" }}>
          Ewert
        </option>
        <option value="Lavishly Yours, cursive" style={{ fontFamily: "Lavishly Yours, cursive" }}>
          Lavishly Yours
        </option>
        <option value="Alfa Slab One, sans-serif" style={{ fontFamily: "Alfa Slab One, sans-serif" }}>
          Alfa Slab One
        </option>
        <option value="Cormorant Upright, serif" style={{ fontFamily: "Cormorant Upright, serif" }}>
          Cormorant Upright
        </option>
        <option value="Bigelow Rules, serif" style={{ fontFamily: "Bigelow Rules, serif" }}>
          Bigelow Rules
        </option>
        <option value="Dancing Script, cursive" style={{ fontFamily: "Dancing Script, cursive" }}>
          Dancing Script
        </option>
        <option value="Georgia, serif" style={{ fontFamily: "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" }}>
          Georgia
        </option>
        <option value="Verdana, sans-serif" style={{ fontFamily: "Verdana, sans-serif" }}>
          Verdana
        </option>
        <option value="Playwrite AR, cursive" style={{ fontFamily: "Playwrite AR, cursive" }}>
          Playwrite AR
        </option>
        <option value="Tangerine, cursive" style={{ fontFamily: "Tangerine, cursive" }}>
          Tangerine
        </option>
        <option value="Playwrite US Trad, cursive" style={{ fontFamily: "Playwrite US Trad, cursive" }}>
          Playwrite US Trad
        </option>
        <option value="Nunito, sans-serif" style={{ fontFamily: "Nunito, sans-serif" }}>
          Nunito
        </option>
        <option value="Pacifico, cursive" style={{ fontFamily: "Pacifico, cursive" }}>
          Pacifico
        </option>
        <option value="Roboto, sans-serif" style={{ fontFamily: "Roboto, sans-serif" }}>
          Roboto
        </option>
        <option value="Cedarville Cursive, cursive" style={{ fontFamily: "Cedarville Cursive, cursive" }}>
          Cedarville Cursive
        </option>
        <option value="Ibarra Real Nova, serif" style={{ fontFamily: "Ibarra Real Nova, serif" }}>
          Ibarra Real Nova
        </option>
        <option value="Outfit, sans-serif" style={{ fontFamily: "Outfit, sans-serif" }}>
          Outfit
        </option>
        <option value="Prata, serif" style={{ fontFamily: "Prata, serif" }}>
          Prata
        </option>
        <option value="Courgette, cursive" style={{ fontFamily: "Courgette, cursive" }}>
          Courgette
        </option>
        <option value="Cinzel Decorative, serif" style={{ fontFamily: "Cinzel Decorative, serif" }}>
          Cinzel Decorative
        </option>
        <option value="Petit Formal Script, cursive" style={{ fontFamily: "Petit Formal Script, cursive" }}>
          Petit Formal Script
        </option>
        <option value="Bodoni Moda, serif" style={{ fontFamily: "Bodoni Moda, serif" }}>
          Bodoni Moda
        </option>
      </select>
    </div>
  );
};

export default SelectTipoLetra;
