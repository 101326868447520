import React from 'react';

const ErrorAlert = ({ message, onRetry, onClose, redirect }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white rounded-lg p-8 max-w-md mx-4 shadow-xl transform transition-all">
      <div className="text-center">
        <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-${redirect ? "red" : "yellow"}-100 mb-4`}>
          <svg className={`h-6 w-6 text-${redirect ? "red" : "yellow"}-600`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {redirect ? "¡Ups! Algo salió mal" : "✨ Aviso importante ✨"}
        </h3>
        
        <p className="text-sm text-gray-500 mb-6">
          {message}
        </p>

        <div className="flex justify-center space-x-3">
          {redirect ? (
            <button onClick={() => window.location.href = "/"} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors">
              Ir a la página principal
            </button>
          ) : (
            <div className="flex space-x-3">
              <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors">
                Continuar
              </button>
              <button onClick={onRetry} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors">
                Intentar de nuevo
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ErrorAlert; 