import { Home } from "./components/Home";

import { EventoEjemplo } from "./screens/eventos/Evento-plata/EventoEjemplo";
import { FormularioBodaDiamante } from "./screens/bodas/Boda-diamante/FormularioBodaDiamante";
import { EventoGraduacionEjemplo } from "./screens/eventos/Evento-plata/EventoGraduacionEjemplo";
import { XvEjemplo } from "./screens/XV/xv-diamante/XvEjemplo";
import { FormularioXvDiamante } from "./screens/XV/xv-diamante/FormularioXvDiamante";
import { FormularioEventoPlata } from "./screens/eventos/Evento-plata/FormularioEventoPlata";
import { Miamorcito } from './components/Miamorcito'
import { XvEjemploOro } from "./screens/XV/xv-oro/XvEjemploOro";
import { XvEjemploPlata } from "./screens/XV/xv-plata/XvEjemploPlata";
import { FormularioXvPlata } from "./screens/XV/xv-plata/FormularioXvPlata";
import { FormularioBodaPlata } from "./screens/bodas/Boda-plata/FormularioBodaPlata";
import { InvitadoView } from "./screens/ImgEvent/InvitadoView";
import { ImgEvent } from "./screens/ImgEvent/ImgEvent";
import { ImagenesAzure } from "./screens/Test/ImagenesAzure";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/miamorcito',
    element: <Miamorcito />
  },
  {
    path: '/boda-diamante-form',
    element: <FormularioBodaDiamante />
  },
  {
    path: '/boda-plata-form',
    element: <FormularioBodaPlata/>
  },
  {
    path: '/xv-diamante-form',
    element: <FormularioXvDiamante />
  },
  {
    path: '/xv-plata-form',
    element: <FormularioXvPlata />
  },
  {
    path: '/evento-plata-form',
    element: <FormularioEventoPlata />
  },
  {
    path: '/img-event/:id',
    element: <ImgEvent />
  },
  {
    path: '/img-event-inv/:id',
    element: <InvitadoView />
  },
  {
    path: '/test/imagenes-azure',
    element: <ImagenesAzure />
  },
];

export default AppRoutes;
