'use client'

import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import FormattedText from '../functions/FormattedText'
import { useLocation } from 'react-router-dom'

export const ConfirmacionBodaComponent2 = ({ datosSeccionConfirmacion, seccionImagenes, diamante, isEnglish }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [invitadoId, setInvitadoId] = useState(null)
  const location = useLocation()

  useEffect(() => {
    // Obtener el ID del invitado de la URL
    const searchParams = new URLSearchParams(location.search)
    const idFromUrl = searchParams.get('invitadoId')
    if (idFromUrl) {
      setInvitadoId(idFromUrl)
    }
  }, [location])

  const [formData, setFormData] = useState({
    nombre: '',
    asistencia: '',
    personas: '1',
    comentarios: '',
    numPases: datosSeccionConfirmacion?.numPases || 10
  })

  const fontFamilyStyle = {
    fontFamily: datosSeccionConfirmacion?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: '#000000'
  };

  const photos = [
    'https://images.unsplash.com/photo-1519741497674-611481863552?w=800&h=600&fit=crop',
    'https://images.unsplash.com/photo-1511285560929-80b456fea0bc?w=800&h=600&fit=crop',
    'https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?w=800&h=600&fit=crop',
    'https://images.unsplash.com/photo-1460364157752-926555421a7e?w=800&h=600&fit=crop',
  ]

  const handleDragStart = (event) => {
    setIsDragging(true)
    const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX
    setDragStart(clientX)
  }

  const handleDragEnd = (event) => {
    if (!isDragging) return

    const clientX = 'changedTouches' in event ? event.changedTouches[0].clientX : event.clientX
    const delta = dragStart - clientX

    if (Math.abs(delta) > 100) { // Minimum drag distance
      if (delta > 0 && currentIndex < seccionImagenes.length - 1) {
        setCurrentIndex(prev => prev + 1)
      } else if (delta < 0 && currentIndex > 0) {
        setCurrentIndex(prev => prev - 1)
      }
    }

    setIsDragging(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = async (event, type) => {
    event.preventDefault();
    setSubmitted(true);

    // Construir mensaje de WhatsApp
    const message = isEnglish ? 
      `Hello! I'm ${formData.nombre}.\n
${formData.asistencia === 'si' ? `I confirm my attendance with ${formData.personas} ${formData.personas === '1' ? 'person' : 'people'}!` : 'I regret I cannot attend.'}\n
${formData.comentarios ? `Message: ${formData.comentarios}` : ''}` :
      `¡Hola! Soy ${formData.nombre}.\n
${formData.asistencia === 'si' ? `¡Confirmo mi asistencia con ${formData.personas} ${formData.personas === '1' ? 'persona' : 'personas'}!` : 'Lamento no poder asistir.'}\n
${formData.comentarios ? `Mensaje: ${formData.comentarios}` : ''}`;

    // Codificar el mensaje para URL
    const encodedMessage = encodeURIComponent(message);

    // Número de WhatsApp desde los datos
    const phoneNumber = datosSeccionConfirmacion?.numeroConfirmacion || '0';

    // Si el usuario confirma asistencia, actualizar el contador
    if (formData.asistencia === 'si') {
      try {
        let endpoint;
        let body;

        if (invitadoId) {
          // Si tenemos el ID del invitado, usamos el endpoint directo
          endpoint = `/api/Invitados/${invitadoId}`;
          body = {
            id: invitadoId,
            confirmado: parseInt(formData.personas)
          };
        } else {
          // Si no tenemos el ID, usamos el nombre para buscar y actualizar
          endpoint = '/api/Invitados/confirmar';
          body = {
            nombre: formData.nombre,
            eventoId: datosSeccionConfirmacion.eventoId, // Asumiendo que tenemos el ID del evento
            confirmado: parseInt(formData.personas)
          };
        }

        const response = await fetch(endpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        });
          
        if (!response.ok) {
          console.error('Error al actualizar el contador de invitados');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    // Abrir WhatsApp en nueva pestaña
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(whatsappUrl);

    setTimeout(() => setSubmitted(false), 2000);
  }

  return (
    <div className="min-h-screen py-12" style={{
      backgroundImage: `url(${datosSeccionConfirmacion?.imgFondo})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: datosSeccionConfirmacion?.textColor || 'inherit',
      ...fontFamilyStyle
    }}>
      <div className="mx-auto max-w-md px-4">
        {/* Photo Gallery */}
        {seccionImagenes && seccionImagenes?.length > 0 && (
          <div className="mb-16">
            <div className="relative">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -300, opacity: 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  className="relative"
                  onMouseDown={handleDragStart}
                  onMouseUp={handleDragEnd}
                  onMouseLeave={handleDragEnd}
                  onTouchStart={handleDragStart}
                  onTouchEnd={handleDragEnd}
                  style={{ touchAction: 'pan-y pinch-zoom' }}
                >
                  {/* Stacked photos effect */}
                  <div className="absolute -bottom-2 left-1 right-1 top-2 rotate-[-4deg] rounded-lg bg-white shadow-md" />
                  <div className="absolute -bottom-1 left-0.5 right-0.5 top-1 rotate-[-2deg] rounded-lg bg-white shadow-md" />

                  {/* Main photo */}
                  <div className="relative rounded-lg bg-white p-4 shadow-xl">
                    <div className="overflow-hidden rounded-md">
                      <img
                        src={seccionImagenes[currentIndex]}
                        alt={isEnglish ? `Wedding photo ${currentIndex + 1}` : `Foto de boda ${currentIndex + 1}`}
                        className="aspect-[4/5] w-full object-cover"
                      />
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>

              {/* Swipe indicator */}
              <div className="mt-8 text-center">
                <div className="mb-2 flex justify-center">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    className="h-8 w-8 text-gray-600"
                    style={textStyle}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M8 7h8m-8 5h8m-8 5h8"
                      style={textStyle}
                    />
                  </svg>
                </div>
                <p className="font-serif text-sm text-gray-600" style={{ ...fontFamilyStyle, ...textStyle }}>
                  {isEnglish ? "Swipe to see more photos" : "Desliza para ver más fotos"}
                </p>
              </div>

              {/* Photo counter */}
              <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 rounded-full bg-white/80 px-3 py-1 text-sm font-light text-gray-800">
                {currentIndex + 1} / {seccionImagenes.length}
              </div>
            </div>
          </div>
        )}

        {datosSeccionConfirmacion?.aviso && !diamante && (
          <div className='flex justify-center items-center'>
            <p className='text-center text-2xl' style={{ ...fontFamilyStyle, ...textStyle }}><FormattedText text={datosSeccionConfirmacion?.aviso} /></p>
          </div>
        )}

        {/* RSVP Form */}
        {!diamante && (
          <div className="rounded-lg bg-white p-6 shadow-xl">
            <h2 className="mb-6 text-center font-serif text-2xl font-semibold text-gray-800" style={fontFamilyStyle}>
              {isEnglish ? "Attendance Confirmation" : "Confirmación de Asistencia"}
            </h2>
            <form onSubmit={(e) => handleSubmit(e, 'novia')} className="space-y-4">
              <div>
                <label htmlFor="nombre" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                  {isEnglish ? "Family Name" : "Nombre de la Familia"}
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  className="w-full text-black rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                  placeholder={isEnglish ? "Smith Family" : "Fam. González Molina"}
                  required
                  style={fontFamilyStyle}
                />
              </div>
              <div>
                <label htmlFor="asistencia" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                  {isEnglish ? "Will you attend?" : "¿Asistirás?"}
                </label>
                <select
                  id="asistencia"
                  name="asistencia"
                  value={formData.asistencia}
                  onChange={handleInputChange}
                  className="w-full text-black rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                  required
                  style={fontFamilyStyle}
                >
                  <option value="">{isEnglish ? "Select an option" : "Selecciona una opción"}</option>
                  <option value="si">{isEnglish ? "Yes, I will attend" : "Sí, asistiré"}</option>
                  <option value="no">{isEnglish ? "No, I cannot attend" : "No podré asistir"}</option>
                </select>
              </div>

              {formData.asistencia === 'si' && (
                <div>
                  <label htmlFor="personas" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                    {isEnglish ? "Number of attendees" : "Número de asistentes"}
                  </label>
                  <div className="flex items-center space-x-2">
                    <button
                      type="button"
                      onClick={() => setFormData(prev => ({
                        ...prev,
                        personas: Math.max(1, parseInt(prev.personas) - 1).toString()
                      }))}
                      className="rounded-full bg-gray-200 p-2 hover:bg-gray-300"
                      disabled={formData.personas === '1'}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      id="personas"
                      name="personas"
                      value={formData.personas}
                      onChange={handleInputChange}
                      min="1"
                      max={datosSeccionConfirmacion?.numPases || 10}
                      className="w-20 text-center text-black rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                      required
                      style={fontFamilyStyle}
                    />
                    <button
                      type="button"
                      onClick={() => setFormData(prev => ({
                        ...prev,
                        personas: Math.min(datosSeccionConfirmacion?.numPases || 10, parseInt(prev.personas) + 1).toString()
                      }))}
                      className="rounded-full bg-gray-200 p-2 hover:bg-gray-300"
                      disabled={parseInt(formData.personas) >= (datosSeccionConfirmacion?.numPases || 10)}
                    >
                      +
                    </button>
                  </div>
                  <p className="mt-1 text-sm text-gray-500" style={fontFamilyStyle}>
                    {isEnglish ? 
                      `Maximum number of passes: ${datosSeccionConfirmacion?.numPases || 10}` : 
                      `Número máximo de pases: ${datosSeccionConfirmacion?.numPases || 10}`}
                  </p>
                </div>
              )}
              <div>
                <label htmlFor="comentarios" className="mb-1 block text-sm font-medium text-gray-700" style={fontFamilyStyle}>
                  {isEnglish ? "Comments and Congratulations" : "Comentarios y Felicitaciones"}
                </label>
                <textarea
                  id="comentarios"
                  name="comentarios"
                  value={formData.comentarios}
                  onChange={handleInputChange}
                  rows={3}
                  className="w-full text-black rounded-md border border-gray-300 px-3 py-2 focus:border-[#9E8B8E] focus:outline-none focus:ring-1 focus:ring-[#9E8B8E]"
                  placeholder={isEnglish ? "Write your congratulations or comments here..." : "Escribe tus felicitaciones o comentarios aquí..."}
                  style={fontFamilyStyle}
                ></textarea>
              </div>
              <div className="grid gap-4 pt-4 sm:grid-cols-2">
                <button
                  type="submit"
                  className="w-full rounded-md bg-[#9E8B8E] px-4 py-2 text-white transition-colors hover:bg-[#8A7477] focus:outline-none focus:ring-2 focus:ring-[#9E8B8E] focus:ring-offset-2"
                  disabled={submitted}
                  style={fontFamilyStyle}
                >
                  {submitted ? (isEnglish ? 'Sent!' : '¡Enviado!') : (isEnglish ? 'Confirm' : 'Confirmar')}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}