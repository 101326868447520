import React, { useState } from 'react';
import axios from 'axios';
import { getHeaders } from '../../../../components/auth/authHelpers';
import toast from 'react-hot-toast';

const ImageMigration = () => {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const startMigration = async () => {
        try {
            setLoading(true);
            setError(null);
            
            // Mostrar toast de inicio
            toast.loading('Iniciando migración de imágenes...', { id: 'migration' });

            const response = await axios.get("/api/Images/ListFromServer", {
                headers: await getHeaders(),
                timeout: 300000 // 5 minutos de timeout
            });
            
            setResult(response.data);
            toast.success('Migración iniciada correctamente', { id: 'migration' });
        } catch (err) {
            console.error('Error detallado:', err);
            
            let errorMessage = 'Error al iniciar la migración';
            
            if (err.response) {
                // Error de respuesta del servidor
                if (err.response.status === 401) {
                    errorMessage = 'Sesión expirada, por favor inicie sesión nuevamente';
                } else if (err.response.status === 500) {
                    errorMessage = err.response.data?.message || 'Error interno del servidor';
                }
            } else if (err.request) {
                // Error de red o timeout
                errorMessage = 'No se pudo conectar con el servidor. Por favor, verifica tu conexión.';
            }

            setError(errorMessage);
            toast.error(errorMessage, { id: 'migration' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Migración de Imágenes</h2>
            
            <div className="mb-4">
                <button 
                    onClick={startMigration}
                    disabled={loading}
                    className={`px-4 py-2 rounded-md text-white font-medium ${
                        loading 
                            ? 'bg-blue-400 cursor-not-allowed' 
                            : 'bg-blue-600 hover:bg-blue-700'
                    }`}
                >
                    {loading ? 'Migrando imágenes...' : 'Iniciar Migración'}
                </button>
            </div>

            {error && (
                <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                    <p className="font-bold">Error</p>
                    <p>{error}</p>
                </div>
            )}

            {result && (
                <div>
                    {/* Barra de progreso */}
                    <div className="mb-4">
                        <div className="w-full bg-gray-200 rounded-full h-4">
                            <div 
                                className="bg-blue-600 h-4 rounded-full transition-all duration-500"
                                style={{ width: `${result.completionPercentage}%` }}
                            >
                            </div>
                        </div>
                        <p className="text-center mt-1 text-gray-600">
                            {result.completionPercentage.toFixed(2)}% Completado
                        </p>
                    </div>

                    {/* Estadísticas */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="text-gray-600">Total de archivos</p>
                            <p className="text-2xl font-bold">{result.totalFiles}</p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="text-gray-600">Imágenes encontradas</p>
                            <p className="text-2xl font-bold">{result.totalImages}</p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="text-gray-600">Procesadas exitosamente</p>
                            <p className="text-2xl font-bold">{result.processedFiles}</p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="text-gray-600">Saltadas (existentes)</p>
                            <p className="text-2xl font-bold">{result.skippedExisting}</p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="text-gray-600">Errores</p>
                            <p className="text-2xl font-bold">{result.errorCount}</p>
                        </div>
                    </div>

                    {/* Lista de archivos saltados */}
                    {result.skippedFiles?.length > 0 && (
                        <div>
                            <h3 className="font-bold text-lg mb-2">Archivos saltados:</h3>
                            <div className="max-h-60 overflow-y-auto border rounded-lg">
                                {result.skippedFiles.map((item, index) => (
                                    <div 
                                        key={index}
                                        className="p-2 border-b last:border-b-0 hover:bg-gray-50"
                                    >
                                        <span className="font-medium">{item.name}</span>
                                        <span className="text-gray-600 ml-2">- {item.reason}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImageMigration; 