import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import toast from 'react-hot-toast';

export const ModalGaleriaConfig = ({ 
  showModal, 
  setShowModal, 
  galeriaForm, 
  setGaleriaForm, 
  selectedEventoForGaleria 
}) => {
  const handleSubmit = async () => {
    if (!galeriaForm.galeriaAdmin.nombre) {
      toast.error('Por favor ingrese un nombre para la galería');
      return;
    }

    try {
      // Preparar datos para el endpoint
      const requestData = {
        galeriaAdmin: {
          cantidadImagenes: galeriaForm.galeriaAdmin.cantidadImagenes,
          foto: galeriaForm.galeriaAdmin.foto || '',
          nombre: galeriaForm.galeriaAdmin.nombre,
          idEvento: selectedEventoForGaleria.idEvento,
          fecha: galeriaForm.galeriaAdmin.fecha
        },
        seccionInvitados: {
          imgFondo: '',
          titulo: 'Invitados',
          entradasText: '',
          colorFondoName: '#FFFFFF',
          eventoTitulo: selectedEventoForGaleria.nombre,
          idEvento: selectedEventoForGaleria.idEvento,
          limiteAccesos: galeriaForm.seccionInvitados.limiteAccesos || 0,
          tipoLetra: 'Arial'
        },
        albums: [
          {
            posicion: 1,
            nombre: 'Ceremonia',
            idEvento: selectedEventoForGaleria.idEvento,
            imagenPortada: ''
          },
          {
            posicion: 2,
            nombre: 'Recepción',
            idEvento: selectedEventoForGaleria.idEvento,
            imagenPortada: ''
          }
        ]
      };

      const response = await axios.post('/api/GaleriaEventoAdmin/CreateComplete', requestData);
      
      if (response.status === 200) {
        toast.success('Galería creada exitosamente');
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error: ' + (error.response?.data || error.message));
    }
  };

  return (
    showModal && (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl max-w-2xl w-full p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">
              Crear Nueva Galería
            </h2>
            <button
              onClick={() => setShowModal(false)}
              className="text-gray-400 hover:text-gray-600"
            >
              <FaTimes />
            </button>
          </div>

          <div className="space-y-6">
            <div className="border-b pb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Galería Principal</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nombre de la Galería
                  </label>
                  <input
                    type="text"
                    value={galeriaForm.galeriaAdmin.nombre}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, nombre: e.target.value }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                    placeholder="Nombre de la galería"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha del Evento
                  </label>
                  <input
                    type="date"
                    value={galeriaForm.galeriaAdmin.fecha}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, fecha: e.target.value }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    URL de la Foto de Portada
                  </label>
                  <input
                    type="text"
                    value={galeriaForm.galeriaAdmin.foto}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, foto: e.target.value }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                    placeholder="URL de la imagen de portada"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Cantidad de Imágenes Permitidas
                  </label>
                  <input
                    type="number"
                    value={galeriaForm.galeriaAdmin.cantidadImagenes}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, cantidadImagenes: parseInt(e.target.value) }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                    min="1"
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Límite de Accesos</h3>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Cantidad máxima de invitados
                </label>
                <input
                  type="number"
                  value={galeriaForm.seccionInvitados.limiteAccesos}
                  onChange={(e) => setGaleriaForm(prev => ({
                    ...prev,
                    seccionInvitados: { 
                      ...prev.seccionInvitados, 
                      limiteAccesos: parseInt(e.target.value) 
                    }
                  }))}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                  min="0"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 mt-6">
              <button
                onClick={handleSubmit}
                className="w-full py-2 bg-[#D4AF37] text-white rounded-lg hover:bg-[#B89530] transition-colors"
              >
                Crear Galería
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="w-full py-2 border border-gray-200 text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}; 