import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import toast from 'react-hot-toast';

export const ModalEditarGaleria = ({ 
  showModal, 
  setShowModal, 
  selectedEventoForGaleria 
}) => {
  const [galeriaForm, setGaleriaForm] = useState({
    galeriaAdmin: {
      idAdmin: null,
      cantidadImagenes: 0,
      foto: '',
      nombre: '',
      fecha: ''
    },
    seccionInvitados: {
      limiteAccesos: 0
    }
  });
  const [existingGaleriaId, setExistingGaleriaId] = useState(null);

  useEffect(() => {
    const loadExistingData = async () => {
      if (selectedEventoForGaleria?.idEvento) {
        try {
          // Cargar datos de GaleriaEventoAdmin
          const galeriaResponse = await axios.get(`/api/GaleriaEventoAdmin/Evento/${selectedEventoForGaleria.idEvento}`);
          const galeriaData = Array.isArray(galeriaResponse.data) ? galeriaResponse.data[0] : galeriaResponse.data;
          
          if (galeriaData) {
            setExistingGaleriaId(galeriaData.idAdmin);
            setGaleriaForm(prev => ({
              ...prev,
              galeriaAdmin: {
                ...prev.galeriaAdmin,
                nombre: galeriaData.nombre || '',
                foto: galeriaData.foto || '',
                fecha: galeriaData.fecha || '',
                cantidadImagenes: galeriaData.cantidadImagenes || 100
              }
            }));
          }

          // Cargar datos de SeccionInvitados
          const seccionResponse = await axios.get(`/api/Seccioninvitado/${selectedEventoForGaleria.idEvento}`);
          const seccionData = Array.isArray(seccionResponse.data) ? seccionResponse.data[0] : seccionResponse.data;
          
          if (seccionData) {
            setGaleriaForm(prev => ({
              ...prev,
              seccionInvitados: {
                ...prev.seccionInvitados,
                limiteAccesos: seccionData.limiteAccesos || 0
              }
            }));
          }
        } catch (error) {
          console.error('Error al cargar datos:', error);
          toast.error('Error al cargar la información de la galería');
        }
      }
    };

    if (showModal) {
      loadExistingData();
    }
  }, [showModal, selectedEventoForGaleria]);

  const handleSubmit = async () => {
    try {
      // Actualizar GaleriaEventoAdmin
      const galeriaAdminData = {
        idAdmin: existingGaleriaId,
        cantidadImagenes: galeriaForm.galeriaAdmin.cantidadImagenes,
        foto: galeriaForm.galeriaAdmin.foto || '',
        nombre: galeriaForm.galeriaAdmin.nombre,
        idEvento: selectedEventoForGaleria.idEvento,
        fecha: galeriaForm.galeriaAdmin.fecha
      };

      await axios.put(`/api/GaleriaEventoAdmin/${existingGaleriaId}`, galeriaAdminData);

      // Actualizar SeccionInvitados
      const seccionResponse = await axios.get(`/api/Seccioninvitado/${selectedEventoForGaleria.idEvento}`);
      const seccionData = Array.isArray(seccionResponse.data) ? seccionResponse.data[0] : seccionResponse.data;

      if (seccionData) {
        const seccionInvitadosData = {
          invitadosId: seccionData.invitadosId,
          imgFondo: seccionData.imgFondo || '',
          titulo: seccionData.titulo || 'Invitados',
          entradasText: seccionData.entradasText || '',
          colorFondoName: seccionData.colorFondoName || '#FFFFFF',
          eventoTitulo: selectedEventoForGaleria.nombre,
          idEvento: selectedEventoForGaleria.idEvento,
          limiteAccesos: galeriaForm.seccionInvitados.limiteAccesos,
          tipoLetra: seccionData.tipoLetra || 'Arial'
        };

        await axios.put(`/api/Seccioninvitado/${seccionData.invitadosId}`, seccionInvitadosData);
      }

      toast.success('Galería actualizada exitosamente');
      setShowModal(false);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al actualizar la galería');
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar esta galería? Se eliminarán todas las imágenes y álbumes asociados.');
    
    if (!confirmed) return;

    try {
      await axios.delete(`/api/GaleriaEventoAdmin/DeleteGaleria/${selectedEventoForGaleria.idEvento}`);
      toast.success('Galería eliminada exitosamente');
      setShowModal(false);
    } catch (error) {
      console.error('Error al eliminar la galería:', error);
      toast.error('Error al eliminar la galería');
    }
  };

  return (
    showModal && (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl max-w-2xl w-full p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Editar Galería</h2>
            <button
              onClick={() => setShowModal(false)}
              className="text-gray-400 hover:text-gray-600"
            >
              <FaTimes />
            </button>
          </div>

          <div className="space-y-6">
            <div className="border-b pb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Galería Principal</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nombre de la Galería
                  </label>
                  <input
                    type="text"
                    value={galeriaForm.galeriaAdmin.nombre}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, nombre: e.target.value }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha del Evento
                  </label>
                  <input
                    type="date"
                    value={galeriaForm.galeriaAdmin.fecha}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, fecha: e.target.value }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    URL de la Foto de Portada
                  </label>
                  <input
                    type="text"
                    value={galeriaForm.galeriaAdmin.foto}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, foto: e.target.value }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Cantidad de Imágenes Permitidas
                  </label>
                  <input
                    type="number"
                    value={galeriaForm.galeriaAdmin.cantidadImagenes}
                    onChange={(e) => setGaleriaForm(prev => ({
                      ...prev,
                      galeriaAdmin: { ...prev.galeriaAdmin, cantidadImagenes: parseInt(e.target.value) }
                    }))}
                    className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                    min="1"
                  />
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Límite de Accesos</h3>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Cantidad máxima de invitados
                </label>
                <input
                  type="number"
                  value={galeriaForm.seccionInvitados.limiteAccesos}
                  onChange={(e) => setGaleriaForm(prev => ({
                    ...prev,
                    seccionInvitados: { 
                      ...prev.seccionInvitados, 
                      limiteAccesos: parseInt(e.target.value) 
                    }
                  }))}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#D4AF37]"
                  min="0"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 mt-6">
              <button
                onClick={handleSubmit}
                className="w-full py-2 bg-[#D4AF37] text-white rounded-lg hover:bg-[#B89530] transition-colors"
              >
                Guardar Cambios
              </button>
              <button
                onClick={handleDelete}
                className="w-full py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Eliminar Galería
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="w-full py-2 border border-gray-200 text-gray-600 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}; 