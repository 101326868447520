import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { PadresBodaComponent2 } from '../../../components/ComponentsBoda2/PadresBodaComponent2';
import { PadresBodaComponent } from '../../../components/ComponentsBoda/PadresBodaComponent';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { PreviewModal } from '../../../components/PreviewModal';

export const PadresForm = ({ initialValues, onSubmit, colores, diseño, screenMobile }) => {
  const [datosPadres, setDatosPadres] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [currentValues, setCurrentValues] = useState(null);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Manejador personalizado para actualizar tanto Formik como datosPadres
  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
  };

  // Manejador para el botón de preview
  const handlePreviewClick = (values) => {
    setDatosPadres({
      cantidad: values?.cantidad || "",
      titulo: values?.titulo || "",
      descripcion: values?.descripcion || "",
      titulo1: values?.titulo1 || "",
      madre: values?.madre || "",
      padre: values?.padre || "",
      titulo2: values?.titulo2 || "",
      madrina: values?.madrina || "",
      padrino: values?.padrino || "",
      titulo3: values?.titulo3 || "",
      madrina2: values?.madrina2 || "",
      padrino2: values?.padrino2 || "",
      imgFondo: values?.imgFondo || "",
      textColor: values?.textColor || "",
      tipoLetra: values?.tipoLetra || "",
    });
    setRefreshKey(prev => prev + 1);
  };

  useEffect(() => {
    setDatosPadres({
      cantidad: initialValues?.cantidad || "",
      titulo: initialValues?.titulo || "",
      descripcion: initialValues?.descripcion || "",
      titulo1: initialValues?.titulo1 || "",
      madre: initialValues?.madre || "",
      padre: initialValues?.padre || "",
      titulo2: initialValues?.titulo2 || "",
      madrina: initialValues?.madrina || "",
      padrino: initialValues?.padrino || "",
      titulo3: initialValues?.titulo3 || "",
      madrina2: initialValues?.madrina2 || "",
      padrino2: initialValues?.padrino2 || "",
      imgFondo: initialValues?.imgFondo || "",
      textColor: initialValues?.textColor || "",
      tipoLetra: initialValues?.tipoLetra || "",
    });
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Detalles de los Padres</h3>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                {/* Sección 1 */}
                <div className="bg-blue-50 p-6 rounded-lg mb-6">
                  <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Título:
                  </label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={values?.titulo}
                    onChange={handleCustomChange(handleChange, values)}
                  />
                </div>

                <div className="bg-blue-50 p-6 rounded-lg mb-6">
                  <div className="flex flex-col items-center">
                    <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-1">
                      Descripción:
                    </label>
                    <textarea
                      type="text"
                      id="descripcion"
                      name="descripcion"
                      value={values?.descripcion}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <h4 className="text-lg font-medium text-blue-900 mb-4">Sección 1</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="titulo1" className="block text-sm font-medium text-gray-700 mb-1">
                        Título 1:
                      </label>
                      <Input
                        type="text"
                        id="titulo1"
                        name="titulo1"
                        value={values?.titulo1}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="madre" className="block text-sm font-medium text-gray-700 mb-1">
                        Madre:
                      </label>
                      <Input
                        type="text"
                        id="madre"
                        name="madre"
                        value={values?.madre}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="padre" className="block text-sm font-medium text-gray-700 mb-1">
                        Padre:
                      </label>
                      <Input
                        type="text"
                        id="padre"
                        name="padre"
                        value={values?.padre}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                  </div>
                </div>

                {/* Sección 2 */}
                <div className="bg-green-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-green-900 mb-4">Sección 2</h4>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="titulo2" className="block text-sm font-medium text-gray-700 mb-1">
                        Título 2:
                      </label>
                      <Input
                        type="text"
                        id="titulo2"
                        name="titulo2"
                        value={values?.titulo2}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="madrina" className="block text-sm font-medium text-gray-700 mb-1">
                        Madrina:
                      </label>
                      <Input
                        type="text"
                        id="madrina"
                        name="madrina"
                        value={values?.madrina}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="padrino" className="block text-sm font-medium text-gray-700 mb-1">
                        Padrino:
                      </label>
                      <Input
                        type="text"
                        id="padrino"
                        name="padrino"
                        value={values?.padrino}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                  </div>
                </div>

                {/* Sección 3 */}
                <div className="bg-purple-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-purple-900 mb-4">Sección 3</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="titulo3" className="block text-sm font-medium text-gray-700 mb-1">
                        Título 3:
                      </label>
                      <Input
                        type="text"
                        id="titulo3"
                        name="titulo3"
                        value={values?.titulo3}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="madrina2" className="block text-sm font-medium text-gray-700 mb-1">
                        Madrina 2:
                      </label>
                      <Input
                        type="text"
                        id="madrina2"
                        name="madrina2"
                        value={values?.madrina2}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="padrino2" className="block text-sm font-medium text-gray-700 mb-1">
                        Padrino 2:
                      </label>
                      <Input
                        type="text"
                        id="padrino2"
                        name="padrino2"
                        value={values?.padrino2}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                  </div>
                </div>

                {/* Configuración General */}
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="text-lg font-medium text-gray-900 mb-4">Configuración General</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                      <label htmlFor="cantidad" className="block text-sm font-medium text-gray-700 mb-1">
                        Cantidad:
                      </label>
                      <Input
                        type="number"
                        id="cantidad"
                        name="cantidad"
                        value={values?.cantidad}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                      />
                    </div>
                    <div>
                      <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                        Color del Texto:
                      </label>
                      <Input
                        type="color"
                        id="textColor"
                        name="textColor"
                        value={values?.textColor}
                        onChange={handleCustomChange(handleChange, values)}
                        className="w-full h-10"
                      />
                    </div>
                    <div>
                      <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                    </div>
                    <div className="col-span-1 md:col-span-3">
                      <ImageFondo
                        label="Seleccione un fondo"
                        name="imgFondo"
                        onChange={handleCustomChange(handleChange, values)}
                        value={values?.imgFondo}
                      />
                      <Input
                        type="text"
                        id="imgFondo"
                        name="imgFondo"
                        value={values?.imgFondo}
                        onChange={handleCustomChange(handleChange, values)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end gap-4 mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      setDatosPadres(values);
                      setRefreshKey(prev => prev + 1);
                      if (isMobile) {
                        setShowMobilePreview(true);
                      }
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Guardar Padres
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          {!isMobile && <PreviewContainer>
            {datosPadres && Object.keys(datosPadres).length > 0 && (
              diseño === "2" ? (
                <PadresBodaComponent2
                  key={refreshKey}
                  datosSeccionPadres={datosPadres}
                  colores={colores}
                  isEnglish={false}
                  screenMobile={true}
                />
              ) : (
                <PadresBodaComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionPadres={datosPadres}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>}
          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {datosPadres && Object.keys(datosPadres).length > 0 && (
                diseño === "2" ? (
                  <PadresBodaComponent2
                    key={refreshKey}
                    datosSeccionPadres={datosPadres}
                    colores={colores}
                    isEnglish={false}
                    screenMobile={true}
                  />
                ) : (
                  <PadresBodaComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionPadres={datosPadres}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
}; 