import AOS from "aos";
import React, { useEffect, useState } from "react";
import FormattedText from "../functions/FormattedText";

export const ContadorXvComponent = ({ peltaColores, datosSeccionContador, screenMobile }) => {
  // Parse the target date as UTC
  const targetDate = new Date(datosSeccionContador.fechaNewDate).getTime();

  const calculateTimeRemaining = () => {
    const now = Date.now(); // Current time in milliseconds since epoch
    const difference = targetDate - now; // Difference in milliseconds

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const [estilos, setEstilos] = useState({ imagenArriba: "", imagenAbajo: "" });

  useEffect(() => {
    AOS.init({ duration: 2000 });
    setEstilos(getEstilos());

    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fontFamilyStyle = { fontFamily: datosSeccionContador.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  // Función para parsear los estilos
  const getEstilos = () => {
    try {
      const estilos = JSON.parse(datosSeccionContador.estilos || '{"imagenArriba":"","imagenAbajo":""}');
      console.log(estilos, datosSeccionContador.estilos);
      return estilos;
    } catch {
      return { imagenArriba: "", imagenAbajo: "" };
    }
  };


  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor || "",
      }}
      className={`flex flex-col items-center w-full px-2 py-5 ${screenMobile ? 'bg-cover h-auto' : 'sm:bg-cover md:bg-full sm:h-auto md:h-[120vh]'}`}
    >
      <div className="flex flex-col pt-[10vh] w-full items-center">
        <h1
          className={`font-serif ${screenMobile ? 'text-[52px]' : 'sm:text-[52px] md:text-[92px]'}`}
          data-aos={screenMobile ? '' : 'flip-up'}
          style={fontFamilyStyle}
        >
          <FormattedText text={datosSeccionContador?.titulo} />
        </h1>
        <br />
        <p
          className={`text-center pt-[5vh] font-serif ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-xl"} ${screenMobile ? "w-[80%]" : "sm:w-[80%] md:w-[60%]"}`}
          data-aos={screenMobile ? '' : 'flip-up'}
          style={fontFamilyStyle}
        >
          <FormattedText text={datosSeccionContador?.descipcion} />
        </p>
        <div className={`grid ${screenMobile ? 'grid-cols-1' : 'sm:grid-cols-1 md:grid-cols-3'} items-center w-[80%] pt-[8vh]`}>
          <div
            style={{
              backgroundImage: `url(${datosSeccionContador.fotoIzquierda})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: estilos.imagenArriba || "cover",
              height: "45vh",
              display: "flex",
              backgroundPosition: "center center",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="rounded-[30%]"
            data-aos={screenMobile ? '' : 'fade-right'}
          ></div>
          {datosSeccionContador.contador ? (
            <div
              className="grid grid-cols-4 justify-center items-center mt-10"
              data-aos={screenMobile ? '' : 'fade-up'}
              style={{
                border: "15px solid transparent",
                padding: "15px",
                borderImage: datosSeccionContador.marcoEnable
                  ? "url('https://www.pngall.com/wp-content/uploads/11/Golden-Frame-PNG.png')"
                  : "",
                borderImageSlice: "46",
                borderImageWidth: "round",
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              <div className="flex flex-col justify-center items-center text-center mx-2" >
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {timeRemaining.days}
                </p>
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {datosSeccionContador.ingles ? "Days" : "Días"}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center mx-2" >
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {timeRemaining.hours}
                </p>
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {datosSeccionContador.ingles ? "H" : "Hrs"}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center mx-2" >
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {timeRemaining.minutes}
                </p>
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {datosSeccionContador.ingles ? "M" : "Mins"}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center text-center mx-2" >
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {timeRemaining.seconds}
                </p>
                <p className={`font-serif  ${datosSeccionContador.tipoLetra == "Tangerine, cursive" ? "text-[4vh]" : "text-full"}`} style={{ ...fontFamilyStyle }}>
                  {datosSeccionContador.ingles ? "S" : "Segs"}
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div
            style={{
              backgroundImage: `url(${datosSeccionContador.fotoDerecha})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: estilos.imagenAbajo || "cover",
              height: "45vh",
              display: "flex",
              backgroundPosition: "center center",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={`rounded-[30%] mt-8 ${screenMobile ? 'ml-0' : 'sm:mt-8 md:mt-0 sm:ml-0 md:ml-8'}`}
            data-aos={screenMobile ? '' : 'fade-left'}
          ></div>
        </div>
      </div>
    </div>
  );
};
