import React, { useEffect } from "react";
import { useState } from "react";
import { CSSTransition } from 'react-transition-group';
import Aos from "aos";

const ModalCard = ({ isOpen, setIsOpen, datosSeccionPortada, ingles }) => {
  const [flapOpen, setFlapOpen] = useState(true);

  useEffect(() => {
    // Inicializa AOS
    Aos.init({ duration: 2000 });
  }, []);

  const toggleFlap = () => {
    setFlapOpen(!flapOpen);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <CSSTransition
        in={isOpen}
        timeout={2000}
        unmountOnExit
        appear
      >
        <div className="modal-co index-full container">
          <div
            className={`envelope-wrapper ${flapOpen ? "flap" : ""}`}
            onClick={toggleFlap}
          >
            <div className="envelope">
              <div className="letter" style={{backgroundImage: `url(${datosSeccionPortada.imgPortada || ""})`}}>
                <div className="text">
                  <button
                    className="ver-invitacion-btn"
                    onClick={closeModal}
                  >
                    {ingles?"Open Invitation":"Ver Invitación"}
                  </button>
                </div>
              </div>
            </div>
            <div className="heart"></div>
            <p className="texto-corazon">{datosSeccionPortada.esposa || ""} & {datosSeccionPortada.esposo || ""}</p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ModalCard;
