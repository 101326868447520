import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

export const LoadingEventos = () => {
  const [progress, setProgress] = useState(0);
  const controls = useAnimation();

  // Función para determinar si un icono debe estar iluminado
  const shouldGlow = (iconIndex) => {
    const threshold = 25 * iconIndex; // Cada icono se iluminará cada 25%
    return progress >= threshold;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prev + 1;
      });
    }, 50);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    controls.start({
      width: `${progress}%`,
      transition: { duration: 0.5 }
    });
  }, [progress, controls]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="flex gap-6 mb-12">
        <motion.div
          className={`w-12 h-12 bg-gradient-to-r from-[#D4AF37] to-[#FFD700] rounded-full shadow-sm transition-all duration-500
            ${shouldGlow(1) ? 'ring-2 ring-[#FFD700] ring-opacity-50 shadow-[#FFD700]/50' : 'opacity-50'}`}
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 360],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear"
          }}
        >
          <div className="w-full h-full flex items-center justify-center text-lg">
            💝
          </div>
        </motion.div>

        <motion.div
          className={`w-12 h-12 bg-gradient-to-r from-[#1a1a1a] to-[#333333] rounded-full shadow-sm transition-all duration-500
            ${shouldGlow(2) ? 'ring-2 ring-[#333333] ring-opacity-50 shadow-[#333333]/50' : 'opacity-50'}`}
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 360],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear",
            delay: 0.3
          }}
        >
          <div className="w-full h-full flex items-center justify-center text-lg">
            ✨
          </div>
        </motion.div>

        <motion.div
          className={`w-12 h-12 bg-gradient-to-r from-[#C0C0C0] to-white rounded-full shadow-sm transition-all duration-500
            ${shouldGlow(3) ? 'ring-2 ring-[#C0C0C0] ring-opacity-50 shadow-[#C0C0C0]/50' : 'opacity-50'}`}
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 360],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear",
            delay: 0.6
          }}
        >
          <div className="w-full h-full flex items-center justify-center text-lg">
            🌟
          </div>
        </motion.div>

        <motion.div
          className={`w-12 h-12 bg-gradient-to-r from-[#D4AF37] to-[#FFD700] rounded-full shadow-sm transition-all duration-500
            ${shouldGlow(4) ? 'ring-2 ring-[#FFD700] ring-opacity-50 shadow-[#FFD700]/50' : 'opacity-50'}`}
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 360],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear",
            delay: 0.9
          }}
        >
          <div className="w-full h-full flex items-center justify-center text-lg">
            🎀
          </div>
        </motion.div>
      </div>

      <motion.div
        className="flex flex-col items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h2 
          className="text-xl font-light text-[#1a1a1a] mb-6 tracking-wider"
          animate={{ opacity: [0.5, 1, 0.5] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          Loading {progress}%
        </motion.h2>
        <div className="w-48 h-[2px] bg-[#f5f5f5] rounded-full overflow-hidden">
          <motion.div
            className="h-full bg-[#D4AF37]"
            animate={controls}
            initial={{ width: "0%" }}
            style={{
              transformOrigin: "left"
            }}
          />
        </div>
      </motion.div>
    </div>
  );
}; 