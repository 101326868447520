import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Button } from "reactstrap";
import { ModalAgregarEvento } from "../Modals/ModalAgregarEvento";
import { ModalEditarEvento } from "../Modals/ModarEditarEvento";
import { ModalEditarImagenes } from "../Modals/ModalImages";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { validateToken } from "../../../components/validateToken";
import { AddHistorial } from "../../../components/functions/AddHistorial";
import { useApi } from "../../../hooks/useApi";
import { getHeaders, handleLogout } from "../../../components/auth/authHelpers";
import { FaShare, FaSearch, FaEye, FaEnvelope, FaUserFriends, FaEdit, FaRedo, FaImages, FaEllipsisV, FaPhotoVideo, FaTrash, FaCamere, FaTimes, FaCog } from 'react-icons/fa';
import { LoadingEventos } from '../../../components/LoadingEventos';
import { ModalGaleriaConfig } from '../Modals/ModalGaleriaConfig';
import { ModalEditarGaleria } from '../Modals/ModalEditarGaleria';

export const Eventos = () => {
  const { error, callApi } = useApi();
  const [datosEventos, setDatosEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [infoEdit, setInfoEdit] = useState(null);
  const [infoImages, setInfoImages] = useState(null);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalImagen, setModalImagen] = useState(false);
  const [modalAgregar, setModalAgregar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchIdEvento, setSearchIdEvento] = useState("");
  const [searchFechaFin, setSearchFechaFin] = useState("");
  const [searchVendedor, setSearchVendedor] = useState("");
  const [searchFechaInicio, setSearchFechaInicio] = useState("");
  const [searchFechaFinFiltro, setSearchFechaFinFiltro] = useState("");
  const [searchDiseño, setSearchDiseño] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(100);
  const [totalEvents, setTotalEvents] = useState(0);
  const [count, setCount] = useState(0);
  const [totalMonto, setTotalMonto] = useState(0);
  const [totalEsperado, setTotalEsperado] = useState(0);
  const [userName, setUserName] = useState("");
  const [admins, setAdmins] = useState([]);
  const [agreado, setAgreado] = useState(false);
  const [userId, setUserId] = useState(null);
  const toggleModalAgregar = () => setModalAgregar(true);
  const toggleModalDetalle = () => setModalDetalle(!modalDetalle);
  const [eventosMontoMenorQueTotal, setEventosMontoMenorQueTotal] = useState([]);
  const [filterFechaFin, setFilterFechaFin] = useState('');
  const [filterDiseño, setFilterDiseño] = useState('');
  const [openMenuId, setOpenMenuId] = useState(null);
  const [showGaleriaModal, setShowGaleriaModal] = useState(false);
  const [selectedEvento, setSelectedEvento] = useState(null);
  const [selectedEventoForGaleria, setSelectedEventoForGaleria] = useState(null);
  const [galeriaForm, setGaleriaForm] = useState({
    galeriaAdmin: {
      idAdmin: null,
      cantidadImagenes: 100,
      foto: '',
      nombre: '',
      idEvento: null,
      fecha: ''
    },
    seccionInvitados: {
      imgFondo: '',
      titulo: 'Invitados',
      entradasText: '',
      colorFondoName: '',
      eventoTitulo: '',
      idEvento: null,
      limiteAccesos: 0,
      tipoLetra: ''
    }
  });
  const [showEditGaleriaModal, setShowEditGaleriaModal] = useState(false);
  const [openGaleriaMenuId, setOpenGaleriaMenuId] = useState(null);

  const toggleModalEditar = (e) => {
    setInfoEdit(e);
    setModalEditar(true);
  };

  const toggleModalImages = async (id) => {
    const response = await axios.get(`/api/Images/FromEvent/${id}`);
    if (response.status !== 200) {
      toast.error("No se pudo obtener la información de las imágenes para este evento");
      return;
    }

    setInfoImages(response.data);
    setModalImagen(true);
  };

  const fetchData = async (queryParams) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/Eventos/filtrado`, {
        params: queryParams,
        headers: await getHeaders()
      });
      setDatosEventos(response.data);
      setTotalEvents(parseInt(response.headers["x-total-count"], 10));
      setCount(response.data.length);

      const totalMonto = response.data.reduce(
        (acc, evento) => acc + parseFloat(evento.monto || 0),
        0
      );
      setTotalMonto(totalMonto);

      const totalEsperado = response.data.reduce(
        (acc, evento) => acc + parseFloat(evento.total || 0),
        0
      );
      setTotalEsperado(totalEsperado);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error al cargar los datos");
    }
  };

  const debounceFetchData = useCallback(
    debounce((queryParams) => fetchData(queryParams), 1500),
    []
  );

  const fetchEventosMontoMenorQueTotal = async () => {
    try {
      const response = await axios.get("/api/Eventos/monto-menor-que-total", {
        headers: await getHeaders()
      });
      setEventosMontoMenorQueTotal(response.data);
    } catch (error) {
      console.error("Error fetching events with monto less than total", error);
    }
  };

  const resetForm = async (idEvento, tipoEvento) => {
    const confirmed = window.confirm(
      `¿Deseas resetear la información de la invitación ${idEvento}?`
    );

    if (confirmed) {
      try {
        toast.loading(`Reseteando formulario ${idEvento}`);
        await axios.delete(`/api/Eventos/${tipoEvento === "Boda" ? "boda/" : ""}${idEvento}`, {
          headers: await getHeaders()
        });
        debounceFetchData({
          nombre: searchTerm,
          idEvento: searchIdEvento,
          fechaFin: searchFechaFin,
          fechaInicio: searchFechaInicio,
          fechaFinFiltro: searchFechaFinFiltro,
          vendedor: searchVendedor,
          diseño: searchDiseño,
          pageNumber: pageNumber,
          pageSize: pageSize,
        });
        toast.dismiss();
        toast.success("Se ha reseteado correctamente");
      } catch (error) {
        console.error("Error al eliminar el invitado:", error);
        toast.error("Error al resetear");
      }
    }
  };

  const fetchAdmins = async () => {
    try {
      const responseAdmin = await callApi('get', '/Admins');
      setAdmins(responseAdmin);
    } catch (error) {
      console.error("Error al obtener administradores:", error);
    }
  };

  useEffect(() => {
    debounceFetchData({
      nombre: searchTerm,
      idEvento: searchIdEvento,
      fechaFin: searchFechaFin,
      fechaInicio: searchFechaInicio,
      fechaFinFiltro: searchFechaFinFiltro,
      vendedor: searchVendedor,
      diseño: searchDiseño,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  }, [
    searchTerm,
    searchIdEvento,
    searchFechaFin,
    searchFechaInicio,
    searchFechaFinFiltro,
    searchVendedor,
    searchDiseño,
    pageNumber,
    pageSize,
    debounceFetchData,
    agreado
  ]);

  useEffect(() => {
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    fetchAdmins();
    validateAndFetchData();
    fetchEventosMontoMenorQueTotal();
  }, []);

  const handleNextPage = () => {
    if ((pageNumber + 1) * pageSize < totalEvents) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const addAlbum = () => {
    setGaleriaForm(prev => ({
      ...prev,
      albums: [
        ...prev.albums,
        {
          nombre: '',
          posicion: prev.albums.length + 1,
          imagenPortada: '',
          enableAlbum: true
        }
      ]
    }));
  };

  const removeAlbum = (index) => {
    setGaleriaForm(prev => ({
      ...prev,
      albums: prev.albums.filter((_, i) => i !== index)
    }));
  };

  const handleCreateGaleria = async () => {
    if (!galeriaForm.galeriaAdmin.nombre) {
      toast.error('Por favor ingrese un nombre para la galería');
      return;
    }

    try {
      // Crear GaleriaEventoAdmin
      const galeriaAdminData = {
        cantidadImagenes: galeriaForm.galeriaAdmin.cantidadImagenes,
        foto: galeriaForm.galeriaAdmin.foto || '',
        nombre: galeriaForm.galeriaAdmin.nombre,
        idEvento: selectedEventoForGaleria.idEvento,
        fecha: galeriaForm.galeriaAdmin.fecha
      };

      const galeriaResponse = await axios.post('/api/GaleriaEventoAdmin', galeriaAdminData);
      const idAdmin = galeriaResponse.data.idAdmin;

      // Crear sección de invitados con el modelo correcto
      const seccionInvitadosData = {
        imgFondo: '',
        titulo: 'Invitados',
        entradasText: '',
        colorFondoName: '#FFFFFF', // Color de fondo por defecto
        eventoTitulo: selectedEventoForGaleria.nombre,
        idEvento: selectedEventoForGaleria.idEvento,
        limiteAccesos: galeriaForm.seccionInvitados.limiteAccesos || 0,
        tipoLetra: 'Arial' // Tipo de letra por defecto
      };

      await axios.post('/api/Seccioninvitado', seccionInvitadosData);

      // Crear álbumes por defecto
      const albumesDefault = [
        {
          posicion: 1,
          nombre: 'Ceremonia',
          idEvento: selectedEventoForGaleria.idEvento,
          idAdmin: idAdmin,
          imagenPortada: ''
        },
        {
          posicion: 2,
          nombre: 'Recepción',
          idEvento: selectedEventoForGaleria.idEvento,
          idAdmin: idAdmin,
          imagenPortada: ''
        }
      ];

      for (const album of albumesDefault) {
        await axios.post('/api/Album', album);
      }

      setShowGaleriaModal(false);
      toast.success('Galería creada exitosamente');
    } catch (error) {
      console.error('Error al crear la galería:', error);
      toast.error('Error al crear la galería: ' + (error.response?.data || error.message));
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-2 sm:p-4 md:p-6 lg:p-8">
      {loading ? (
        <LoadingEventos />
      ) : (
        <div className="max-w-full mx-auto bg-white rounded-lg shadow-lg p-4 sm:p-6 md:p-8">
          {userName && (
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">
              ¡Excelente día, {userName}! 🌟
            </h1>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <button
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              onClick={toggleModalAgregar}
            >
              Agregar Evento
            </button>
            <input
              type="text"
              placeholder="Buscar por nombre..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Filtrar por ID de Evento..."
              value={searchIdEvento}
              onChange={(e) => setSearchIdEvento(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <select
              value={searchFechaFin}
              onChange={(e) => setSearchFechaFin(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Estatus...</option>
              <option value="Nada">Nada</option>
              <option value="Formulario">Formulario</option>
              <option value="Entregado">Entregado</option>
              <option value="Formateado">Elaborada</option>
              <option value="Test">Test</option>
              <option value="Ejemplos">Ejemplos</option>
            </select>
            <input
              type="date"
              placeholder="Filtrar por Fecha de Inicio..."
              value={searchFechaInicio}
              onChange={(e) => setSearchFechaInicio(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={searchVendedor}
              onChange={(e) => setSearchVendedor(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Vendedor</option>
              {Array.isArray(admins) && admins.length > 0 ? (
                admins.map((item) => (
                  <option key={item.nombre} value={item.nombre}>
                    {item.nombre}
                  </option>
                ))
              ) : (
                <option disabled>Cargando...</option>
              )}
            </select>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <select
              value={searchDiseño}
              onChange={(e) => setSearchDiseño(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Diseño</option>
              <option value="1">Diseño Clasico</option>
              <option value="2">Diseño Vip</option>
            </select>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mb-6 text-center">
            <p className="font-semibold bg-blue-100 p-2 rounded-lg">Total de Eventos: <span className="text-blue-600">{count}</span></p>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Id</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Nombre</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Comprador</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Tipo</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Paquete</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Fecha</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Monto</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Detalles</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Estatus</th>
                  <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider">Acciones</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {datosEventos.map((evento) => {
                  let rowColorClass = '';

                  switch (evento.paquete) {
                    case 'Plata':
                      rowColorClass = 'bg-slate-50 hover:bg-slate-100';
                      break;
                    case 'Oro':
                      rowColorClass = 'bg-amber-50 hover:bg-amber-100';
                      break;
                    case 'Diamante':
                      rowColorClass = 'bg-cyan-50 hover:bg-cyan-100';
                      break;
                    case 'Premium':
                      rowColorClass = 'bg-violet-50 hover:bg-violet-100';
                      break;
                    default:
                      rowColorClass = 'hover:bg-gray-50';
                  }

                  if (evento.enableInvitados) {
                    rowColorClass += ' border-l-4 border-green-400';
                  }

                  if (parseFloat(evento.monto) < parseFloat(evento.total)) {
                    rowColorClass += ' border-r-4 border-red-400';
                  }

                  if (evento.ingles) {
                    rowColorClass += ' border-t border-blue-300';
                  }

                  return (
                    <tr
                      key={evento.idEvento}
                      className={`${rowColorClass} transition-colors duration-150`}
                    >
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        {evento.idEvento.toString()}
                        <AddHistorial evento={evento.idEvento.toString()} nombre={userName} />
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <div className="flex flex-col">
                          <span>{evento.nombre}</span>
                          {evento.ingles && (
                            <span className="text-[10px] text-blue-500">English</span>
                          )}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <div className="flex flex-col">
                          <span>{evento.nombreComprador}</span>
                          <span className="text-gray-500">{evento.numComprador}</span>
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <span className={`px-2 py-0.5 rounded-full ${evento.tipoEvento === "Boda"
                          ? "bg-blue-100 text-blue-800"
                          : "bg-purple-100 text-purple-800"
                          }`}>
                          {evento.tipoEvento}
                        </span>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <div className="flex flex-col">
                          <span className={`font-medium ${evento.paquete === "Diamante"
                            ? "text-cyan-600"
                            : evento.paquete === "Oro"
                              ? "text-amber-600"
                              : evento.paquete === "Premium"
                                ? "text-violet-600"
                                : "text-slate-600"
                            }`}>
                            {evento.paquete}
                          </span>
                          <span className="text-[10px] text-gray-500">
                            {evento.diseño === "2" ? "Diseño Vip" : "Clásico"}
                          </span>
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <div className="flex flex-col">
                          <span>{evento.fechaInicio}</span>
                          <span className="text-gray-500">{evento.vendedor}</span>
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <div className="flex flex-col">
                          <div className={`${parseFloat(evento.monto) < parseFloat(evento.total)
                            ? "text-red-600 font-medium"
                            : "text-green-600 font-medium"
                            }`}>
                            ${evento.monto} / ${evento.total}
                          </div>
                          {parseFloat(evento.monto) < parseFloat(evento.total) && (
                            <div className="text-[10px] text-red-500">
                              Resta: ${(parseFloat(evento.total) - parseFloat(evento.monto)).toFixed(2)}
                            </div>
                          )}
                          {parseFloat(evento.monto) >= parseFloat(evento.total) && (
                            <div className="text-[10px] text-green-500">
                              Pagado
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-2 py-2 text-xs">
                        {evento.detalles && evento.detalles.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${evento.fechaFin === "Nada"
                            ? "bg-red-100 text-red-800"
                            : evento.fechaFin === "Formulario"
                              ? "bg-blue-100 text-blue-800"
                              : evento.fechaFin === "Formateado"
                                ? "bg-purple-100 text-purple-800"
                                : evento.fechaFin === "Test"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : evento.fechaFin === "Ejemplos"
                                    ? "bg-yellow-100 text-yellow-800"
                                    : evento.fechaFin === "Entregado"
                                      ? "bg-green-100 text-green-800"
                                      : ""
                            }`}
                        >
                          {evento.fechaFin === "Formateado" ? "Elaborada" : evento.fechaFin}
                        </span>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-sm font-medium">
                        <div className="relative">
                          <button
                            onClick={() => setOpenMenuId(openMenuId === evento.idEvento ? null : evento.idEvento)}
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                          >
                            <FaEllipsisV />
                          </button>

                          {openMenuId === evento.idEvento && (
                            <div
                              className="fixed z-[1000] w-48 bg-gray-100 rounded-lg shadow-lg py-1 text-sm"
                              style={{
                                left: `${window.event.clientX}px`,
                                top: `${window.event.clientY}px`,
                                transform: 'translate(-90%, 0)'
                              }}
                              onMouseLeave={() => setOpenMenuId(null)}
                            >
                              {!evento.enableInvitados && (
                                <a
                                  href={`/compartir-invitacion?id=${evento.idEvento}`}
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaShare className="mr-3 text-blue-500" />
                                  <span>Compartir Invitación</span>
                                </a>
                              )}

                              <a
                                href={evento.tipoEvento === "Boda" ? `/admin-boda-detalles?id=${evento.idEvento}` : `/admin-xv-detalles?id=${evento.idEvento}`}
                                className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaSearch className="mr-3 text-indigo-500" />
                                <span>Ver Detalles</span>
                              </a>

                              {evento.paquete !== "Diamante" && !evento.enableInvitados && (
                                <a
                                  href={evento.tipoEvento === "Boda"
                                    ? evento.ingles ? `/en/wedding-auto?id=${evento.idEvento}` : `/share/2/${evento.idEvento}`
                                    : evento.ingles ? `/en/event-auto?id=${evento.idEvento}` : `/share/2/${evento.idEvento}`
                                  }
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaEye className="mr-3 text-green-500" />
                                  <span>Vista Previa</span>
                                </a>
                              )}

                              <a
                                href={evento.tipoEvento === "Boda"
                                  ? evento.ingles ? `/form-wedding?id=${evento.idEvento}`
                                    : evento.diseño === "2" ? `/formulario-evento?id=${evento.idEvento}`
                                      : `/formulario-boda?id=${evento.idEvento}`
                                  : evento.ingles ? `/form-event?id=${evento.idEvento}`
                                    : evento.diseño === "2" ? `/formulario-evento-invitado?id=${evento.idEvento}`
                                      : `/formulario-xv?id=${evento.idEvento}`
                                }
                                className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaEnvelope className="mr-3 text-yellow-500" />
                                <span>Formulario</span>
                              </a>

                              {(evento.paquete === "Diamante" || evento.enableInvitados) && (
                                <a
                                  href={evento.ingles
                                    ? `/guests-generate?id=${evento.idEvento}`
                                    : evento.diseño === "2" ? `/2/invitados-generate?id=${evento.idEvento}`
                                      : `/invitados-generate?id=${evento.idEvento}`
                                  }
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaUserFriends className="mr-3 text-purple-500" />
                                  <span>Generar Invitados</span>
                                </a>
                              )}

                              <button
                                onClick={() => {
                                  toggleModalEditar(evento);
                                  setOpenMenuId(null);
                                }}
                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                              >
                                <FaEdit className="mr-3 text-gray-500" />
                                <span>Editar</span>
                              </button>

                              <button
                                onClick={() => {
                                  resetForm(evento.idEvento, evento.tipoEvento);
                                  setOpenMenuId(null);
                                }}
                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                              >
                                <FaRedo className="mr-3 text-red-500" />
                                <span>Resetear</span>
                              </button>

                              <button
                                onClick={() => {
                                  toggleModalImages(evento.idEvento);
                                  setOpenMenuId(null);
                                }}
                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                              >
                                <FaImages className="mr-3 text-pink-500" />
                                <span>Gestionar Imágenes</span>
                              </button>

                              <a
                                href={`/img-event/${evento.idEvento}`}
                                className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaPhotoVideo className="mr-3 text-indigo-500" />
                                <span>Galería de Fotos</span>
                              </a>

                              <button
                                onClick={() => {
                                  setSelectedEventoForGaleria(evento);
                                  setShowGaleriaModal(true);
                                  setOpenMenuId(null);
                                }}
                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                              >
                                <FaImages className="mr-3 text-green-500" />
                                <span>Crear Galería</span>
                              </button>

                              <button
                                onClick={() => {
                                  setSelectedEventoForGaleria(evento);
                                  setShowEditGaleriaModal(true);
                                  setOpenMenuId(null);
                                }}
                                className="w-full flex items-center px-4 py-2 hover:bg-gray-100 text-gray-700"
                              >
                                <FaCog className="mr-3 text-gray-500" />
                                <span>Editar Galería</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-6">
            <button
              disabled={pageNumber === 0}
              onClick={handlePreviousPage}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
            >
              Anterior
            </button>
            <button
              disabled={(pageNumber + 1) * pageSize >= totalEvents}
              onClick={handleNextPage}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
            >
              Siguiente
            </button>
          </div>

          <div className="mt-6 text-center">
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}

      <div className="bg-white p-4 md:p-6 rounded-lg shadow mt-8">
        <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Eventos con Monto Menor que Total</h2>
        <div className="mb-4">
          <label htmlFor="filterFechaFin" className="block text-gray-700">Filtrar por FechaFin:</label>
          <select
            id="filterFechaFin"
            className="border rounded p-2 w-full"
            onChange={(e) => setFilterFechaFin(e.target.value)}
          >
            <option value="">Mostrar todos</option>
            <option value="Formateado">Elaborada</option>
            <option value="Formulario">Formulario</option>
            <option value="Nada">Nada</option>
            <option value="Entregado">Entregado</option>
          </select>
        </div>
        <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2 text-left">Id</th>
                <th className="border p-2 text-left">Nombre</th>
                <th className="border p-2 text-left">Num Comprador</th>
                <th className="border p-2 text-left">Tipo Evento</th>
                <th className="border p-2 text-left">Paquete</th>
                <th className="border p-2 text-left">Fecha Inicio</th>
                <th className="border p-2 text-left">Fecha Fin</th>
                <th className="border p-2 text-left">Monto</th>
                <th className="border p-2 text-left">Detalles</th>
                <th className="border p-2 text-left">Total</th>
                <th className="border p-2 text-left">Socio</th>
                <th className="border p-2 text-left">Enable Evento</th>
              </tr>
            </thead>
            <tbody>
              {eventosMontoMenorQueTotal
                .filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin)
                .map((evento, index) => {
                  let rowColor = '';
                  switch (evento.fechaFin) {
                    case 'Formateado':
                      rowColor = 'bg-blue-100';
                      break;
                    case 'Formulario':
                      rowColor = 'bg-yellow-100';
                      break;
                    case 'Nada':
                      rowColor = 'bg-red-100';
                      break;
                    case 'Entregado':
                      rowColor = 'bg-green-100';
                      break;
                    default:
                      rowColor = '';
                  }
                  return (
                    <tr key={index} className={rowColor}>
                      <td className="border p-2">{evento.idEvento}</td>
                      <td className="border p-2">{evento.nombre}</td>
                      <td className="border p-2">{evento.numComprador}</td>
                      <td className="border p-2">{evento.tipoEvento}</td>
                      <td className="border p-2">{evento.paquete}</td>
                      <td className="border p-2">{evento.fechaInicio}</td>
                      <td className="border p-2">{evento.fechaFin}</td>
                      <td className="border p-2">{evento.monto}</td>
                      <td className="border p-2">{evento.detalles}</td>
                      <td className="border p-2">{evento.total}</td>
                      <td className="border p-2">{evento.socio}</td>
                      <td className="border p-2">{evento.enableEvento ? 'Sí' : 'No'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <p className="mt-4 font-semibold">
          Total de eventos: {eventosMontoMenorQueTotal.filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin).length}
        </p>
      </div>

      <ModalAgregarEvento
        modalAgregar={modalAgregar}
        setModalAgregar={setModalAgregar}
        admin={userName}
        agreado={agreado}
        setAgreado={setAgreado}
      />

      {modalEditar && (
        <ModalEditarEvento
          modalEditar={modalEditar}
          setModalEditar={setModalEditar}
          infoEdit={infoEdit}
          agreado={agreado}
          setAgreado={setAgreado}
        />
      )}

      {modalImagen && (
        <ModalEditarImagenes
          modalImages={modalImagen}
          setModalImages={setModalImagen}
          infoImages={infoImages}
          edit={true}
        />
      )}

      <ModalGaleriaConfig 
        showModal={showGaleriaModal}
        setShowModal={setShowGaleriaModal}
        galeriaForm={galeriaForm}
        setGaleriaForm={setGaleriaForm}
        selectedEventoForGaleria={selectedEventoForGaleria}
      />

      <ModalEditarGaleria 
        showModal={showEditGaleriaModal}
        setShowModal={setShowEditGaleriaModal}
        selectedEventoForGaleria={selectedEventoForGaleria}
      />
    </div>
  );
};
