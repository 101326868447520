import React from 'react';
import { motion } from 'framer-motion';
import { FaHeart, FaComment, FaCamera } from 'react-icons/fa';

export const ImageGalleryView = ({ 
  images, 
  albums, 
  selectedAlbum,
  onImageClick,
  onAlbumSelect,
  getLikesCount,
  setImages,
  allImages
}) => {
  // Función para obtener la primera imagen de un álbum
  const getFirstImageFromAlbum = (albumId) => {
    return allImages?.find(img => img.idAlbum === albumId)?.urlImg;
  };

  return (
    <div>
      {/* Sección de álbumes deslizable */}
      <div className="px-2 py-2">
        <div className="overflow-x-auto scrollbar-hide">
          <div className="flex gap-2 min-w-max">
            {/* Opción "Todas las fotos" */}
            <button
              onClick={() => onAlbumSelect(null)}
              className={`flex flex-col w-[30vh] relative ${
                !selectedAlbum ? 'opacity-100' : 'opacity-70'
              }`}
            >
              <div className={`aspect-square rounded-lg overflow-hidden mb-1 ${
                !selectedAlbum ? 'ring-2 ring-[#D4AF37]' : ''
              }`}>
                {allImages?.length > 0 ? (
                  <img
                    src={allImages[0]?.urlImg}
                    alt="Todas las fotos"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                    <FaCamera className="text-xl text-gray-400" />
                  </div>
                )}
              </div>
              <span className="text-xs text-center text-gray-700 font-medium truncate">
                Todas las fotos
              </span>
            </button>

            {/* Álbumes */}
            {albums.map(album => (
              <button
                key={album.idAlbum}
                onClick={() => onAlbumSelect(album)}
                className={`flex flex-col w-[30vh] relative ${
                  selectedAlbum?.idAlbum === album.idAlbum ? 'opacity-100' : 'opacity-70'
                }`}
              >
                <div className={`aspect-square rounded-lg overflow-hidden mb-1 ${
                  selectedAlbum?.idAlbum === album.idAlbum ? 'ring-2 ring-[#D4AF37]' : ''
                }`}>
                  {album.imagenPortada || getFirstImageFromAlbum(album.idAlbum) ? (
                    <img
                      src={album.imagenPortada || getFirstImageFromAlbum(album.idAlbum)}
                      alt={album.nombre}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                      <FaCamera className="text-xl text-gray-400" />
                    </div>
                  )}
                </div>
                <span className="text-xs text-center text-gray-700 font-medium truncate">
                  {album.nombre}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Grid de fotos */}
      <div className="grid grid-cols-3 p-2 gap-[2px]">
        {images.map((image, index) => (
          <motion.div
            key={image.idImagenesEvento}
            className="relative aspect-square"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.05 }}
            onClick={() => onImageClick(image)}
          >
            <img
              src={image.urlImg}
              alt={`Imagen ${image.idImagenesEvento}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/20 opacity-0 hover:opacity-100 transition-opacity duration-200 flex items-center justify-center">
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-1">
                  <FaHeart className="text-white" />
                  <span className="text-white">{image.likes}</span>
                </div>
                <div className="flex items-center gap-1">
                  <FaComment className="text-white" />
                  <span className="text-white">{image.comments?.length || 0}</span>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Estilos para ocultar scrollbar */}
      <style jsx>{`
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </div>
  );
}; 