import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { SelectIcons } from '../../../components/functions/SelectIcons';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { VestimentaXvComponent } from '../../../components/ComponentsXv/VestimentaXvComponent';
import { VestimentaBodaComponent2 } from '../../../components/ComponentsBoda2/VestimentaBodaComponent2';

export const VestimentaForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (initialValues) {
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Vestimenta</h3>
        <div className='grid grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label htmlFor="vestimenta" className="block text-sm font-medium text-gray-700 mb-1">
                      Vestimenta:
                    </label>
                    <Input
                      type="text"
                      id="vestimenta"
                      name="vestimenta"
                      value={values?.vestimenta}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="messageVestimenta" className="block text-sm font-medium text-gray-700 mb-1">
                      Mensaje sobre la vestimenta:
                    </label>
                    <Input
                      type="text"
                      id="messageVestimenta"
                      name="messageVestimenta"
                      value={values?.messageVestimenta}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                      Imagen de fondo:
                    </label>
                    <Input
                      type="text"
                      id="imgFondo"
                      name="imgFondo"
                      value={values?.imgFondo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                      Imagen de fondo:
                    </label>
                    <ImageFondo
                      label="Seleccione un fondo"
                      name="imgFondo"
                      onChange={handleCustomChange(handleChange, values)}
                      value={values?.imgFondo}
                      className={`w-full p-2.5 border border-gray-300 rounded-md ${values?.imgFondo ? "input-error" : ""}`}
                    />
                  </div>
                  <div>
                    <SelectIcons
                      label="Selecciona un icono para la vestimenta hombre"
                      name="urlVestimentaHombre"
                      onChange={handleCustomChange(handleChange, values)}
                      value={values?.urlVestimentaHombre}
                      className={`w-full p-2.5 border border-gray-300 rounded-md`}
                    />
                  </div>
                  <div>
                    <SelectIcons
                      label="Selecciona un icono para la vestimenta mujer"
                      name="urlVestimentaMujer"
                      onChange={handleCustomChange(handleChange, values)}
                      value={values?.urlVestimentaMujer}
                      className={`w-full p-2.5 border border-gray-300 rounded-md`}
                    />
                  </div>
                  <div>
                    <label htmlFor="textoHombre" className="block text-sm font-medium text-gray-700 mb-1">
                      Texto para Hombre:
                    </label>
                    <Input
                      type="text"
                      id="textoHombre"
                      name="textoHombre"
                      value={values?.textoHombre}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="textoMujer" className="block text-sm font-medium text-gray-700 mb-1">
                      Texto para Mujer:
                    </label>
                    <Input
                      type="text"
                      id="textoMujer"
                      name="textoMujer"
                      value={values?.textoMujer}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="colores" className="block text-sm font-medium text-gray-700 mb-1">
                      Colores:
                    </label>
                    <div className="flex items-center space-x-4 mb-4">
                      <button
                        type="button"
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'colores',
                              value: JSON.stringify({
                                color1: "#FFFFFF",
                                color2: "#4A3728",
                                color3: "#D2B48C",
                                color4: "#1A1A1A"
                              })
                            }
                          });
                        }}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      >
                        Habilitar Colores
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'colores',
                              value: null
                            }
                          });
                        }}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Restablecer Colores
                      </button>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="color1" className="block text-xs font-medium text-gray-600">
                          Color 1:
                        </label>
                        <Input
                          type="color"
                          id="color1"
                          name="color1"
                          disabled={!values?.colores}
                          value={values?.colores ? JSON.parse(values.colores).color1 : "#FFFFFF"}
                          onChange={(e) => {
                            const currentColors = values?.colores ? JSON.parse(values.colores) : {
                              color1: "#FFFFFF",
                              color2: "#4A3728",
                              color3: "#D2B48C",
                              color4: "#1A1A1A"
                            };
                            handleChange({
                              target: {
                                name: 'colores',
                                value: JSON.stringify({
                                  ...currentColors,
                                  color1: e.target.value
                                })
                              }
                            });
                          }}
                          className="mt-1 block w-full h-8"
                        />
                      </div>
                      <div>
                        <label htmlFor="color2" className="block text-xs font-medium text-gray-600">
                          Color 2:
                        </label>
                        <Input
                          type="color"
                          id="color2"
                          name="color2"
                          disabled={!values?.colores}
                          value={values?.colores ? JSON.parse(values.colores).color2 : "#FFFFFF"}
                          onChange={(e) => {
                            const currentColors = values?.colores ? JSON.parse(values.colores) : {
                              color1: "#FFFFFF",
                              color2: "#4A3728",
                              color3: "#D2B48C",
                              color4: "#1A1A1A"
                            };
                            handleChange({
                              target: {
                                name: 'colores',
                                value: JSON.stringify({
                                  ...currentColors,
                                  color2: e.target.value
                                })
                              }
                            });
                          }}
                          className="mt-1 block w-full h-8"
                        />
                      </div>
                      <div>
                        <label htmlFor="color3" className="block text-xs font-medium text-gray-600">
                          Color 3:
                        </label>
                        <Input
                          type="color"
                          id="color3"
                          name="color3"
                          disabled={!values?.colores}
                          value={values?.colores ? JSON.parse(values.colores).color3 : "#FFFFFF"}
                          onChange={(e) => {
                            const currentColors = values?.colores ? JSON.parse(values.colores) : {
                              color1: "#FFFFFF",
                              color2: "#4A3728",
                              color3: "#D2B48C",
                              color4: "#1A1A1A"
                            };
                            handleChange({
                              target: {
                                name: 'colores',
                                value: JSON.stringify({
                                  ...currentColors,
                                  color3: e.target.value
                                })
                              }
                            });
                          }}
                          className="mt-1 block w-full h-8"
                        />
                      </div>
                      <div>
                        <label htmlFor="color4" className="block text-xs font-medium text-gray-600">
                          Color 4:
                        </label>
                        <Input
                          type="color"
                          id="color4"
                          name="color4"
                          disabled={!values?.colores}
                          value={values?.colores ? JSON.parse(values.colores).color4 : "#FFFFFF"}
                          onChange={(e) => {
                            const currentColors = values?.colores ? JSON.parse(values.colores) : {
                              color1: "#FFFFFF",
                              color2: "#4A3728",
                              color3: "#D2B48C",
                              color4: "#1A1A1A"
                            };
                            handleChange({
                              target: {
                                name: 'colores',
                                value: JSON.stringify({
                                  ...currentColors,
                                  color4: e.target.value
                                })
                              }
                            });
                          }}
                          className="mt-1 block w-full h-8"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                      Color de texto:
                    </label>
                    <Input
                      type="text"
                      id="textColor"
                      name="textColor"
                      value={values?.textColor}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentValues(values);
                      setRefreshKey(prev => prev + 1);
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Guardar Vestimenta
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <VestimentaBodaComponent2
                  key={refreshKey}
                  datosSeccionVestimenta={currentValues}
                  colores={colores}
                  isEnglish={false}
                />
              ) : (
                <VestimentaXvComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionVestimenta={currentValues}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>
        </div>
      </div>
    </div>
  );
}; 