import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input } from "reactstrap";// Reemplaza con tu componente Input si tienes uno personalizado
import SelectTipoLetra from "../../../components/functions/SelectTipoLetra"; // Reemplaza con tu componente de selección
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';

export const InstagramForm = ({ dataInsta, actualizarInstagram }) => {
  return  (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Instagram Post Configuración
        </h2>
        <Formik
          initialValues={{
            idInstagram: dataInsta?.idInstagram || "",
            titulo: dataInsta?.titulo || "",
            descripcion: dataInsta?.descripcion || "",
            hashtag: dataInsta?.hashtag || "",
            imgFondo: dataInsta?.imgFondo || "",
            textColor: dataInsta?.textColor || "",
            idEvento: dataInsta?.idEvento || "",
            tipoLetra: dataInsta?.tipoLetra || "",
          }}
          onSubmit={(values) => {
            console.log("Formulario de Instagram enviado:", values);
            actualizarInstagram(values);
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="titulo"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Título:
                  </label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={values.titulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="titulo"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div>
                  <label
                    htmlFor="descripcion"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Descripción:
                  </label>
                  <Input
                    type="text"
                    id="descripcion"
                    name="descripcion"
                    value={values.descripcion}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="descripcion"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div>
                  <label
                    htmlFor="hashtag"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Hashtag:
                  </label>
                  <Input
                    type="text"
                    id="hashtag"
                    name="hashtag"
                    value={values.hashtag}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="hashtag"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div>
                  <label
                    htmlFor="imgFondo"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      values?.imgFondo ? "input-error" : ""
                    }`}
                  />
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="textColor"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Color de texto:
                  </label>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor || "#000000"}
                    onChange={handleChange}
                    className="mt-1 block w-full h-10"
                  />
                </div>
                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Guardar Configuración
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
};

