import React, { useState, useEffect } from "react";
import { validateToken } from "../../../components/validateToken";
import VentasDiarias from "./components/VentasDiarias";
import VentasVendedores from "./components/VentasVendedores";
import VentasMensuales from "./components/VentasMensuales";
import VentasQuincenales from "./components/VentasQuincenales";
import EventosMontoMenor from "./components/EventosMontoMenor";
import HistorialSemanal from "./components/HistorialSemanal";
import EventosDiferenciaMonto from "./components/EventosDiferenciaMonto";
import { getWeekNumber } from "./utils/dateUtils";
import axios from "axios";
import { getHeaders } from "../../../components/auth/authHelpers";
import toast from "react-hot-toast";
import ImageMigration from "./components/ImageMigration";
// ... otros imports

const Dashboard = () => {
  const [userName, setUserName] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedWeek, setSelectedWeek] = useState(getWeekNumber(new Date()));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('ventas-diarias');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [ventasHoy, setVentasHoy] = useState({
    total: 0,
    cantidad: 0
  });

  useEffect(() => {
    const validateAndSetUser = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    validateAndSetUser();
  }, []);

  useEffect(() => {
    const fetchVentasHoy = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-diarias", {
            params: { year: selectedYear, month: new Date().getMonth() + 1 },
          });
          const data = response.data;
          const today = new Date();
          const diaActual = today.getDate();
          const ventasDelDia = data.find(d => d.dia === diaActual);
          if (ventasDelDia) {
            setVentasHoy({
              total: ventasDelDia.totalTotales,
              cantidad: ventasDelDia.cantidadEventos
            });
          }
        } catch (error) {
          console.error("Error fetching today's sales", error);
        }
    };

    fetchVentasHoy();
  }, [selectedYear]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setIsSidebarOpen(false);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'ventas-diarias':
        return <VentasDiarias selectedMonth={selectedMonth} selectedYear={selectedYear} />;
      case 'ventas-vendedores':
        return <VentasVendedores selectedWeek={selectedWeek} selectedYear={selectedYear} />;
      case 'ventas-mensuales':
        return <VentasMensuales selectedYear={selectedYear} />;
      case 'ventas-quincenales':
        return <VentasQuincenales selectedYear={selectedYear} />;
      case 'eventos-monto-menor':
        return <EventosMontoMenor startDate={startDate} endDate={endDate} selectedYear={selectedYear} />;
      case 'historial-semanal':
        return <HistorialSemanal selectedWeek={selectedWeek} selectedYear={selectedYear} />;
      case 'eventos-diferencia':
        return <EventosDiferenciaMonto />;
      default:
        return <VentasDiarias selectedMonth={selectedMonth} selectedYear={selectedYear} />;
    }
  };

  const getWeekDates = (weekNumber) => {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    
    // Ajustar al primer día de la semana (lunes)
    const dayOffset = firstDayOfYear.getDay() === 0 ? 6 : firstDayOfYear.getDay() - 1;
    firstDayOfYear.setDate(firstDayOfYear.getDate() - dayOffset);
    
    // Calcular el inicio de la semana seleccionada
    const startDate = new Date(firstDayOfYear);
    startDate.setDate(startDate.getDate() + ((weekNumber - 1) * 7));
    
    // Calcular el fin de la semana
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    
    // Formatear las fechas
    const formatDate = (date) => {
      return date.toLocaleDateString('es-MX', {
        day: 'numeric',
        month: 'long'
      });
    };
    
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleDownloadExcel = async () => {
    try {
      toast.loading('Descargando eventos...');
      const response = await axios.get("/api/Eventos/download-excel", {
        headers: await getHeaders(),
        responseType: 'blob'
      });

      // Crear un objeto URL para el blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'eventos.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.dismiss();
      toast.success('Archivo Excel descargado correctamente');
    } catch (error) {
      toast.dismiss();
      console.error('Error al descargar el archivo Excel:', error);
      if (error.response?.status === 401) {
        toast.error('Sesión expirada, por favor inicie sesión nuevamente');
      } else {
        toast.error('Error al descargar el archivo Excel');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gradient-to-br from-gray-50 to-gray-200">
      {/* Botón de menú para móvil */}
      <button 
        className="fixed top-4 left-4 z-50 md:hidden bg-blue-600 text-white p-2 rounded-lg shadow-lg"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d={isSidebarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
          />
        </svg>
      </button>

      {/* Sidebar */}
      <aside className={`w-full md:w-[20%] bg-gradient-to-b from-blue-900 via-blue-800 to-blue-700 text-white ${
        isSidebarOpen ? 'fixed inset-0 z-40' : 'hidden'
      } md:block md:sticky md:top-0 md:h-screen overflow-y-auto`}>
        {/* Header del sidebar */}
        <div className="bg-blue-900 border-b border-blue-700">
        <div className="p-6 text-2xl font-bold border-b border-blue-600 flex items-center space-x-3">
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span>DIGITAL RSVP</span>
        </div>
        {userName && (
          <div className="p-4 text-center font-medium bg-blue-800 border-b border-blue-700">
            <div className="text-lg">EXCELENTE DÍA</div>
            <div className="text-xl font-bold mt-1">{userName} 🌟</div>
          </div>
        )}
          {/* Ventas de Hoy */}
          <div className="p-4 bg-blue-700 border-b border-blue-600">
            <div className="text-center">
              <h3 className="text-lg font-semibold text-blue-100">Ventas de Hoy</h3>
              <p className="text-2xl font-bold text-white mt-1">
                ${ventasHoy.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </p>
              <p className="text-sm text-blue-200 mt-1">
                {ventasHoy.cantidad} eventos
              </p>
            </div>
          </div>
        </div>

        {/* Menú de navegación */}
        <nav className="py-4">
          <ul className="space-y-2 px-2">
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('ventas-diarias')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'ventas-diarias' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                  <span>Ventas Diarias</span>
                </div>
              </button>
            </li>
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('ventas-vendedores')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'ventas-vendedores' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  <span>Ventas por Vendedor</span>
                </div>
              </button>
            </li>
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('ventas-mensuales')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'ventas-mensuales' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span>Ventas Mensuales</span>
                </div>
              </button>
            </li>
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('ventas-quincenales')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'ventas-quincenales' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                  <span>Ventas Quincenales</span>
                </div>
              </button>
            </li>
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('eventos-monto-menor')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'eventos-monto-menor' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Eventos Monto Menor</span>
                </div>
              </button>
            </li>
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('historial-semanal')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'historial-semanal' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Historial Semanal</span>
                </div>
                </button>
              </li>
            <li className="mx-2">
              <button 
                onClick={() => handleSectionChange('eventos-diferencia')}
                className={`w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 ${activeSection === 'eventos-diferencia' ? 'bg-blue-600' : ''}`}
              >
                <div className="flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                  <span>Diferencia Montos</span>
                </div>
              </button>
            </li>
            <li className="mx-2">
              <a href="/admins-control" className="block">
                <button className="w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 flex items-center space-x-3">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path className="text-white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span className="text-white">Administrar Usuarios</span>
                </button>
              </a>
            </li>
            <li className="mx-2">
              <button 
                onClick={handleDownloadExcel}
                className="w-full p-4 text-left hover:bg-blue-600 rounded-lg transition duration-300 flex items-center space-x-3"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span>Descargar Excel</span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Overlay para cerrar el sidebar en móvil */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Main Content */}
      <main className="flex-grow p-6 md:w-[80%] md:p-8 mt-16 md:mt-0 space-y-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-4xl font-bold text-gray-800">
            Dashboard de Ventas
            <div className="h-1 w-20 bg-blue-600 mt-2 rounded-full"></div>
          </h1>
        </div>

        {/* Selector de año */}
        <div className="mb-6 flex justify-end">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
          >
            {[...Array(10)].map((_, i) => {
              const year = new Date().getFullYear() - 5 + i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>

        {/* Controles de fecha */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
            <label htmlFor="month" className="block text-lg font-semibold mb-3 text-gray-700">
              Selecciona el Mes:
            </label>
            <select 
              id="month" 
              value={selectedMonth} 
              onChange={(e) => setSelectedMonth(Number(e.target.value))} 
              className="p-3 border border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
            >
              {[...Array(12).keys()].map(i => (
                <option key={i} value={i + 1}>
                  {new Date(2025, i).toLocaleString('default', { month: 'long' })}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
            <label htmlFor="week" className="block text-lg font-semibold mb-3 text-gray-700">
              Selecciona la Semana:
            </label>
            <select 
              className="form-select"
              value={selectedWeek} 
              onChange={(e) => setSelectedWeek(e.target.value)}
            >
              {[...Array(52)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  Semana {index + 1} ({getWeekDates(index + 1)})
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Contenido dinámico */}
        {renderContent()}

        <div className="mt-8">
          <ImageMigration />
        </div>
      </main>
    </div>
  );
};

export default Dashboard;

