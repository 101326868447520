import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import YouTube from "react-youtube";

/**
 * Valida si una cadena de texto es un enlace de YouTube.
 * @param {string} input - La cadena de texto a validar.
 * @returns {boolean} - `true` si la cadena es un enlace de YouTube, `false` en caso contrario.
 */
const isYouTubeLink = (input) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubeRegex.test(input);
};

/**
 * Extrae el ID del video de un enlace de YouTube.
 * @param {string} url - El enlace de YouTube.
 * @returns {string|null} - El ID del video de YouTube o `null` si no se puede extraer.
 */
const extractYouTubeID = (url) => {
  const idRegex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(idRegex);
  return match ? match[1] : null;
};

export const PortadaBodaComponent = ({ datosSeccionPotada, audioFondo, screenMobile }) => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isYouTube, setIsYouTube] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cancionYoutube, setCancionYoutube] = useState("");
  const playerRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handlePlayButtonClick = () => {
    setIsMusicPlaying(!isMusicPlaying);
  };

  const reproYoutube = (vali) => {
    if (vali) {
      setIsYouTube(true);
      const youtubeID = extractYouTubeID(datosSeccionPotada.cancion);
      console.log("ID del video de YouTube:", youtubeID);
      setCancionYoutube(youtubeID);
    }
  };

  const onReady = (event) => {
    playerRef.current = event.target;
    playerRef.current.playVideo(); // Autoplay the video
  };

  useEffect(() => {
    const validacion = isYouTubeLink(datosSeccionPotada.cancion);
    reproYoutube(validacion);
    const audioElement = document.getElementById("backgroundMusic");
    if (isMusicPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0; // Reinicia el audio para que comience desde el principio cuando se pausa
    }
  }, [isMusicPlaying]);

  const fontFamilyStyle = {
    fontFamily: datosSeccionPotada.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
    color: datosSeccionPotada.textColor || "#ffffff",
  };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionPotada.imgPortada})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh", // Ajusta la altura del contenedor
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="bg-slate-950 flex flex-col justify-center items-center w-full"
    >
      <div
        className="flex flex-col justify-center h-full w-full items-center px-2 pt-[40vh]"
        style={{ backgroundColor: datosSeccionPotada.titulo === "Nada" ? "" : "rgb(0 0 0 / 22%)" }}
      >
        {isYouTube ? (
          <YouTube
            videoId={cancionYoutube}
            opts={{
              height: "1", // Cambia a un tamaño pequeño pero visible
              width: "1",
              playerVars: {
                autoplay: 1,
                controls: 0,
              },
            }}
            onReady={onReady}
          />
        ) : (
          <audio
            id="backgroundMusic"
            style={{ display: "none" }}
            autoPlay
            loop
            muted={!isMusicPlaying}
          >
            <source src={datosSeccionPotada.cancion} type="audio/mpeg" />
            {/* Agrega más elementos <source> aquí si quieres proporcionar múltiples formatos de audio */}
          </audio>
        )}

        {datosSeccionPotada.cancion ? (
          <div className="w-full flex justify-end h-full items-start fixed ">
            {isYouTube ? (
              <button
                onClick={() => {
                  if (playerRef.current) {
                    if (isPlaying) {
                      playerRef.current.pauseVideo();
                    } else {
                      playerRef.current.playVideo();
                    }
                    setIsPlaying(!isPlaying);
                  }
                }}
                className="text-3xl rounded animate-bounce bg-black p-1"
              >
                {isPlaying ? "🔊" : "🔇"}
              </button>
            ) : (
              <button
                onClick={handlePlayButtonClick}
                className="text-3xl rounded animate-bounce bg-black p-1"
              >
                {isMusicPlaying ? "🔊" : "🔇"}
              </button>
            )}
          </div>
        ) : (
          ""
        )}

        <br />
        <br />
        {datosSeccionPotada.titulo == "Nada" ? (
          ""
        ) : (
          <h1
            className={`font-serif ${screenMobile ? 'text-4xl' : 'sm:text-4xl md:text-[132px]'} ${datosSeccionPotada.textColor
              ? datosSeccionPotada.textColor
              : "text-white"
              } text-center flex ${datosSeccionPotada.posicion === "arriba" ? "mt-[-200px] mb-[300px]" : ""}`}
            data-aos={screenMobile ? '' : 'flip-up'}
            style={fontFamilyStyle}
          >
            {datosSeccionPotada.esposa}{" "}
            {datosSeccionPotada.esposa ? (
              <span>
                <img
                  src="https://i.postimg.cc/6Qqt7rrB/Dise-o-sin-t-tulo-15.png"
                  className={`${screenMobile ? 'h-[62px]' : 'sm:h-[62px] md:h-[138px]'}`}
                  alt=""
                />
              </span>
            ) : (
              ""
            )}{" "}
            {datosSeccionPotada.esposo}
          </h1>
        )}

        <br />
        <br />
        <p
          className={`p-3 rounded-lg font-serif text-2xl text-center ${datosSeccionPotada.textColor
            ? datosSeccionPotada.textColor
            : "text-white"
            }`}
          data-aos={screenMobile ? '' : 'fade-up'}
          style={fontFamilyStyle}
        >
          {datosSeccionPotada.titulo
            ? datosSeccionPotada.titulo == "Nada"
              ? ""
              : `- ${datosSeccionPotada.titulo} -`
            : ""}{" "}
          <br />{" "}
          {datosSeccionPotada.titulo == "Nada"
            ? ""
            : datosSeccionPotada.fechaDeBoda
              ? `- ${datosSeccionPotada.fechaDeBoda} -`
              : ""}
        </p>
        <br />
      </div>
    </div>
  );
};
