import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { SelectSobre } from '../../../components/functions/SelectSobre';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { PortadaBodaComponent2 } from '../../../components/ComponentsBoda2/PortadaBodaComponent2';
import { PortadaBodaComponent } from '../../../components/ComponentsBoda/PortadaBodaComponent';
import { PreviewModal } from '../../../components/PreviewModal';

export const PortadaBodaForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Actualizar currentValues cuando cambien los initialValues
  useEffect(() => {
    if (initialValues) { 
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Manejador personalizado para actualizar el preview
  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  // Datos formateados para el preview
  const getPreviewData = (values) => ({
    textoConfirmacion: values?.textoConfirmacion || "",
    esposo: values?.esposo || "",
    esposa: values?.esposa || "",
    fechaDeBoda: values?.fechaDeBoda || "",
    imgPortada: values?.imgPortada || "",
    titulo: values?.titulo || "",
    textColor: values?.textColor || "",
    tipoLetra: values?.tipoLetra || "",
    posicion: values?.posicion || "",
  });

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Detalles de la Portada</h3>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6">
                  <div>
                    <label htmlFor="esposa" className="block text-sm font-medium text-gray-700 mb-1">
                      Nombre de la Novia:
                    </label>
                    <Input
                      type="text"
                      id="esposa"
                      name="esposa"
                      value={values?.esposa}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="esposo" className="block text-sm font-medium text-gray-700 mb-1">
                      Nombre del Novio:
                    </label>
                    <Input
                      type="text"
                      id="esposo"
                      name="esposo"
                      value={values?.esposo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="fechaDeBoda" className="block text-sm font-medium text-gray-700 mb-1">
                      Fecha de la Boda:
                    </label>
                    <Input
                      type="text"
                      id="fechaDeBoda"
                      name="fechaDeBoda"
                      value={values?.fechaDeBoda}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="cancion" className="block text-sm font-medium text-gray-700 mb-1">
                      URL de la Canción (YouTube):
                    </label>
                    <Input
                      type="text"
                      id="cancion"
                      name="cancion"
                      value={values?.cancion}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                      Título:
                    </label>
                    <Input
                      type="text"
                      id="titulo"
                      name="titulo"
                      value={values?.titulo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                      Color del Texto:
                    </label>
                    <Input
                      type="color"
                      id="textColor"
                      name="textColor"
                      value={values?.textColor || "#ffffff"}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full h-10 p-1 rounded-md border-gray-300"
                    />
                  </div>
                  <div>
                    <ImageFondo
                      label="Seleccione un fondo"
                      name="imgPortada"
                      onChange={handleCustomChange(handleChange, values)}
                      value={values?.imgPortada}
                      className={values?.imgPortada ? "input-error" : ""}
                    />
                    <Input
                      type="text"
                      id="imgPortada"
                      name="imgPortada"
                      value={values?.imgPortada}
                      onChange={handleCustomChange(handleChange, values)}
                    />
                  </div>
                  <div className="md:col-span-2">
                    <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                  </div>
                  <div className="md:col-span-2">
                    <SelectSobre values={values} handleChange={handleCustomChange(handleChange, values)} />
                  </div>
                  <div>
                    <label htmlFor="posicion" className="block text-sm font-medium text-gray-700 mb-1">
                      Posición titulo:
                    </label>
                    <select
                      id="posicion"
                      name="posicion"
                      value={values?.posicion || ""}
                      onChange={handleCustomChange(handleChange, values)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="">Normal</option>
                      <option value="arriba">Arriba</option>
                      <option value="abajo">Abajo</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentValues(values);
                      setRefreshKey(prev => prev + 1);
                      if (isMobile) {
                        setShowMobilePreview(true);
                      }
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Actualizar Portada
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {!isMobile && <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <PortadaBodaComponent2
                  key={refreshKey}
                  datosSeccionPotadaBoda={getPreviewData(currentValues)}
                  colores={colores}
                  isMenuOpen={false}
                  setIsMenuOpen={() => {}}
                  scrollToSection={() => {}}
                  enableSecciones={{}}
                  isEnglish={false}
                  screenMobile={true}
                />
              ) : (
                <PortadaBodaComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionPotada={getPreviewData(currentValues)}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>}
          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {currentValues && Object.keys(currentValues).length > 0 && (
                diseño === "2" ? (
                  <PortadaBodaComponent2
                    key={refreshKey}
                    datosSeccionPotadaBoda={getPreviewData(currentValues)}
                    colores={colores}
                    isMenuOpen={false}
                    setIsMenuOpen={() => {}}
                    scrollToSection={() => {}}
                    enableSecciones={{}}
                    isEnglish={false}
                    screenMobile={true}
                  />
                ) : (
                  <PortadaBodaComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionPotada={getPreviewData(currentValues)}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
}; 