import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import ImageUploadForm from "./Img/ImageUploadForm";
import { FaUpload } from 'react-icons/fa'; // Import FaUpload icon for upload button

const ImageUploader = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [error, setError] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imageName, setImageName] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchType, setSearchType] = useState("");
  const [filteredImages, setFilteredImages] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 20;

  // Load image list on component mount
  useEffect(() => {
    fetchImages();
    fetchInstagramPosts();
  }, []);

  // Filter images when search criteria change
  useEffect(() => {
    const filtered = imageList
      .filter(image => {
        const nameMatch = image.nombre.toLowerCase().includes(searchName.toLowerCase());
        const typeMatch = searchType === "" || image.tipo === searchType;
        return nameMatch && typeMatch;
      })
      .sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
    
    setFilteredImages(filtered);
  }, [searchName, searchType, imageList]);

  // Get image list
  const fetchImages = async (pageNumber = 1, append = false) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/Imagenes/imagenes/list?page=${pageNumber}&pageSize=${pageSize}`);
      
      // Obtener el total de páginas del header
      const totalCount = parseInt(response.headers['x-total-count']);
      const totalPages = parseInt(response.headers['x-total-pages']);
      setTotalPages(totalPages);
      setHasMore(pageNumber < totalPages);

      if (append) {
        setImageList(prev => [...prev, ...response.data]);
        setFilteredImages(prev => [...prev, ...response.data]);
      } else {
        setImageList(response.data);
        setFilteredImages(response.data);
      }
    } catch (err) {
      console.error("Error loading images:", err);
    } finally {
      setLoading(false);
    }
  };

  // Get Instagram posts
  const fetchInstagramPosts = async () => {
    try {
      const response = await axios.get("/api/InstagramPosts");
    } catch (err) {
      console.error("Error loading Instagram posts:", err);
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadedUrl(null);
    setError(null);
  };

  // Handle name change
  const handleNameChange = (e) => {
    setImageName(e.target.value);
  };

  // Upload image
  const handleUpload = async () => {
    if (!file) {
      setError("Please select an image.");
      return;
    }

    if (!imageName) {
      setError("Please enter a name for the image.");
      return;
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("nombre", imageName);

    try {
      const response = await axios.post("/api/Imagenes/upload", formData, {
        params: {
          nombre: imageName
        },
        headers: { 
          "Content-Type": "multipart/form-data"
        },
      });
      setUploadedUrl(response.data.Url);
      setImageName(""); // Clear name after upload
      fetchImages(); // Update list after upload
    } catch (err) {
      setError("Error uploading image. Please try again.");
      console.error("Detailed error:", err);
    } finally {
      setUploading(false);
    }
  };

  // Function to refresh image list
  const refreshImageList = () => {
    fetchImages(); // Call the function that gets the image list
  };

  // Función para cargar más imágenes
  const loadMore = () => {
    if (!loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchImages(nextPage, true);
    }
  };

  // Usar IntersectionObserver para detectar cuando el usuario llega al final
  const observer = useRef();
  const lastImageRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  return (
    <div className="p-4 max-w-6xl mx-auto bg-white shadow-md rounded-lg">
      <ImageUploadForm onUploadSuccess={() => {
        setPage(1);
        fetchImages(1);
      }} />

      <div className="border rounded shadow-md p-4 mt-4">
        <h2 className="text-2xl font-bold mb-4">Uploaded Images</h2>
        
        <div className="mb-4 flex flex-col sm:flex-row gap-4">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            className="p-2 border rounded flex-1"
          />
          
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="">All types</option>
            <option value="Icon">Icon</option>
            <option value="Fondos">Background</option>
            <option value="Otros">Other</option>
          </select>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Preview</th>
                <th className="px-4 py-2">Actions</th>
                <th className="px-4 py-2">URL</th>
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredImages.map((image, index) => (
                <tr 
                  key={image.idImagen} 
                  ref={index === filteredImages.length - 1 ? lastImageRef : null}
                  className="border-t"
                >
                  <td className="px-4 py-2">{image.nombre}</td>
                  <td className="px-4 py-2 bg-slate-400">
                    <img
                      src={image?.urlImg}
                      alt={image?.nombre}
                      className="w-20 h-20 object-cover"
                    />
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => navigator.clipboard.writeText(image.urlImg)}
                      className="text-green-500 hover:text-green-700"
                    >
                      Copy URL
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      value={image.urlImg}
                      readOnly
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="px-4 py-2">{image.tipo}</td>
                  <td className="px-4 py-2">{image.fecha}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {loading && (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
          </div>
        )}

        {!hasMore && filteredImages.length > 0 && (
          <div className="text-center py-4 text-gray-500">
            No hay más imágenes para cargar
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
