import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { getWeekDates } from '../utils/dateUtils';

const VentasVendedores = ({ selectedWeek, selectedYear }) => {
  const [dataVendedoras, setDataVendedoras] = useState(null);
  const [historialSemanal, setHistorialSemanal] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [weekDates, setWeekDates] = useState({ start: null, end: null });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [vendedorasRes, historialRes] = await Promise.all([
          axios.get("/api/Eventos/ventas-diarias-vendedoras", {
            params: { year: selectedYear, weekNumber: selectedWeek },
          }),
          axios.get("/api/Eventos/historial-semanal", {
            params: { year: selectedYear, weekNumber: selectedWeek },
          })
        ]);

        setDataVendedoras(vendedorasRes.data);
        setHistorialSemanal(historialRes.data);
        console.error("seller", vendedorasRes.data);
      } catch (error) {
        console.error("Error fetching vendedores data", error);
      }
    };

    fetchData();
    setWeekDates(getWeekDates(selectedYear, selectedWeek));
  }, [selectedWeek, selectedYear]);

  if (!dataVendedoras || !historialSemanal) return <div>Cargando...</div>;

  return (
    <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-800 flex items-center">
          <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
          Eventos por Vendedor (Semana {selectedWeek}) <br/>
          Metas: 190 inv o  324 puntos
        </h2>
        <p className="text-sm text-gray-600">
          {weekDates.start?.toLocaleDateString()} - {weekDates.end?.toLocaleDateString()}
        </p>
      </div>
      <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50">
              <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Vendedor</th>
              <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Cantidad de Eventos</th>
              <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Total de Ventas</th>
              <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Puntaje</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(dataVendedoras).map(([vendedor, data]) => (
              <tr key={vendedor} className="hover:bg-gray-50 transition duration-200">
                <td className="border border-gray-200 p-3 capitalize">{vendedor}</td>
                <td className="border border-gray-200 p-3">{data.eventos}</td>
                <td className="border border-gray-200 p-3">${data.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td className="border border-gray-200 p-3">
                  <span className="font-semibold text-blue-600">{data.puntaje}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
        {historialSemanal && Object.entries(historialSemanal).map(([vendedor, datos], index) => {
          const filteredEventos = selectedDate 
            ? datos.eventos.filter(evento => evento.fecha.split('T')[0] === selectedDate)
            : datos.eventos;
            
          return (
            <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
                <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
                Historial de {datos.nombre} <br/>
                Metas: 210  inv o 195 finalizados
              </h2>
              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <input 
                    type="date" 
                    className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                  {selectedDate && (
                    <button 
                      className="p-3 text-blue-500 hover:text-blue-700 hover:bg-blue-50 rounded-lg transition duration-300"
                      onClick={() => setSelectedDate('')}
                    >
                      Mostrar todos
                    </button>
                  )}
                </div>
                <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Fecha</th>
                        <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Nombre</th>
                        <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Tipo</th>
                        <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Evento Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEventos.map((evento, eventoIndex) => (
                        <tr key={eventoIndex} className="hover:bg-gray-50 transition duration-200">
                          <td className="border border-gray-200 p-3">
                            {new Date(evento.fecha).toLocaleDateString('es-MX', {
                              year: 'numeric',
                              month: '2-digit', 
                              day: '2-digit'
                            })}
                          </td>
                          <td className="border border-gray-200 p-3">{evento.nombre}</td>
                          <td className="border border-gray-200 p-3">{evento.tipo}</td>
                          <td className="border border-gray-200 p-3">{evento.evento}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p className="text-lg font-semibold text-gray-700">
                  Total de eventos: {filteredEventos.length}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VentasVendedores; 