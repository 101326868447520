import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input, Label } from "reactstrap";
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { CeremoniaXvComponent } from '../../../components/ComponentsXv/CeremoniaXvComponent';
import { UbicacionesBodaComponent2 } from '../../../components/ComponentsBoda2/UbicacionesBodaComponent2';
import { PreviewModal } from '../../../components/PreviewModal';

export const CeremoniaForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  const isGoogleMapsUrl = (url) => {
    if (!url) return true;
    const cleanUrl = url.startsWith('@') ? url.substring(1) : url;
    const mapsRegex = /^(https?:\/\/)?(www\.)?(google\.com\/maps|maps\.app\.goo\.gl|goo\.gl\/maps|maps\.app\.goo\.gl\/[a-zA-Z0-9]+)/;
    return mapsRegex.test(cleanUrl);
  };

  const validateUrl = (url) => {
    if (!url) return "La URL es requerida";
    const cleanUrl = url.startsWith('@') ? url.substring(1) : url;
    if (!cleanUrl.startsWith('http://') && !cleanUrl.startsWith('https://') && !cleanUrl.startsWith('maps.app.goo.gl/')) {
      return "La URL debe comenzar con http://, https:// o ser un enlace corto de Google Maps";
    }
    if (!isGoogleMapsUrl(cleanUrl)) {
      return "La URL debe ser de Google Maps";
    }
    return null;
  };

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Detalles de la Ceremonia</h3>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit, errors, touched, setFieldTouched }) => {
              const ceremoniaUrlError = validateUrl(values?.ceremoniaUrl);
              const recepcionUrlError = validateUrl(values?.recepcionUrl);

              return (
                <Form onSubmit={handleSubmit} className="space-y-6">
                  {/* Sección de Ceremonia */}
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-xl font-semibold text-gray-800">Ceremonia</h3>
                      <label htmlFor="ceremoniaEnable" className="flex items-center">
                        <Input
                          type="checkbox"
                          id="ceremoniaEnable"
                          name="ceremoniaEnable"
                          checked={values?.ceremoniaEnable}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mr-2"
                        />
                        <span className="text-sm font-medium text-gray-700">
                          Habilitar Ceremonia
                        </span>
                      </label>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="ceremoniaImg" className="block text-sm font-medium text-gray-700 mb-1">
                          Imagen de Ceremonia:
                        </label>
                        <Input
                          type="text"
                          id="ceremoniaImg"
                          name="ceremoniaImg"
                          value={values?.ceremoniaImg}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="ceremoniaImg"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="ceremoniaTitulo" className="block text-sm font-medium text-gray-700 mb-1">
                          Título de Ceremonia:
                        </label>
                        <Input
                          type="text"
                          id="ceremoniaTitulo"
                          name="ceremoniaTitulo"
                          placeholder="Ceremonia..."
                          value={values?.ceremoniaTitulo}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="ceremoniaTitulo"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="ceremoniaFecha" className="block text-sm font-medium text-gray-700 mb-1">
                          Fecha de Ceremonia:
                        </label>
                        <Input
                          type="text"
                          id="ceremoniaFecha"
                          name="ceremoniaFecha"
                          value={values?.ceremoniaFecha}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="ceremoniaFecha"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="ceremoniaHora" className="block text-sm font-medium text-gray-700 mb-1">
                          Hora de Ceremonia:
                        </label>
                        <Input
                          type="text"
                          id="ceremoniaHora"
                          name="ceremoniaHora"
                          value={values?.ceremoniaHora}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="ceremoniaHora"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="ceremoniaLugarMap" className="block text-sm font-medium text-gray-700 mb-1">
                          Dirección de Ceremonia (Mapa):
                        </label>
                        <Input
                          type="text"
                          id="ceremoniaLugarMap"
                          name="ceremoniaLugarMap"
                          value={values?.ceremoniaLugarMap}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="ceremoniaLugarMap"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="ceremoniaNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">
                          Nombre del Lugar de Ceremonia:
                        </label>
                        <Input
                          type="text"
                          id="ceremoniaNombreLugar"
                          name="ceremoniaNombreLugar"
                          value={values?.ceremoniaNombreLugar}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="ceremoniaNombreLugar"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <Label htmlFor="ceremoniaUrl" className="block text-sm font-medium text-gray-700 mb-1">
                          URL de Ceremonia
                          <a 
                            href="https://www.google.com/maps" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="ml-2 text-blue-500 hover:text-blue-700 text-sm"
                          >
                            Ir a Google Maps
                          </a>
                        </Label>
                        <Input
                          id="ceremoniaUrl"
                          type="text"
                          name="ceremoniaUrl"
                          value={values?.ceremoniaUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldTouched('ceremoniaUrl', true, false);
                          }}
                          className={`w-full border rounded-md shadow-sm transition-colors ${
                            ceremoniaUrlError 
                              ? "border-2 border-red-500 border-solid focus:ring-red-500 focus:border-red-500 bg-red-50" 
                              : " border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                          }`}
                          placeholder="https://www.google.com/maps/..."
                        />
                        {ceremoniaUrlError && (
                          <div className="mt-2">
                            <p className="text-sm text-red-600">{ceremoniaUrlError}</p>
                            <p className="text-sm text-gray-500">
                              Ejemplo válido: https://www.google.com/maps/place/... o @https://maps.app.goo.gl/...
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Opción de Doble Evento */}
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-xl font-semibold text-gray-800">Configuración de Eventos</h3>
                      <label htmlFor="dobleEnable" className="flex items-center">
                        <Input
                          type="checkbox"
                          id="dobleEnable"
                          name="dobleEnable"
                          checked={values?.dobleEnable}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mr-2"
                        />
                        <span className="text-sm font-medium text-gray-700">
                          Habilitar Doble Evento
                        </span>
                      </label>
                    </div>
                    <p className="text-sm text-gray-600">
                      Al habilitar esta opción, se mostrarán tanto la ceremonia como la recepción en la invitación en un mismo campo (se usa cuando es en el mismo lugar).
                    </p>
                  </div>

                  {/* Sección de Recepción */}
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-xl font-semibold text-gray-800">Recepción</h3>
                      <label htmlFor="recepcionEnable" className="flex items-center">
                        <Input
                          type="checkbox"
                          id="recepcionEnable"
                          name="recepcionEnable"
                          checked={values?.recepcionEnable}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mr-2"
                        />
                        <span className="text-sm font-medium text-gray-700">
                          Habilitar Recepción
                        </span>
                      </label>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="recepcionImg" className="block text-sm font-medium text-gray-700 mb-1">
                          Imagen de Recepción:
                        </label>
                        <Input
                          type="text"
                          id="recepcionImg"
                          name="recepcionImg"
                          value={values?.recepcionImg}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="recepcionImg"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="recepcionTitulo" className="block text-sm font-medium text-gray-700 mb-1">
                          Título de Recepción:
                        </label>
                        <Input
                          type="text"
                          id="recepcionTitulo"
                          placeholder="Recepción..."
                          name="recepcionTitulo"
                          value={values?.recepcionTitulo}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="recepcionTitulo"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="recepcionFecha" className="block text-sm font-medium text-gray-700 mb-1">
                          Fecha de Recepción:
                        </label>
                        <Input
                          type="text"
                          id="recepcionFecha"
                          name="recepcionFecha"
                          value={values?.recepcionFecha}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="recepcionFecha"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="recepcionHora" className="block text-sm font-medium text-gray-700 mb-1">
                          Hora de Recepción:
                        </label>
                        <Input
                          type="text"
                          id="recepcionHora"
                          name="recepcionHora"
                          value={values?.recepcionHora}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="recepcionHora"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="recepcionLugarMap" className="block text-sm font-medium text-gray-700 mb-1">
                          Direccion de Recepción (Mapa):
                        </label>
                        <Input
                          type="text"
                          id="recepcionLugarMap"
                          name="recepcionLugarMap"
                          value={values?.recepcionLugarMap}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="recepcionLugarMap"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <label htmlFor="recepcionNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">
                          Nombre del Lugar de Recepción:
                        </label>
                        <Input
                          type="text"
                          id="recepcionNombreLugar"
                          name="recepcionNombreLugar"
                          value={values?.recepcionNombreLugar}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          name="recepcionNombreLugar"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div>
                        <Label htmlFor="recepcionUrl" className="block text-sm font-medium text-gray-700 mb-1">
                          URL de Recepción
                          <a 
                            href="https://www.google.com/maps" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="ml-2 text-blue-500 hover:text-blue-700 text-sm"
                          >
                            Ir a Google Maps
                          </a>
                        </Label>
                        <Input
                          id="recepcionUrl"
                          type="text"
                          name="recepcionUrl"
                          value={values?.recepcionUrl}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldTouched('recepcionUrl', true, false);
                          }}
                          className={`w-full rounded-md shadow-sm transition-colors ${
                            recepcionUrlError 
                              ? "border-2 border-red-500 focus:ring-red-500 focus:border-red-500 bg-red-50" 
                              : "border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                          }`}
                          placeholder="https://www.google.com/maps/..."
                        />
                        {recepcionUrlError && (
                          <div className="mt-2">
                            <p className="text-sm text-red-600">{recepcionUrlError}</p>
                            <p className="text-sm text-gray-500">
                              Ejemplo válido: https://www.google.com/maps/place/... o @https://maps.app.goo.gl/...
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Sección de Configuración General */}
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Configuración General</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                          Color de Texto:
                        </label>
                        <Input
                          type="color"
                          id="textColor"
                          name="textColor"
                          value={values?.textColor}
                          onChange={handleCustomChange(handleChange, values)}
                          className="mt-1 block w-full h-10"
                        />
                      </div>

                      <div>
                        <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                          Imagen de fondo:
                        </label>
                        <ImageFondo
                          label="Seleccione un fondo"
                          name="imgFondo"
                          onChange={handleCustomChange(handleChange, values)}
                          value={values?.imgFondo}
                          className="mt-1 w-full"
                        />
                        <Input
                          type="text"
                          id="imgFondo"
                          name="imgFondo"
                          value={values?.imgFondo}
                          onChange={handleCustomChange(handleChange, values)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-4">
                    <button
                      type="button"
                      onClick={() => {
                        setCurrentValues(values);
                        setRefreshKey(prev => prev + 1);
                        if (isMobile) {
                          setShowMobilePreview(true);
                        }
                      }}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                    >
                      Ver Preview
                    </button>
                    <button
                      type="submit"
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                    >
                      Guardar Cambios
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {!isMobile && <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <UbicacionesBodaComponent2
                  key={refreshKey}
                  datosSeccionCeremonia={currentValues}
                  colores={colores}
                  isEnglish={false}
                  screenMobile={true}
                />
              ) : (
                <CeremoniaXvComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionCeremonia={currentValues}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>}
          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {currentValues && Object.keys(currentValues).length > 0 && (
                diseño === "2" ? (
                  <UbicacionesBodaComponent2
                    key={refreshKey}
                    datosSeccionCeremonia={currentValues}
                    colores={colores}
                    isEnglish={false}
                    screenMobile={true}
                  />
                ) : (
                  <CeremoniaXvComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionCeremonia={currentValues}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
}; 