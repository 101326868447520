import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaHome, 
  FaCamera, 
  FaQrcode, 
  FaUserPlus, 
  FaImages,
  FaDownload,
  FaShare,
  FaTimes,
  FaCloudUploadAlt,
  FaChevronRight,
  FaWhatsapp,
  FaTelegram,
  FaFacebook,
  FaEnvelope,
  FaComment,
  FaHeart,
  FaTrash,
  FaPlus,
  FaFolderOpen,
} from 'react-icons/fa';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { QRCodeSVG } from 'qrcode.react';
import { ImageGalleryView } from './ImageGalleryView';

// Actualizar las variables de color
const colors = {
  primary: '#1a1a1a',
  secondary: '#f5f5f5',
  accent: '#D4AF37',
  surface: '#ffffff',
  text: '#2d2d2d'
};

// Actualizar los estilos
const styles = `
.card-container {
  perspective: 1000px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  padding: 12px;
}

.image-item {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.image-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: ${colors.accent};
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
}

.floating-button:hover {
  transform: scale(1.1);
}

.bottom-nav {
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,0.8);
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.5), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-item:hover .image-overlay {
  opacity: 1;
}

.like-button {
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.like-button:active {
  transform: scale(1.5);
}
`;

export const ImgEvent = () => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadingImages, setUploadingImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imageText, setImageText] = useState('');
  const [flippedCards, setFlippedCards] = useState({});
  const [activeTab, setActiveTab] = useState('gallery');
  const [invitados, setInvitados] = useState([]);
  const [selectedInvitado, setSelectedInvitado] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [likeDetails, setLikeDetails] = useState([]);
  const [likesList, setLikesList] = useState([]);
  const [showLikesList, setShowLikesList] = useState(false);
  const [newInvitado, setNewInvitado] = useState({ 
    nombre: '', 
    accesos: 0,  // Agregar accesos
    idEvento: parseInt(id)
  });
  const [showAddInvitadoForm, setShowAddInvitadoForm] = useState(false);
  const [galeriaAdmin, setGaleriaAdmin] = useState(null);
  const [editingGaleria, setEditingGaleria] = useState(false);
  const [galeriaForm, setGaleriaForm] = useState({
    nombre: '',
    foto: ''
  });
  const [albums, setAlbums] = useState([]);
  const [showCreateAlbumsButton, setShowCreateAlbumsButton] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [showNewAlbumForm, setShowNewAlbumForm] = useState(false);
  const [newAlbumName, setNewAlbumName] = useState('');
  const [albumToDelete, setAlbumToDelete] = useState(null);
  const [showDeleteAlbumConfirm, setShowDeleteAlbumConfirm] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    fetchImages();
    fetchInvitados();
    fetchGaleriaAdmin();
    fetchAlbums();
  }, [id]);

  // Agregar el estilo al componente
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const fetchImages = async (albumId = null) => {
    try {
      const response = await axios.get(`/api/ImagenesEvento/evento/${id}`);
      const allImagesData = response.data;
      setAllImages(allImagesData);
      console.log("allImagesData",allImagesData);
      
      if (albumId) {
        setImages(allImagesData.filter(img => img.idAlbum === albumId));
      } else {
        setImages(allImagesData);
      }
      setLoading(false);
    } catch (err) {
      setError('Error al cargar las imágenes');
      setLoading(false);
      console.error('Error fetching images:', err);
    }
  };

  const fetchInvitados = async () => {
    try {
      const response = await axios.get(`/api/Invitado/evento/${id}`);
      setInvitados(response.data);
    } catch (err) {
      console.error('Error al cargar invitados:', err);
      setError('Error al cargar la lista de invitados');
    }
  };

  const fetchGaleriaAdmin = async () => {
    try {
      const response = await axios.get(`/api/GaleriaEventoAdmin/Evento/${id}`);
      if (response.data && response.data.length > 0) {
        setGaleriaAdmin(response.data[0]);
        setGaleriaForm({
          nombre: response.data[0].nombre,
          foto: response.data[0].foto
        });
      }
    } catch (error) {
      console.error('Error al cargar datos de la galería:', error);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`/api/Album/Evento/${id}`);
      console.log("Albums",response.data);
      setAlbums(response.data);
      setShowCreateAlbumsButton(response.status === 404);
    } catch (error) {
      if (error.response?.status === 404) {
        setShowCreateAlbumsButton(true);
      }
      console.error('Error al cargar álbumes:', error);
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setUploadingImages(files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    })));
  };

  const handleUpload = async () => {
    if (!selectedAlbum) {
      setError('Por favor selecciona un álbum');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      for (const imgData of uploadingImages) {
        const formData = new FormData();
        formData.append("file", imgData.file);

        const azureResponse = await axios.post('/api/ImagenesAzure/Upload', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });

        await axios.post('/api/ImagenesEvento', {
          urlImg: azureResponse.data.url,
          texto: imageText,
          idEvento: parseInt(id),
          idAlbum: selectedAlbum.idAlbum  // Usar el álbum seleccionado
        });

        setUploadProgress(prev => prev + (100 / uploadingImages.length));
      }

      // Recargar imágenes y limpiar el formulario
      await fetchImages(selectedAlbum.idAlbum);
      setUploadingImages([]);
      setImageText('');
      setIsUploading(false);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error al subir las imágenes:', error);
      setError('Error al subir las imágenes');
      setIsUploading(false);
    }
  };

  const handleDelete = async (image) => {
    try {
      // 1. Eliminar likes asociados a la imagen
      await axios.delete(`/api/Likes/Image/${image.idImagenesEvento}`);

      // 2. Eliminar de Azure
      const fileName = image.urlImg.split('/').pop();
      await axios.delete(`/api/ImagenesAzure/Delete/${fileName}`);

      // 3. Eliminar de la base de datos
      await axios.delete(`/api/ImagenesEvento/${image.idImagenesEvento}`);

      // 4. Actualizar la lista
      setImages(images.filter(img => img.idImagenesEvento !== image.idImagenesEvento));
      setShowDeleteConfirm(false);
      setImageToDelete(null);
      setSelectedImage(null); // Cerrar el modal de imagen si está abierto
    } catch (err) {
      setError('Error al eliminar la imagen');
      console.error('Error deleting image:', err);
    }
  };

  const confirmDelete = async () => {
    if (imageToDelete) {
      await handleDelete(imageToDelete);
    }
  };

  const removeUploadingImage = (index) => {
    const newImages = [...uploadingImages];
    URL.revokeObjectURL(newImages[index].preview);
    newImages.splice(index, 1);
    setUploadingImages(newImages);
  };

  const handleFlip = (imageId, e) => {
    e.stopPropagation();
    setFlippedCards(prev => ({
      ...prev,
      [imageId]: !prev[imageId]
    }));
  };

  // Función para obtener el nombre del archivo de la URL
  const getImageFileName = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  // Función para descargar todas las imágenes
  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();
      
      // Crear un array de promesas para descargar todas las imágenes
      const downloadPromises = images.map(async (image, index) => {
        const response = await fetch(image.urlImg);
        const blob = await response.blob();
        zip.file(`imagen_${index + 1}.jpg`, blob);
      });

      await Promise.all(downloadPromises);
      
      // Generar y descargar el archivo zip
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "imagenes_evento.zip");
    } catch (error) {
      console.error('Error al descargar las imágenes:', error);
      setError('Error al descargar las imágenes');
    }
  };

  // Función para descargar imagen individual desde el modal
  const handleSingleImageDownload = async (imageUrl, index) => {
    try {
      const response = await axios({
        url: `/api/Album/DownloadSingle?imageUrl=${encodeURIComponent(imageUrl)}`,
        method: 'GET',
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'image/jpeg' });
      saveAs(blob, `imagen_${index + 1}.jpg`);
    } catch (error) {
      console.error('Error al descargar la imagen:', error);
      setError('Error al descargar la imagen');
    }
  };

  // Función para compartir
  const handleShare = async (invitado) => {
    const qrUrl = `${window.location.origin}/img-event-inv/${id}?invitado=${invitado.idInvitado}`;
    
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Álbum de fotos',
          text: `${invitado.nombre}, accede al álbum de fotos del evento`,
          url: qrUrl
        });
      } catch (error) {
        console.log('Error compartiendo:', error);
      }
    }
  };

  const getLikesCount = async (imageId) => {
    try {
      const likesCount = await axios.get(`/api/Likes/Image/${imageId}`);
      return likesCount.data;
    } catch (error) {
      console.error('Error al obtener likes:', error);
      return 0;
    }
  };

  const handleImageSelect = async (image) => {
    try {
      // Obtener el conteo actualizado de likes
      const likesCount = await axios.get(`/api/Likes/Image/${image.idImagenesEvento}`);
      // Obtener la lista de personas que dieron like
      const likesDetails = await axios.get(`/api/Likes/Details/${image.idImagenesEvento}`);
      
      setSelectedImage({
        ...image,
        likes: likesCount.data  // Usar el conteo numérico de likes
      });
      setLikesList(likesDetails.data);  // Guardar la lista de personas que dieron like
    } catch (error) {
      console.error('Error al obtener detalles de la imagen:', error);
      setSelectedImage(image);
    }
  };

  const handleAddInvitado = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/Invitado', {
        nombre: newInvitado.nombre,
        accesos: newInvitado.accesos,  // Enviar el número de accesos ingresado
        idEvento: parseInt(id)
      });

      // Actualizar la lista de invitados
      setInvitados([...invitados, response.data]);
      setNewInvitado({ 
        nombre: '', 
        accesos: 0,
        idEvento: parseInt(id)
      });
      setShowAddInvitadoForm(false);
      setError(null);
    } catch (error) {
      console.error('Error al agregar invitado:', error);
      setError('Error al agregar el invitado');
    }
  };

  const handleUpdateGaleria = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/GaleriaEventoAdmin/${galeriaAdmin.idAdmin}`, {
        ...galeriaAdmin,
        nombre: galeriaForm.nombre,
        foto: galeriaForm.foto
      });
      
      setGaleriaAdmin(prev => ({
        ...prev,
        nombre: galeriaForm.nombre,
        foto: galeriaForm.foto
      }));
      setEditingGaleria(false);
    } catch (error) {
      console.error('Error al actualizar la galería:', error);
    }
  };

  // Agregar este componente para el mensaje de error con opción de cerrar
  const ErrorMessage = () => {
    if (!error) return null;

    return (
      <div className="fixed bottom-4 left-4 right-4 bg-red-500 text-white p-4 rounded-lg max-w-md mx-auto flex items-center justify-between">
        <span>{error}</span>
        <button 
          onClick={() => setError(null)}
          className="ml-4 hover:bg-red-600 p-1 rounded"
        >
          <FaTimes />
        </button>
      </div>
    );
  };

  // Agregar función para manejar la subida de imagen
  const handleGaleriaImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append("file", file);

      const azureResponse = await axios.post('/api/ImagenesAzure/Upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });

      setGaleriaForm(prev => ({
        ...prev,
        foto: azureResponse.data.url
      }));
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      setError('Error al subir la imagen');
    }
  };

  const handleCreateDefaultAlbums = async () => {
    try {
      if (!galeriaAdmin) {
        setError('No se encontró la información del administrador');
        return;
      }

      // Crear álbum de Ceremonia
      await axios.post('/api/Album', {
        nombre: 'Ceremonia',
        posicion: 1,
        idEvento: parseInt(id),
        idAdmin: galeriaAdmin?.idAdmin // Usar el ID del admin de la galería
      });

      // Crear álbum de Recepción
      await axios.post('/api/Album', {
        nombre: 'Recepción',
        posicion: 2,
        idEvento: parseInt(id),
        idAdmin: galeriaAdmin?.idAdmin // Usar el ID del admin de la galería
      });

      // Recargar álbumes
      await fetchAlbums();
      setShowCreateAlbumsButton(false);
    } catch (error) {
      console.error('Error al crear álbumes:', error);
      setError('Error al crear los álbumes');
    }
  };

  const handleAlbumSelect = async (album) => {
    setSelectedAlbum(album);
    fetchImages(album.idAlbum);
  };

  const handleCreateNewAlbum = async () => {
    if (!newAlbumName.trim()) {
      setError('Por favor ingresa un nombre para el álbum');
      return;
    }

    try {
      await axios.post('/api/Album', {
        nombre: newAlbumName,
        posicion: albums.length + 1,
        idEvento: parseInt(id),
        idAdmin: galeriaAdmin?.idAdmin
      });

      // Recargar álbumes
      await fetchAlbums();
      setNewAlbumName('');
      setShowNewAlbumForm(false);
    } catch (error) {
      console.error('Error al crear el álbum:', error);
      setError('Error al crear el álbum');
    }
  };

  const handleDeleteAlbum = async (album) => {
    try {
      await axios.delete(`/api/Album/${album.idAlbum}`);
      
      // Actualizar la lista de álbumes
      await fetchAlbums();
      
      // Si el álbum eliminado era el seleccionado, mostrar todas las fotos
      if (selectedAlbum?.idAlbum === album.idAlbum) {
        setSelectedAlbum(null);
        fetchImages();
      }
      
      setShowDeleteAlbumConfirm(false);
      setAlbumToDelete(null);
    } catch (error) {
      console.error('Error al eliminar el álbum:', error);
      setError('Error al eliminar el álbum');
    }
  };

  // Primero agregamos la función para descargar las imágenes del álbum actual
  const handleDownloadAlbumImages = async () => {
    try {
      const albumName = selectedAlbum ? selectedAlbum.nombre : 'Todas';
      const imageUrls = images.map(img => img.urlImg);

      const response = await axios({
        url: '/api/Album/Download',
        method: 'POST',
        data: {
          imageUrls: imageUrls,
          albumName: albumName
        },
        responseType: 'blob'
      });

      // Crear y descargar el archivo ZIP
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `album_${albumName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error al descargar las imágenes:', error);
      setError('Error al descargar las imágenes');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sección de perfil de la galería */}
      {galeriaAdmin && (
        <div className="bg-white shadow-sm border-b">
          <div className="max-w-xl mx-auto px-4 py-6">
            {!editingGaleria ? (
              <div className="flex items-center gap-4">
                <img
                  src={galeriaAdmin.foto || '/default-gallery.png'}
                  alt="Galería"
                  className="w-20 h-20 rounded-full object-cover"
                />
                <div className="flex-1">
                  <h2 className="text-xl font-semibold text-gray-900">
                    {galeriaAdmin.nombre}
                  </h2>
                  <p className="text-sm text-gray-500">
                    {galeriaAdmin.cantidadImagenes} imágenes permitidas
                  </p>
                </div>
                <button
                  onClick={() => setEditingGaleria(true)}
                  className="px-4 py-2 text-sm text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                >
                  Editar
                </button>
              </div>
            ) : (
              <form onSubmit={handleUpdateGaleria} className="space-y-4">
                <div className="flex items-center gap-4">
                  <div className="relative">
                    {galeriaForm.foto ? (
                      <div className="relative">
                        <img
                          src={galeriaForm.foto}
                          alt="Galería"
                          className="w-20 h-20 rounded-full object-cover"
                        />
                        <div className="absolute -bottom-2 -right-2 flex gap-1">
                          <label className="cursor-pointer bg-blue-500 text-white p-1.5 rounded-full hover:bg-blue-600 transition-colors">
                            <input
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={handleGaleriaImageUpload}
                            />
                            <FaCamera className="text-xs" />
                          </label>
                          <button
                            type="button"
                            onClick={() => setGaleriaForm(prev => ({...prev, foto: ''}))}
                            className="bg-red-500 text-white p-1.5 rounded-full hover:bg-red-600 transition-colors"
                          >
                            <FaTrash className="text-xs" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label className="cursor-pointer">
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={handleGaleriaImageUpload}
                        />
                        <div className="w-20 h-20 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors">
                          <div className="text-center">
                            <FaCamera className="text-gray-400 text-xl mb-1 mx-auto" />
                            <span className="text-xs text-gray-500">Subir foto</span>
                          </div>
                        </div>
                      </label>
                    )}
                    <div className="mt-2">
                      
                    </div>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      value={galeriaForm.nombre}
                      onChange={(e) => setGaleriaForm(prev => ({...prev, nombre: e.target.value}))}
                      className="w-full p-2 text-xl font-semibold border rounded-lg"
                      placeholder="Nombre de la galería"
                    />
                    <p className="text-sm text-gray-500 mt-1">
                      {galeriaAdmin.cantidadImagenes} imágenes permitidas
                    </p>
                  </div>
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setEditingGaleria(false);
                      setGaleriaForm({
                        nombre: galeriaAdmin.nombre,
                        foto: galeriaAdmin.foto
                      });
                    }}
                    className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}

      {/* Header con efecto de cristal */}
      <div className="sticky top-0 z-40 backdrop-blur-lg bg-white/70 border-b border-gray-100">
        <div className="max-w-xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-medium text-gray-900">Álbum</h1>
            <div className="flex items-center gap-3">
              <span className="text-sm text-gray-500">
                {images.length} fotos
              </span>
              <div className="h-4 w-px bg-gray-200"></div>
              <span className="text-sm text-gray-500">
                {invitados.length} invitados
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-xl mx-auto pb-24">
        {activeTab === 'gallery' && (
          <ImageGalleryView
            images={images}
            albums={albums}
            selectedAlbum={selectedAlbum}
            onImageClick={handleImageSelect}
            onAlbumSelect={(album) => {
              setSelectedAlbum(album);
              fetchImages(album?.idAlbum);
            }}
            getLikesCount={getLikesCount}
            setImages={setImages}
            allImages={allImages}
          />
        )}

        {activeTab === 'camera' && (
          <div className="p-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl p-6 shadow-sm"
            >
              {/* Selector de álbum */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Seleccionar álbum
                </label>
                <select
                  value={selectedAlbum?.idAlbum || ''}
                  onChange={(e) => {
                    const albumId = parseInt(e.target.value);
                    const album = albums.find(a => a.idAlbum === albumId);
                    setSelectedAlbum(album || null);
                  }}
                  className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#D4AF37]"
                >
                  <option value="">Seleccionar álbum</option>
                  {albums.map(album => (
                    <option key={album.idAlbum} value={album.idAlbum}>
                      {album.nombre}
                    </option>
                  ))}
                </select>
              </div>

              <input
                id="fileInput"
                type="file"
                className="hidden"
                multiple
                accept="image/*"
                onChange={handleFileSelect}
              />
              
              {uploadingImages.length === 0 ? (
                <button
                  onClick={() => document.getElementById('fileInput').click()}
                  className="w-full h-48 border-2 border-dashed border-gray-200 rounded-xl flex flex-col items-center justify-center gap-3 text-gray-400 hover:text-[#D4AF37] hover:border-[#D4AF37] transition-colors"
                >
                  <FaCloudUploadAlt className="text-4xl" />
                  <span className="text-sm font-light">Toca para seleccionar fotos</span>
                </button>
              ) : (
                <div className="space-y-4">
                  <div className="grid grid-cols-3 gap-3">
                    {uploadingImages.map((img, index) => (
                      <div key={index} className="relative aspect-square">
                        <img
                          src={img.preview}
                          alt="Preview"
                          className="w-full h-full object-cover rounded-lg"
                        />
                        <button
                          onClick={() => removeUploadingImage(index)}
                          className="absolute -top-2 -right-2 bg-black/50 text-white p-1.5 rounded-full hover:bg-black transition-colors"
                        >
                          <FaTimes className="text-xs" />
                        </button>
                      </div>
                    ))}
                  </div>

                  <textarea
                    value={imageText}
                    onChange={(e) => setImageText(e.target.value)}
                    placeholder="Escribe un mensaje para tus fotos..."
                    className="w-full p-4 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D4AF37] text-sm"
                    rows="3"
                  />

                  {/* Mensaje de error si no se selecciona álbum */}
                  {uploadingImages.length > 0 && !selectedAlbum && (
                    <p className="text-red-500 text-sm mt-2">
                      Por favor selecciona un álbum para las imágenes
                    </p>
                  )}

                  <div className="flex gap-3">
                    <button
                      onClick={() => {
                        setUploadingImages([]);
                        setImageText('');
                      }}
                      className="flex-1 py-3 px-4 border border-gray-200 rounded-xl text-gray-600 hover:border-gray-300 transition-colors text-sm font-light"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={handleUpload}
                      disabled={isUploading || !selectedAlbum}
                      className="flex-1 py-3 px-4 bg-[#D4AF37] text-white rounded-xl hover:bg-[#B4934A] transition-colors text-sm font-light disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isUploading ? (
                        <div className="flex items-center justify-center gap-2">
                          <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                          <span>{uploadProgress}%</span>
                        </div>
                      ) : (
                        'Subir fotos'
                      )}
                    </button>
                  </div>
                </div>
              )}
            </motion.div>
          </div>
        )}

        {activeTab === 'guests' && (
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Invitados</h2>
              <button
                onClick={() => setShowAddInvitadoForm(true)}
                className="bg-[#D4AF37] text-white px-4 py-2 rounded-lg"
              >
                Agregar Invitado
              </button>
            </div>

            {/* Lista de invitados */}
            <div className="space-y-2">
              {invitados.map(invitado => (
                <div
                  key={invitado.idInvitado}
                  className="bg-white p-4 rounded-lg shadow flex justify-between items-center"
                >
                  <div>
                    <h3 className="font-medium">{invitado.nombre}</h3>
                    <p className="text-sm text-gray-500">Accesos: {invitado.accesos}</p>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        setSelectedInvitado(invitado);
                        setShowQRModal(true);
                      }}
                      className="p-2 text-[#D4AF37] hover:bg-gray-100 rounded-full"
                    >
                      <FaQrcode />
                    </button>
                    <button
                      onClick={() => handleShare(invitado)}
                      className="p-2 text-[#D4AF37] hover:bg-gray-100 rounded-full"
                    >
                      <FaShare />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Modal para agregar invitado */}
            <AnimatePresence>
              {showAddInvitadoForm && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black/75 z-50 flex items-center justify-center p-4"
                  onClick={() => setShowAddInvitadoForm(false)}
                >
                  <motion.div
                    initial={{ scale: 0.95 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0.95 }}
                    className="bg-white rounded-xl p-6 w-full max-w-md"
                    onClick={e => e.stopPropagation()}
                  >
                    <h3 className="text-lg font-semibold mb-4">Agregar Invitado</h3>
                    <form onSubmit={handleAddInvitado} className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Nombre
                        </label>
                        <input
                          type="text"
                          value={newInvitado.nombre}
                          onChange={e => setNewInvitado({
                            ...newInvitado, 
                            nombre: e.target.value
                          })}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Número de Accesos
                        </label>
                        <input
                          type="number"
                          min="0"
                          value={newInvitado.accesos}
                          onChange={e => setNewInvitado({
                            ...newInvitado, 
                            accesos: parseInt(e.target.value) || 0
                          })}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <div className="flex justify-end gap-2 mt-4">
                        <button
                          type="button"
                          onClick={() => setShowAddInvitadoForm(false)}
                          className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                        >
                          Cancelar
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 bg-[#D4AF37] text-white rounded-lg"
                        >
                          Guardar
                        </button>
                      </div>
                    </form>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}

        {/* Botón para crear álbumes por defecto */}
        {showCreateAlbumsButton && (
          <div className="p-4">
            <button
              onClick={handleCreateDefaultAlbums}
              className="w-full py-3 bg-[#D4AF37] text-white rounded-lg shadow-md hover:bg-[#B89530] transition-colors flex items-center justify-center gap-2"
            >
              <FaPlus className="text-lg" />
              <span>Crear Álbumes (Ceremonia y Recepción)</span>
            </button>
          </div>
        )}

        {/* Menú inferior con efecto de cristal */}
        <div className="fixed bottom-0 left-0 right-0 bottom-nav border-t border-gray-100">
          <div className="max-w-xl mx-auto flex justify-around">
            {[
              { id: 'gallery', icon: FaImages, label: 'Galería' },
              { id: 'camera', icon: FaCamera, label: 'Subir' },
              { id: 'guests', icon: FaUserPlus, label: 'Invitados' },
              { id: 'albums', icon: FaFolderOpen, label: 'Álbumes' }
            ].map(item => (
              <motion.button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`py-3 px-6 transition-all ${
                  activeTab === item.id 
                    ? 'text-[#D4AF37]' 
                    : 'text-gray-400'
                }`}
                whileHover={{ y: -2 }}
                whileTap={{ scale: 0.95 }}
              >
                <item.icon className="text-xl mx-auto mb-1" />
                <span className="text-xs">{item.label}</span>
              </motion.button>
            ))}
          </div>
        </div>

        {/* Modal de imagen */}
        <AnimatePresence>
          {selectedImage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/95 z-50 p-4"
              onClick={() => {
                setSelectedImage(null);
                setShowLikesList(false);
              }}
            >
              {/* Botón de cerrar */}
              <button
                onClick={() => {
                  setSelectedImage(null);
                  setShowLikesList(false);
                }}
                className="absolute top-4 right-4 p-2 text-white/50 hover:text-white transition-colors z-50"
              >
                <FaTimes className="text-2xl" />
              </button>

              <div className="h-full flex flex-col">
                <div className="flex-grow flex items-center justify-center relative">
                  <img
                    src={selectedImage.urlImg}
                    alt="Imagen ampliada"
                    className={`max-w-full max-h-[80vh] object-contain transition-all duration-300 ${
                      selectedImage.showMessage ? 'blur-sm' : ''
                    }`}
                  />
                  {selectedImage.texto && selectedImage.showMessage && (
                    <div className="absolute inset-0 flex items-center justify-center p-6">
                      <div className="bg-black/70 text-white p-6 rounded-[4px] max-w-md text-center">
                        <p className="text-lg">{selectedImage.texto}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-center gap-1 p-4">
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowLikesList(!showLikesList);
                      }}
                      className="p-4 rounded-full text-[#D4AF37] transition-colors flex items-center gap-2"
                    >
                      <FaHeart className={`text-xl ${
                        likesList.length > 0 ? 'text-red-500' : 'text-[#D4AF37]'
                      }`} />
                      <span className="text-white">{likesList.length}</span>
                    </button>

                    {/* Lista de likes */}
                    {showLikesList && likesList.length > 0 && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-white rounded-lg shadow-lg p-2 min-w-[200px]"
                        onClick={e => e.stopPropagation()}
                      >
                        <div className="text-sm font-medium text-gray-900 mb-2 px-2">
                          Les gusta a:
                        </div>
                        <div className="max-h-[150px] overflow-y-auto">
                          {likesList.map((like) => (
                            <div
                              key={like.invitadoId}
                              className="px-2 py-1.5 hover:bg-gray-50 text-sm text-gray-700"
                            >
                              {like.nombreInvitado}
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </div>
                  {selectedImage.texto && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(prev => ({ ...prev, showMessage: !prev.showMessage }));
                      }}
                      className="p-4 rounded-full text-[#D4AF37] transition-colors"
                    >
                      <FaComment className={selectedImage.showMessage ? 'opacity-50' : ''} />
                    </button>
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSingleImageDownload(selectedImage.urlImg, selectedImage.idImagenesEvento);
                    }}
                    className="p-4 rounded-full text-white"
                  >
                    <FaDownload />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageToDelete(selectedImage);
                      setShowDeleteConfirm(true);
                    }}
                    className="p-4 rounded-full text-red-500 hover:bg-red-500 hover:text-white transition-colors"
                  >
                    <FaTrash />
                  </button>
                  <button
                    className="p-4 rounded-full text-white"
                  >
                    <FaShare />
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Modal de QR */}
        <AnimatePresence>
          {showQRModal && selectedInvitado && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
              onClick={() => setShowQRModal(false)}
            >
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-white rounded-xl p-6 max-w-sm w-full"
                onClick={e => e.stopPropagation()}
              >
                <div className="text-center">
                  <h3 className="text-lg font-semibold mb-2">{selectedInvitado.nombre}</h3>
                  <div className="bg-gray-50 p-6 rounded-xl mb-4">
                    <QRCodeSVG
                      value={`${window.location.origin}/img-event-inv/${id}?invitado=${selectedInvitado.idInvitado}`}
                      size={200}
                      level="H"
                      className="mx-auto"
                      includeMargin={true}
                    />
                  </div>
                  
                  <p className="text-sm text-gray-600 mb-6">
                    Comparte este código QR con {selectedInvitado.nombre}
                  </p>

                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <button
                      onClick={() => {
                        const url = `whatsapp://send?text=${encodeURIComponent(
                          `${window.location.origin}/img-event-inv/${id}?invitado=${selectedInvitado.idInvitado}`
                        )}`;
                        window.location.href = url;
                      }}
                      className="flex flex-col items-center gap-1 text-green-600"
                    >
                      <FaWhatsapp className="text-2xl" />
                      <span className="text-xs">WhatsApp</span>
                    </button>
                    <button
                      onClick={() => {
                        const url = `https://t.me/share/url?url=${encodeURIComponent(
                          `${window.location.origin}/img-event-inv/${id}?invitado=${selectedInvitado.idInvitado}`
                        )}`
                        window.open(url);
                      }}
                      className="flex flex-col items-center gap-1 text-blue-500"
                    >
                      <FaTelegram className="text-2xl" />
                      <span className="text-xs">Telegram</span>
                    </button>
                    <button
                      onClick={() => {
                        const url = `mailto:?subject=Álbum de fotos&body=${encodeURIComponent(
                          `${window.location.origin}/img-event-inv/${id}?invitado=${selectedInvitado.idInvitado}`
                        )}`;
                        window.location.href = url;
                      }}
                      className="flex flex-col items-center gap-1 text-gray-600"
                    >
                      <FaEnvelope className="text-2xl" />
                      <span className="text-xs">Email</span>
                    </button>
                    <button
                      onClick={() => handleShare(selectedInvitado)}
                      className="flex flex-col items-center gap-1 text-purple-600"
                    >
                      <FaShare className="text-2xl" />
                      <span className="text-xs">Más</span>
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Modal de confirmación */}
        {showDeleteConfirm && (
          <div className="fixed inset-0 bg-black/75 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl p-6 max-w-sm w-full">
              <div className="text-center">
                <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  <FaTrash className="text-3xl text-red-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">¿Eliminar imagen?</h3>
                <p className="text-gray-600 mb-6">
                  Esta acción no se puede deshacer. Se eliminarán también todos los likes asociados.
                </p>
                <div className="flex justify-center gap-3">
                  <button
                    onClick={() => {
                      setShowDeleteConfirm(false);
                      setImageToDelete(null);
                    }}
                    className="px-6 py-2 border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => {
                      handleDelete(imageToDelete);
                      setSelectedImage(null);
                    }}
                    className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal para crear nuevo álbum */}
        <AnimatePresence>
          {showNewAlbumForm && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
              onClick={() => setShowNewAlbumForm(false)}
            >
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-white rounded-xl p-6 w-full max-w-sm"
                onClick={e => e.stopPropagation()}
              >
                <h3 className="text-lg font-medium mb-4">Crear nuevo álbum</h3>
                <input
                  type="text"
                  value={newAlbumName}
                  onChange={(e) => setNewAlbumName(e.target.value)}
                  placeholder="Nombre del álbum"
                  className="w-full p-3 border border-gray-200 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-[#D4AF37]"
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => setShowNewAlbumForm(false)}
                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleCreateNewAlbum}
                    className="px-4 py-2 bg-[#D4AF37] text-white rounded-lg hover:bg-[#B89530] transition-colors"
                  >
                    Crear
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Reemplazar el mensaje de error actual por el nuevo componente */}
        <ErrorMessage />

        {/* Modal de confirmación para eliminar álbum */}
        {showDeleteAlbumConfirm && albumToDelete && (
          <div className="fixed inset-0 bg-black/75 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl p-6 max-w-sm w-full">
              <div className="text-center">
                <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  <FaTrash className="text-3xl text-red-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">¿Eliminar álbum?</h3>
                <p className="text-gray-600 mb-6">
                  ¿Estás seguro de que deseas eliminar el álbum "{albumToDelete.nombre}"? Esta acción no se puede deshacer.
                </p>
                <div className="flex justify-center gap-3">
                  <button
                    onClick={() => {
                      setShowDeleteAlbumConfirm(false);
                      setAlbumToDelete(null);
                    }}
                    className="px-6 py-2 border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => handleDeleteAlbum(albumToDelete)}
                    className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Nueva sección de álbumes */}
        {activeTab === 'albums' && (
          <div className="p-4">
            <div className="bg-white rounded-xl shadow-sm">
              <div className="p-4 border-b border-gray-100">
                <div className="flex flex-col gap-3">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <h2 className="text-lg font-medium text-gray-800">Álbumes</h2>
                      <span className="text-sm text-gray-500">({albums.length})</span>
                    </div>
                  </div>
                  
                  {/* Botones de acción */}
                  <div className="flex flex-wrap gap-2">
                    <button
                      onClick={() => setShowNewAlbumForm(true)}
                      className="flex-1 sm:flex-none inline-flex items-center justify-center gap-2 px-3 py-1.5 text-sm font-medium text-[#D4AF37] bg-[#FDF6E3] hover:bg-[#FCF3D9] rounded-lg transition-colors"
                    >
                      <FaPlus className="text-sm" />
                      <span>Nuevo álbum</span>
                    </button>

                    <button
                      onClick={() => setIsDeleteMode(!isDeleteMode)}
                      className={`flex-1 sm:flex-none inline-flex items-center justify-center gap-2 px-3 py-1.5 text-sm font-medium rounded-lg transition-colors ${
                        isDeleteMode 
                          ? 'bg-red-500 text-white hover:bg-red-600' 
                          : 'text-red-600 bg-red-50 hover:bg-red-100'
                      }`}
                    >
                      <FaTrash className="text-sm" />
                      <span>{isDeleteMode ? 'Cancelar' : 'Eliminar'}</span>
                    </button>
                  </div>
                </div>
              </div>

              {/* Lista de álbumes */}
              <div className="divide-y divide-gray-100">
                {albums.map(album => (
                  <div key={album.idAlbum} className="p-4 flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div className="w-12 h-12 bg-gray-100 rounded-lg overflow-hidden">
                        {album.imagenPortada ? (
                          <img
                            src={album.imagenPortada}
                            alt={album.nombre}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center">
                            <FaCamera className="text-gray-400" />
                          </div>
                        )}
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-800">{album.nombre}</h3>
                        <p className="text-sm text-gray-500">
                          {allImages.filter(img => img.idAlbum === album.idAlbum).length} fotos
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => {
                          setSelectedAlbum(album);
                          handleDownloadAlbumImages();
                        }}
                        className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                        title={`Descargar álbum ${album.nombre}`}
                      >
                        <FaDownload />
                      </button>
                      {isDeleteMode && (
                        <button
                          onClick={() => {
                            setAlbumToDelete(album);
                            setShowDeleteAlbumConfirm(true);
                          }}
                          className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                        >
                          <FaTrash />
                        </button>
                      )}
                    </div>
                  </div>
                ))}

                {albums.length === 0 && (
                  <div className="p-8 text-center text-gray-500">
                    <FaFolderOpen className="text-4xl mx-auto mb-3 opacity-50" />
                    <p>No hay álbumes creados</p>
                    <button
                      onClick={() => setShowNewAlbumForm(true)}
                      className="mt-3 text-sm text-[#D4AF37] hover:underline"
                    >
                      Crear el primer álbum
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};