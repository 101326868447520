export const backgrounds = [
    {
        value: "",
        categories: ["otros"]
    },
    
    {
        value: "https://digitalrsvp.mx/api/Images/11f307cf-9118-47b3-a2a8-8ea08f57b5bd.jpg",
        categories: ["lisos", "bodas", "infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/5b4777ce-9d89-4ab7-b01c-d73a7ef2a71e.jpg",
        categories: ["infantil", "flores", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/4ab05a26-d795-4f3e-81cb-a2bc7e9046af.jpg",
        categories: ["infantil", "flores", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/bacf29ab-eb05-489c-99bb-fe0fd5f48c94.jpg",
        categories: ["infantil", "flores", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/27ea83f1-7631-4d24-8d74-279d99b4db12.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/b5c4c7f5-7fd5-4ca8-a1cf-4656bada8a52.jpg",
        categories: ["flores", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/36cc472a-64ed-4203-bfbc-999f2ea9af79.jpg",
        categories: ["marmol", "flores "]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/48d91c72-91f9-4064-af53-1dddaf39560b.jpg",
        categories: ["marmol"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/da9f4568-75b3-4a27-96cc-8408ff8f93c9.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/3252c344-bbd2-4e73-bbc2-2fb75e1b6802.jpg",
        categories: ["xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/4c9bb981-b60b-48e0-ba07-25bad81a3098.jpg",
        categories: ["xv", "flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/336e7edc-56a2-4fee-b3ab-657757a5be37.jpg",
        categories: ["marmol", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/9b72b730-8742-4a36-872c-9674ff52902e.jpg",
        categories: ["marmol", "infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/b6e729ed-b0f9-436b-9603-a574a6f0b2ad.jpg",
        categories: ["xv", "marmol", "infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/3a73ee2d-ffd7-4250-8a1c-5dd97d7b1264",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/87d12548-e043-4f07-b80e-12dfc4da7aba.jpg",
        categories: ["xv", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/8d9717c2-cdb0-4a30-81bc-68336f5d19cf.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/8d259a9b-a22e-4538-b3b1-a9021877d1e9.jpg",
        categories: ["lisos", "infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/a33877c9-dd6e-4cc0-ba91-9b09ac59fa60",
        categories: ["bodas", "marmol"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/61c93084-cebc-4bfd-a8ba-ce350d39e9f1.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/31ccf8fa-4397-4dd0-8954-6fa085a3cb6d.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/794ad4d2-817c-487e-96d9-4c69195773bf",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/2ef4a7b2-b213-483a-8d67-9d628e393a1a.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/416e7e23-1167-4327-b152-bbebe3856e83.jpg",
        categories: ["xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/0d7c9da3-3396-41eb-ad3f-c7d20dffcf92.jpg",
        categories: ["xv", "lisos"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/075b2eae-6ab5-4d8d-9f7f-a7940a7c8d75.jpg",
        categories: ["xv"]
    },

    {
        value: "https://digitalrsvp.mx/api/Images/f9a1744e-54f3-4d4e-89dd-860e9782f468.jpg", 
        categories: ["infantil"]
      },

    {
        value: "https://digitalrsvp.mx/api/Images/e98362de-a1c6-4793-b15c-fa1a909f3f6a.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/e6a62ff9-d10f-47d7-a7c9-403a592b86a7.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/100d6bfa-50d9-4255-9379-575186da587f.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/0e539a01-a0c8-4add-bc2f-30885679edbb.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/6243181e-fb62-4356-b5b8-fc5201b1b7cd.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/1457b90d-a247-4454-9572-39e517989269.jpg",
        categories: ["marmol", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/9012e0a1-a406-40ad-b5b2-7a02175f305e.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/ee5dc3d1-e1f6-4db9-9825-3e05367b68db.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/3315f5e4-5a4a-45c3-bb4e-622c32e53d51.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/884e3829-7d3a-4ea5-8bc8-cd03e9a1e3ea",
        categories: ["xv", "flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/6bbb5249-1fe2-4324-a189-db5f43193451",
        categories: ["xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/f83f492f-575f-4cb3-88dc-b8f1d3d92b78.jpg",
        categories: ["xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/fdad2717-8a9a-4b6c-bdea-f0a4802cd092",
        categories: ["flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/ba928da6-dac3-4f51-bee4-c81fe53d59f3",
        categories: ["flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/a0a67b1b-8bfe-4c4d-9ff1-3c7cb09bf1b8.jpg",
        categories: ["flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/ac65eeb8-5eb3-4223-9ddf-0e1292e1a9c6",
        categories: ["marmol"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/ebdf3678-b771-4453-bfac-bb1bb942ad76",
        categories: ["marmol"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/57d25efb-c2a9-420c-885b-275238d3d560.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/e4c91c71-e1e4-4e53-8df8-e20c2ad6cede.jpg",
        categories: ["infantil", "otros"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/c36bb567-5c2c-40c8-ac50-25bf01b3650d.jpg",
        categories: ["flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/374d9714-c949-492e-a6d7-88d80ffd263e.jpg",
        categories: ["flores"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/a9f8e603-3b66-4c9f-b693-68e84cacfc4a.jpg",
        categories: ["flores", "bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/8d4b2080-79b1-44b6-a622-d24e099021fa.jpg",
        categories: ["flores", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/83e22c20-6f32-45d2-8379-93e5da834d10.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/b246a278-725a-432f-a447-b4095a2064b7.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/dbb901ec-36e5-4084-86e2-feda299bb68b.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/c0f5e81e-2493-490f-b4df-4147d8060533.jpg",
        categories: ["infantil", "xv"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/3032b3ac-34d3-4677-ab92-88707f6a68c1.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/2e86c0c0-df67-4197-9042-f1b31b437c34.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/32892dd6-35d6-42f3-b4f9-b1a76b2aba87.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/af44e94a-9dff-45d3-ae71-c9a55d360ec4.jpg",
        categories: ["infantil"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/05a5a3e4-8528-4c0f-840c-2588490f6bc7",
        categories: ["marmol"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/87c14890-53a4-4161-996a-3a579c895e17",
        categories: ["marmol"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/76e3850a-fb7b-4621-b3f4-3cd0eb1b5cef",
        categories: ["lisos"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/004118d3-c57b-4dd7-9359-00824d74e82b",
        categories: ["lisos"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/72973984-090e-4f08-a21b-744e7df38906",
        categories: ["lisos"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/c22bf00a-9bd8-4cdc-ba94-fc7e2c57fe2f",
        categories: ["otros"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/9012e0a1-a406-40ad-b5b2-7a02175f305e.jpg",
        categories: ["bodas"]
    },
    {
        value: "https://digitalrsvp.mx/api/Images/0f7832c4-59e3-4de1-947b-bbd6401789ec",
        categories: ["otros"]
    }
];

export const categories = {
    todos: {
        title: {
            es: "Todos los Fondos",
            en: "All Backgrounds"
        }
    },
    bodas: {
        title: {
            es: "Fondos para Bodas",
            en: "Wedding Backgrounds"
        }
    },
    xv: {
        title: {
            es: "Fondos para XV Años",
            en: "XV Years Backgrounds"
        }
    },
    flores: {
        title: {
            es: "Fondos Florales",
            en: "Floral Backgrounds"
        }
    },
    marmol: {
        title: {
            es: "Fondos Marmoleados",
            en: "Marble Backgrounds"
        }
    },
    lisos: {
        title: {
            es: "Fondos Lisos",
            en: "Solid Backgrounds"
        }
    },
    infantil: {
        title: {
            es: "Fondos Infantiles",
            en: "Children's Backgrounds"
        }
    },
    otros: {
        title: {
            es: "Otros Fondos",
            en: "Other Backgrounds"
        }
    }
}; 
