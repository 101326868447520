import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { InvitadosXvComponent } from '../../../components/ComponentsXv/InvitadosXvComponent';
import { InvitadosBodaComponent2 } from '../../../components/ComponentsBoda2/InvitadosBodaComponent2';

export const InvitadosForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (initialValues) {
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Invitados</h3>
        <div className='grid grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              if (values.limiteAccesos == null) {
                values.limiteAccesos = 150;
              }
              onSubmit(values);
            }}
          >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                      Titulo:
                    </label>
                    <Input
                      type="text"
                      id="titulo"
                      name="titulo"
                      value={values?.titulo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage name="titulo" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  <div>
                    <label htmlFor="entradasText" className="block text-sm font-medium text-gray-700 mb-1">
                      Texto de Entradas:
                    </label>
                    <Input
                      type="text"
                      id="entradasText"
                      name="entradasText"
                      value={values?.entradasText}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div>
                    <label htmlFor="eventoTitulo" className="block text-sm font-medium text-gray-700 mb-1">
                      Evento Titulo:
                    </label>
                    <Input
                      type="text"
                      id="eventoTitulo"
                      name="eventoTitulo"
                      value={values?.eventoTitulo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage name="eventoTitulo" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  <div>
                    <label htmlFor="colorFondoName" className="block text-sm font-medium text-gray-700 mb-1">
                      Color Fondo Name:
                    </label>
                    <Input
                      type="color"
                      id="colorFondoName"
                      name="colorFondoName"
                      value={values?.colorFondoName}
                      onChange={handleCustomChange(handleChange, values)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage name="colorFondoName" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  <div>
                    <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                      Imagen de fondo:
                    </label>
                    <Input
                      type="text"
                      id="imgFondo"
                      name="imgFondo"
                      value={values?.imgFondo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                      Imagen de fondo:
                    </label>
                    <ImageFondo
                      label="Seleccione un fondo"
                      name="imgFondo"
                      onChange={handleCustomChange(handleChange, values)}
                      value={values?.imgFondo}
                      className={`mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${values?.imgFondo ? "input-error" : ""}`}
                    />
                    <ErrorMessage name="imgFondo" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  <div>
                    <label htmlFor="limiteAccesos" className="block text-sm font-medium text-gray-700 mb-1">
                      Limite de Invitados:
                    </label>
                    <Input
                      type="text"
                      id="limiteAccesos"
                      name="limiteAccesos"
                      value={values?.limiteAccesos}
                      onChange={handleCustomChange(handleChange, values)}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage name="limiteAccesos" component="div" className="text-red-500 text-sm mt-1" />
                  </div>
                  <div>
                    <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentValues(values);
                      setRefreshKey(prev => prev + 1);
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Guardar Invitados
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <InvitadosBodaComponent2
                  key={refreshKey}
                  invitado={2}
                  xv={true}
                  colores={colores}
                  datosSeccionInvitados={currentValues}
                  dataInvitado={{ nombre: "Alejandro", accesos: 2 }}
                  isEnglish={false}
                />
              ) : (
                <InvitadosXvComponent
                  key={refreshKey}
                  NameInvitation="Alejandro"
                  numGuess={2}
                  peltaColores={colores}
                  datosSeccionInvitados={currentValues}
                />
              )
            )}
          </PreviewContainer>
        </div>
      </div>
    </div>
  );
}; 