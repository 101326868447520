import React, { useEffect, useState } from "react";
import { SiGooglemaps } from "react-icons/si";
import marcoImagen from './Assets/marcoDorado.png';
import { Link } from "react-router-dom"; // Si usas react-router-dom

// Función auxiliar para parsear diferentes formatos de fecha
const parseDate = (dateString) => {
  if (!dateString) return null;

  // Intenta crear una fecha directamente (ISO format)
  let date = new Date(dateString);
  if (!isNaN(date.getTime())) return date;

  // Formatos comunes en inglés (e.g., "March 15, 2025" o "15 March 2025")
  const englishFormats = [
    /(\w+)\s+(\d{1,2}),?\s+(\d{4})/, // March 15, 2025 o March 15 2025
    /(\d{1,2})\s+(\w+)\s+(\d{4})/, // 15 March 2025
  ];

  // Formatos comunes en español (e.g., "15 de Marzo de 2025" o "Marzo 15 de 2025")
  const spanishFormats = [
    /(\d{1,2})\s+de\s+(\w+)\s+de\s+(\d{4})/, // 15 de Marzo de 2025
    /(\w+)\s+(\d{1,2})\s+de\s+(\d{4})/, // Marzo 15 de 2025
  ];

  const months = {
    enero: 0, january: 0, jan: 0,
    febrero: 1, february: 1, feb: 1,
    marzo: 2, march: 2, mar: 2,
    abril: 3, april: 3, apr: 3,
    mayo: 4, may: 4,
    junio: 5, june: 5, jun: 5,
    julio: 6, july: 6, jul: 6,
    agosto: 7, august: 7, aug: 7,
    septiembre: 8, september: 8, sep: 8, sept: 8,
    octubre: 9, october: 9, oct: 9,
    noviembre: 10, november: 10, nov: 10,
    diciembre: 11, december: 11, dec: 11
  };

  // Probar formatos en inglés
  for (let format of englishFormats) {
    const match = dateString.match(format);
    if (match) {
      const [_, part1, part2, year] = match;
      const month = isNaN(part1) ? months[part1.toLowerCase()] : months[part2.toLowerCase()];
      const day = isNaN(part1) ? parseInt(part2) : parseInt(part1);
      if (month !== undefined && !isNaN(day)) {
        return new Date(parseInt(year), month, day);
      }
    }
  }

  // Probar formatos en español
  for (let format of spanishFormats) {
    const match = dateString.match(format);
    if (match) {
      const [_, part1, part2, year] = match;
      const month = isNaN(part1) ? months[part1.toLowerCase()] : months[part2.toLowerCase()];
      const day = isNaN(part1) ? parseInt(part2) : parseInt(part1);
      if (month !== undefined && !isNaN(day)) {
        return new Date(parseInt(year), month, day);
      }
    }
  }

  return null;
};

const formatGoogleCalendarDateTime = (dateTime) => {
  const date = parseDate(dateTime);
  if (!date || isNaN(date.getTime())) {
    console.error("Invalid date:", dateTime);
    return "";
  }
  return date.toISOString().replace(/-|:|\.\d+/g, "").slice(0, 15);
};

const calculateTimeRemaining = (targetDate) => {
  if (typeof targetDate === 'string') {
    targetDate = parseDate(targetDate);
  }
  if (!targetDate || isNaN(targetDate.getTime())) {
    return { dias: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const now = new Date().getTime();
  const difference = targetDate.getTime() - now;

  if (difference > 0) {
    const dias = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { dias, hours, minutes, seconds };
  } else {
    return { dias: 0, hours: 0, minutes: 0, seconds: 0 };
  }
};

export const ContadorXvComponentD2 = ({
  peltaColores,
  nombres,
  fechaEvento,
  recepcionNombreLugar,
  datosSeccionContador,
  isEnglish
}) => {
  const [urlCalendario, setUrlCalendario] = useState("");

  const formattedStartDateTime = formatGoogleCalendarDateTime(
    datosSeccionContador.fechaNewDate
  );
  const formattedEndDateTime = formatGoogleCalendarDateTime(
    new Date(datosSeccionContador.fechaNewDate).setHours(
      new Date(datosSeccionContador.fechaNewDate).getHours() + 1
    )
  );

  const targetDate = new Date(datosSeccionContador.fechaNewDate || "").getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);
    return () => clearInterval(interval);
  }, [targetDate]);

  useEffect(() => {
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      `Evento de ${nombres}`
    )}&dates=${formattedStartDateTime}/${formattedEndDateTime}&location=${encodeURIComponent(
      recepcionNombreLugar
    )}`;
    setUrlCalendario(googleCalendarUrl);
  }, [nombres, formattedStartDateTime, formattedEndDateTime, recepcionNombreLugar]);

  const fontFamilyStyle = { fontFamily: datosSeccionContador.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };

  // Format date for display
  const formatDisplayDate = (fechaISO) => {
    const date = parseDate(fechaISO);

    // Check if valid
    if (!date || isNaN(date.getTime())) {
      return fechaISO; // Return original if can't parse
    }

    const daysOfWeek = isEnglish ? [
      "Sunday",
      "Monday",
      "Tuesday", 
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ] : [
      "Domingo",
      "Lunes", 
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const months = isEnglish ? [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ] : [
      "Enero",
      "Febrero", 
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const day = daysOfWeek[date.getDay()];
    const dayNumber = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Función para obtener el sufijo ordinal en inglés
    const getOrdinalSuffix = (n) => {
      if (n > 3 && n < 21) return 'th';
      switch (n % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };

    return isEnglish ? 
      `${day}, ${month} ${dayNumber}${getOrdinalSuffix(dayNumber)}, ${year}` :
      `${day}, ${dayNumber} de ${month} de ${year}`;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionContador.imgFondo || "https://i.postimg.cc/KYJVZxp1/c.jpg	"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color1,
        color: datosSeccionContador.textColor || "#ffffff",
        zIndex: 10,
        position: "relative"
      }}
      className="sm:h-auto md:h-[120vh] flex flex-col items-center w-full "
    >
      <div className="flex flex-col pt-[10vh] pb-4 w-full h-full items-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
        <h1
          className="font-serif sm:text-[25px] md:text-[52px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {/* {datosSeccionContador.titulo.split("\\n").map((linea, index) => (
            <React.Fragment key={index}>
              {linea}
              <br />
            </React.Fragment>
          ))} */}
          {formatDisplayDate(datosSeccionContador.fechaNewDate)}
        </h1>
        <h1
          className="font-serif sm:text-[25px] md:text-[52px]"
          data-aos="flip-up"
          style={fontFamilyStyle}
        >
          {isEnglish ? "Time Left" : "Faltan"}
        </h1>
       <br />
        <div
          className="flex sm:flex-col md:flex-row justify-center w-full sm:h-[70vh] md:h-[40vh] items-center mt-2"
          style={{ zIndex: 20 }}
        >
          {["dias", "hours", "minutes", "seconds"].map((unit, index) => (
            <div
              key={unit}
              className="flex flex-col sm:m-1 md:mx-6 justify-center items-center"
              style={{
                backgroundImage: `url(${marcoImagen})`,
                backgroundSize: "100% 100%",
                padding: "5px",
                borderRadius: "10px",
                width: "150px",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p className="letra-text sm:text-[23px] md:text-[43px]">
                {timeRemaining[unit]}
              </p>
              <p className="letra-text sm:text-[23px] md:text-[43px]">
                {isEnglish ? 
                  unit.charAt(0).toUpperCase() + unit.slice(1) :
                  unit === "dias" ? "Días" :
                  unit === "hours" ? "Horas" :
                  unit === "minutes" ? "Minutos" :
                  "Segundos"
                }
              </p>
            </div>
          ))}
        </div>
        <br />
        <a
          href={urlCalendario}
          target="_blank"
          rel="noopener noreferrer"
          className="rounded-xl"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "black",
            cursor: "pointer",
            border: "1px solid transparent",
            padding: "10px",
            backgroundColor: "white",
            boxShadow: "-2px 2px 7px 0px rgba(0, 0, 0, 0.44)",
            ...fontFamilyStyle,
          }}
        >
          <SiGooglemaps className="m-2 text-red-800" />
          <span>{isEnglish ? "Add to Calendar" : "Agregar al Calendario"}</span>
        </a>
      </div>
    </div>
  );
};
