import React from 'react';

const InputPro = ({
    id,
    label,
    type = 'text',
    maxLength,
    name,
    onChange,
    value,
    error,
    touched,
    placeholder = " ",
    className = '',
    required = false,
    disabled = false
}) => {
    return (
        <div className="relative z-0 w-full mb-5 group">
            <input
                id={id}
                type={type}
                maxLength={maxLength}
                name={name}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                className={`block w-full px-0 pb-2.5 pt-5 text-base text-gray-900 bg-transparent 
                    border-b-2 border-gray-300 
                    dark:text-black dark:border-blue-200 
                    focus:outline-none focus:ring-0 focus:border-amber-500 
                    appearance-none
                    peer ${error && touched ? "border-red-500" : ""} ${className}`}
            />

            <label
                htmlFor={id}
                className={`absolute text-base text-gray-500 
                    duration-300 transform 
                    -translate-y-4 scale-75
                    top-4 left-0 z-10 origin-[0] 
                    peer-focus:text-amber-500
                    peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 
                    peer-focus:scale-75 
                    peer-focus:-translate-y-4
                    pointer-events-none
                    ${error && touched ? "text-red-500 peer-focus:text-red-500" : ""}`}
            >
                {label}
                {required && <span className="text-red-500 ml-1">*</span>}
            </label>

            {touched && error && (
                <p className="text-xs text-red-500 mt-1">{error}</p>
            )}
        </div>
    );
};

export default InputPro; 