import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { RegalosXvComponent } from '../../../components/ComponentsXv/RegalosXvComponent';
import { RegalosBodaComponent2 } from '../../../components/ComponentsBoda2/RegalosBodaComponent2';
import { PreviewModal } from '../../../components/PreviewModal';

export const RegalosForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Mesa de Regalos</h3>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                {/* Sección Principal */}
                <div className="bg-blue-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-blue-900 mb-4">Información Principal</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="mb-4">
                      <label
                        htmlFor="cantidadRegalos"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Cantidad:
                      </label>
                      <Input
                        type="number"
                        max={4}
                        id="cantidadRegalos"
                        name="cantidadRegalos"
                        value={values?.cantidadRegalos}
                        onChange={handleCustomChange(handleChange, values)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="titulo"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Título:
                      </label>
                      <Input
                        type="text"
                        id="titulo"
                        name="titulo"
                        value={values?.titulo}
                        onChange={handleCustomChange(handleChange, values)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="descripcion"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Descripción:
                      </label>
                      <Input
                        type="textarea"
                        id="descripcion"
                        name="descripcion"
                        value={values?.descripcion}
                        onChange={handleCustomChange(handleChange, values)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                </div>

                {/* Sección Enlaces */}
                <div className="bg-green-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-green-900 mb-4">Enlaces</h4>
                  <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-4 gap-6">
                    {[1, 2, 3, 4].map((num) => (
                      <div key={num} className="mb-4">
                        <label
                          htmlFor={`link${num}`}
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Enlace {num}:
                        </label>
                        <Input
                          type="text"
                          id={`link${num}`}
                          name={`link${num}`}
                          value={values?.[`link${num}`]}
                          onChange={handleCustomChange(handleChange, values)}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <div className="mt-2">
                          <label className="inline-flex items-center">
                            <Input
                              type="checkbox"
                              id={`link${num}Enable`}
                              name={`link${num}Enable`}
                              checked={values?.[`link${num}Enable`]}
                              onChange={handleCustomChange(handleChange, values)}
                              className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <span className="ml-2 text-gray-700">Habilitar</span>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Sección Imágenes */}
                <div className="bg-yellow-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-yellow-900 mb-4">Imágenes de Enlaces</h4>
                  <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-4 gap-6">
                    {[1, 2, 3, 4].map((num) => (
                      <div key={num} className="mb-4">
                        <label
                          htmlFor={`imgLink${num}`}
                          className="block text-gray-700 text-sm font-bold mb-2"
                        >
                          Imagen Enlace {num}:
                        </label>
                        <Input
                          type="text"
                          id={`imgLink${num}`}
                          name={`imgLink${num}`}
                          value={values?.[`imgLink${num}`]}
                          onChange={handleCustomChange(handleChange, values)}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Sección Configuración de Estilo */}
                <div className="bg-purple-50 p-6 rounded-lg mb-6">
                  <h4 className="text-lg font-medium text-purple-900 mb-4">Configuración de Estilo</h4>
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                    <div className="mb-4">
                      <label
                        htmlFor="textColor"
                        className="block text-gray-700 text-sm font-bold mb-2"
                      >
                        Color Texto:
                      </label>
                      <Input
                        type="color"
                        id="textColor"
                        name="textColor"
                        value={values?.textColor}
                        onChange={handleCustomChange(handleChange, values)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div>
                      <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                    </div>
                    <div className="col-span-1 md:col-span-1 lg:col-span-3">
                      <ImageFondo
                        label="Seleccione un fondo"
                        name="imgFondo"
                        onChange={handleCustomChange(handleChange, values)}
                        value={values?.imgFondo}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentValues(values);
                      setRefreshKey(prev => prev + 1);
                      if (isMobile) {
                        setShowMobilePreview(true);
                      }
                    }}
                    className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="w-full sm:w-auto bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Guardar Mesa de Regalos
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {!isMobile && <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <RegalosBodaComponent2
                  key={refreshKey}
                  datosSeccionRegalos={currentValues}
                  colores={colores}
                  isEnglish={false}
                />
              ) : (
                <RegalosXvComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionRegalos={currentValues}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>}

          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {currentValues && Object.keys(currentValues).length > 0 && (
                diseño === "2" ? (
                  <RegalosBodaComponent2
                    key={refreshKey}
                    datosSeccionRegalos={currentValues}
                    colores={colores}
                    isEnglish={false}
                  />
                ) : (
                  <RegalosXvComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionRegalos={currentValues}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
}; 