import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';
import { SelectIcons } from '../../../components/functions/SelectIcons';
import { PreviewContainer } from '../../../components/PreviewContainer';
import { ProgramaBodaComponent } from '../../../components/ComponentsBoda/ProgramaBodaComponent';
import { ProgramaBodaComponent2 } from '../../../components/ComponentsBoda2/ProgramaBodaComponent2';
import { PreviewModal } from '../../../components/PreviewModal';

export const ProgramaBodaForm = ({ initialValues, onSubmit, diseño, colores }) => {
  const [currentValues, setCurrentValues] = useState(initialValues || {});
  const [refreshKey, setRefreshKey] = useState(0);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setCurrentValues(initialValues);
      setRefreshKey(prev => prev + 1);
    }
  }, [initialValues]);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleCustomChange = (handleChange, values) => (e) => {
    handleChange(e);
    setCurrentValues(values);
    setRefreshKey(prev => prev + 1);
  };

  const programSections = [
    { name: "ceremoniaReligiosa", label: "Seccion", number: 1 },
    { name: "recepcion", label: "Seccion", number: 2 },
    { name: "civil", label: "Seccion", number: 3 },
    { name: "cena", label: "Seccion", number: 4 },
    { name: "coctel", label: "Seccion", number: 5 },
    { name: "baile", label: "Seccion", number: 6 }
  ];

  return (
    <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Detalles del Programa</h3>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="md:col-span-3">
                    <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                      Titulo Principal:
                    </label>
                    <Input
                      type="text"
                      id="titulo"
                      name="titulo"
                      value={values?.titulo}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                  <div className="md:col-span-3">
                    <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-1">
                      Descripcion:
                    </label>
                    <Input
                      type="text"
                      id="descripcion"
                      name="descripcion"
                      value={values?.descripcion}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                    />
                  </div>
                </div>

                {programSections.map((item) => (
                  <React.Fragment key={item.name}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      <div>
                        <label htmlFor={item.name} className="block text-sm font-medium text-gray-700 mb-1">
                          {`${item.label} (${item.number}):`}
                        </label>
                        <Input
                          type="text"
                          id={item.name}
                          name={item.name}
                          value={values?.[item.name]}
                          onChange={handleCustomChange(handleChange, values)}
                          className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                        />
                      </div>
                      <div>
                        <SelectIcons
                          label={`Selecciona un icono para ${item.label} (${item.number})`}
                          name={`${item.name}Img`}
                          onChange={handleCustomChange(handleChange, values)}
                          value={values?.[`${item.name}Img`]}
                        />
                      </div>
                      <div>
                        <label htmlFor={`${item.name}Titulo`} className="block text-sm font-medium text-gray-700 mb-1">
                          {`${item.label} Titulo (${item.number}):`}
                        </label>
                        <Input
                          type="text"
                          id={`${item.name}Titulo`}
                          name={`${item.name}Titulo`}
                          value={values?.[`${item.name}Titulo`]}
                          onChange={handleCustomChange(handleChange, values)}
                          className="w-full p-2.5 border border-gray-300 rounded-md text-base"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div>
                    <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                      Color de Texto:
                    </label>
                    <Input
                      type="color"
                      id="textColor"
                      name="textColor"
                      value={values?.textColor || ""}
                      onChange={handleCustomChange(handleChange, values)}
                      className="w-full h-10 p-1 rounded-md border-gray-300"
                    />
                  </div>
                  <div>
                    <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                      Imagen de Fondo:
                    </label>
                    <ImageFondo
                      label="Seleccione un fondo"
                      name="imgFondo"
                      onChange={handleCustomChange(handleChange, values)}
                      value={values?.imgFondo}
                      className="mt-1 w-full"
                    />
                    <Input
                      type="text"
                      id="imgFondo"
                      name="imgFondo"
                      value={values?.imgFondo}
                      onChange={handleCustomChange(handleChange, values)}
                    />
                  </div>
                  <div>
                    <SelectTipoLetra values={values} handleChange={handleCustomChange(handleChange, values)} />
                  </div>
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentValues(values);
                      setRefreshKey(prev => prev + 1);
                      if (isMobile) {
                        setShowMobilePreview(true);
                      }
                    }}
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Ver Preview
                  </button>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                  >
                    Guardar Programa
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {!isMobile && <PreviewContainer>
            {currentValues && Object.keys(currentValues).length > 0 && (
              diseño === "2" ? (
                <ProgramaBodaComponent2
                  key={refreshKey}
                  datosSeccionProgramaBoda={currentValues}
                  colores={colores}
                  isEnglish={false}
                  screenMobile={true}
                />
              ) : (
                <ProgramaBodaComponent
                  key={refreshKey}
                  peltaColores={colores}
                  datosSeccionPrograma={currentValues}
                  screenMobile={true}
                />
              )
            )}
          </PreviewContainer>}
          {showMobilePreview && (
            <PreviewModal onClose={() => setShowMobilePreview(false)}>
              {currentValues && Object.keys(currentValues).length > 0 && (
                diseño === "2" ? (
                  <ProgramaBodaComponent2
                    key={refreshKey}
                    datosSeccionProgramaBoda={currentValues}
                    colores={colores}
                    isEnglish={false}
                    screenMobile={true}
                  />
                ) : (
                  <ProgramaBodaComponent
                    key={refreshKey}
                    peltaColores={colores}
                    datosSeccionPrograma={currentValues}
                    screenMobile={true}
                  />
                )
              )}
            </PreviewModal>
          )}
        </div>
      </div>
    </div>
  );
}; 