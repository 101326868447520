import React from 'react';

export const PreviewContainer = ({ children }) => {
  return (
    <div className='sticky top-6 w-[350px] mx-auto'>
      <h4 className="text-lg font-medium text-gray-900 mb-4">Vista Previa</h4>
      <div className='relative aspect-[9/16] overflow-hidden rounded-lg shadow-md bg-white'>
        <div className='absolute inset-0 flex justify-center'>
          <div className='w-[320px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}; 
